angular.module('organisations.directives')

	.directive('associatedSubcontractorIssues', function ($rootScope, IssuesService) {
		return {
			restrict: 'EAC',
			replace: true,
			scope: {
				organisationId: '='
			},
			template: `<div>							
							<div>
								<div ng-show="!dataLoaded" class="row text-center">
									<span><i class="fa fa-cog fa-spin" style="font-size:48px"></i></span>
								</div>
								<div ng-show="dataLoaded">
									<table class="table">
										<tr>
											<th>Apartment</th>
											<th>Issue</th>
											<th>Status</th>
											<th>Date</th>
										</tr>
										<tr ng-repeat="item in issues" ui-sref="main.issue({ id: '{{item.id}}' })" href="" class="clickable">
											<td>{{item.address1}}</td>
											<td>{{item.issue}}</td>
											<td><span class="badge">{{item.status | capitalize}}</span></td>
											<td>{{item.created_at}}</td>
										</tr>
									</table>
								</div>
							</div>							
						</div>`,
			link: function (scope, element, attr) {

				const id = scope.organisationId;
				scope.dataLoaded = false;
				scope.issues = [];

				function getIssues() {
					IssuesService.getIssuesByOrganisation(id).then(function (success) {
						scope.issues = success.data;
					}, function (error) {
						console.log(error);
					}).finally(function () {
						scope.dataLoaded = true;
					});
				}

				getIssues();


			}
		};
	})