angular.module('settings.controllers')

.controller('SettingController', ['SettingsService', '$stateParams', '$uibModal', '$state', 'PermissionsService', '$timeout',

    function(SettingsService,$stateParams, $uibModal, $state,  PermissionsService, $timeout) {

	var self = this;
	self.dataLoaded = false;		
	self.setting;		
	var id = $stateParams.id;
	self.permissions = [];
	self.showSaveSpinner = false;			
	self.showAlert = false;
	self.alertDetails = {};

	PermissionsService.getByModuleByUser('settings').then(function(success){   			
		self.permissions = success.data;
	}, function(error){
		console.log(error);
	});   

	SettingsService.getSetting(id).then(function(success){        
		self.setting = success.data;
		self.dataLoaded = true;			
	}, function(error){
		console.log(error);
	});
	
	function showAlert(type, message, id = false){
		self.showSaveSpinner = false;
		self.showAlert = true;
		self.alertDetails.type = type;
		self.alertDetails.message = message;                       
		
		$timeout(function() {
			self.showAlert = false;				
		}, 2000);
	}             

	self.saveRecord = function(){
		self.showSaveSpinner = true;     
		SettingsService.postUpdate(id, self.setting).then(function(success){
			showAlert('Success', 'Record updated.');			
		}, function(error){
			showAlert('Failure', 'Unable to update record.');
		});

	}

}])