angular.module('channel-manager')

.controller('ChannelManagerController', ['ChannelManagerService', 'PermissionsService', 'ModalConfigurationService',
        function( ChannelManagerService, PermissionsService, ModalConfigurationService) {
    var self = this;	
    
    self.dataLoaded = true;   
    self.developments;
    self.developments_prefix;
    self.permissions = [];
    self.development_id;
    var data;
    
    PermissionsService.getByModuleByUser('channel-manager').then(function(success){   			
		self.permissions = success.data;
	}, function(error){
		console.log(error);
	});   
   
    function getDevelopments(){
        self.dataLoaded = false;   
        ChannelManagerService.getDevelopments().then(function(success){                
            self.developments = success.data;
        }, function(error){
            console.log(error);
        }).finally(function(){
            self.dataLoaded = true;   
        });
    }   

    getDevelopments();

    function getDevelopmentsPrefix(){
        self.dataLoaded = false;   
        ChannelManagerService.getDevelopmentsPrefix().then(function(success){                
            self.developments_prefix = success.data;
        }, function(error){
            console.log(error);
        }).finally(function(){
            self.dataLoaded = true;   
        });
    }   

    getDevelopmentsPrefix();

    self.addNewDevelopment = function(){
        data = {'id': 'new'};
        var modalInstance = ModalConfigurationService.channelManagerDevelopmentView(data, self.permissions, self.developments_prefix);

        modalInstance.result.then(function (data) {  					
            getDevelopments();		
        }, function(error){
            console.log(error);                        
        });
        modalInstance.close('cancel');      
    }

  
   
    
}])
