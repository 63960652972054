angular.module('angular-utils.directives')

.directive('myDeleteButton', function () {
	return {
		restrict : 'EAC',
		replace : true,
		scope :{				
			clickFn: '&'
		},      
		template:	`<button type="button"
						class="btn btn-lg btn-danger btn-outline  btn-block" 
						ng-click="clickMe()" > 
						Delete
					</button>`,
		
		
		link: function (scope, element, attr) {				
			scope.clickMe = function() {						
				scope.clickFn();
			}
		}
	};
})