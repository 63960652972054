angular.module('contacts.controllers')	

.controller('ContactController', ['$scope', 'UtilBroadcastService', 'ContactsService', '$stateParams', '$uibModal', '$state', 
			'ModalConfirmAction', 'ModalFeedback', 'PermissionsService', '$timeout', '$filter',
		function($scope, UtilBroadcastService, ContactsService, $stateParams, $uibModal, $state, 
					ModalConfirmAction, ModalFeedback, PermissionsService, $timeout, $filter) {
	var self = this;

	self.dataLoaded = false;
	self.title;
	self.contact={};
	self.contact.contact_types = [];
	self.showSaveSpinner = false;	
	self.showAlert = false;
	self.alertDetails = {};	
	self.permissions = [];
		
	var config = {	params: {}	};
	var id = $stateParams.id;
	self.isNew = (id=='add-new');

	self.types = ContactsService.getTypes();

	PermissionsService.getByModuleByUser('contacts').then(function(success){   			
		self.permissions = success.data;
	}, function(error){
		console.log(error);
	});   

	function getContact(){
		ContactsService.getContact(id).then(function(success){
			self.title = success.data.contact_name;
			self.contact = success.data;
			self.dataLoaded = true;
			
		}, function(error){
			console.log(error);
		});
	}
	
	if( !self.isNew ){ 
		getContact();
		$timeout(function() { updateTypes(); }, 1000);
	} else {	
		self.contact.contact_types = [];
		self.contact.type = 'Guest';
		self.contact.status = 'Active';
		self.dataLoaded = true;

		$timeout(function() { updateTypes(); }, 1000);
		
	}	

	self.addType = function(type){
		var index = self.contact.contact_types.indexOf(type); 
		index === -1 ? self.contact.contact_types.push(type) :  self.contact.contact_types.splice(index, 1);
		$scope.selectedType = null;
	}
	
	self.saveRecord = function(){
		self.showSaveSpinner = true;
		var promise; var message;	
		if( self.isNew ){
			self.showSaveSpinner = true; 
			promise = ContactsService.postStore(self.contact);
			message = 'Record created';			
		} else {
			promise = ContactsService.postUpdate(id, self.contact);
			message = 'Record updated';			
		}
		
		promise.then(function(success){
			var newId=false;
			if(self.isNew) newId = success.data;
			$scope.contactForm.$setPristine();				
			UtilBroadcastService.showAlert(self, 'Success', message, newId, 'main.contact');								   
		}, function(error){
			console.log(error);
			UtilBroadcastService.showAlert(self,'Failure', 'Unable to create/update record.');
		}).finally(function(){
			UtilBroadcastService.broadcastUpdate();
		});		
		
	}

	self.deleteRecord = function(){
		var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
			'Warning!', 
			'Are you sure you would like to delete this record? Deletions cannot be undone.', 
			'alert-danger')
		);

		modalInstance.result.then(function (msg) {
			ContactsService.getDelete(id).then(function(success){
				var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Record deleted!', 'alert-success', true));						
				$timeout(function() { 
					modalInstance1.close('cancel'); 
					$state.go('main.contacts', {}, {reload: true});
				}, 2000);
				
			}, function(error){
				console.log(error);
				var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
							'Unable to delete record. Please contact a system administrator.', 'alert-danger', true));			
				$timeout(function() { modalInstance1.close('cancel'); }, 2000);
			});
		});

	}

	function updateTypes(){
		if(self.contact.contact_types.length == 0){
			self.contact.types_formatted = 'Please choose at least 1 contact type';
			$scope.contactForm.types_formatted.$setValidity('required', false);
		} else {
			self.contact.types_formatted = '';
			angular.forEach(self.contact.contact_types, function(value, key){
				self.contact.types_formatted = self.contact.types_formatted + $filter('capitalize')($filter('underscoreless')(value)) +', ';
			});
			self.contact.types_formatted = self.contact.types_formatted.substring(0, self.contact.types_formatted.length - 2);
			$scope.contactForm.types_formatted.$setValidity('required', true);
		}
	}

	$scope.$watch(function () {
		return self.contact.contact_types;
	},function(newValue, oldValue){
		if(newValue != oldValue){
			updateTypes();
		}		
	}, true);



}])