angular.module('dashboard.directives')

	.directive('dashboardOccupancyLastYears', function (DashboardService, $filter) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				chartTitle: '@',
			},      
			template:	`<div>
							<div class="row">
								<div class="col-lg-12">
									<div class="panel panel-success">
										<div class="panel-heading" >
											<h3 class="panel-title">{{chartTitle}}</h3>	
										</div>
										<div class="panel-body" >
											<div class="col-lg-3">
												<label class="form-group dropdown-wide" >
													<label>Previous Years</label>
													<select class="form-control" required name="previous_years"  ng-model="previous_year">
														<option value="{{number}}" ng-selected="previous_year==number"
																ng-repeat="number in previous_years">{{number}}</option>                    
													</select>												
												</label>    
											</div>
											
											<div class="col-lg-9">
												
											</div>											
											
										</div>
									</div>
								</div>
							</div>
							
							<div class="row" >
								<div class="col-lg-12">
									<div class="panel panel-success">
										<div class="panel-heading">
											<h3 class="panel-title"><i class="fa fa-long-arrow-right fa-fw"></i> Chart</h3>
										</div>
										<div class="panel-body text-center" >
											<div ng-if="!dataLoaded" class="text-center">
												<i class="fa fa-cog fa-spin" style="font-size:48px"></i>
											</div>
											<div ng-if="dataLoaded">
												<canvas height="100" id="bar" class="chart chart-bar"
													chart-data="occupanyLastYears.data" 
													chart-labels="occupanyLastYears.labels" 
													chart-series="occupanyLastYears.series"		
													chart-colours="occupanyLastYears.colors"
													chart-options="occupanyLastYears.options"
												></canvas>
											</div>
										</div>
									</div>
								</div>							
							</div>
						</div>`,
			link: function (scope, element, attr) {
				
				scope.previous_years = [1,2,3];
				scope.previous_year = '1';
				
				scope.dataLoaded = false;
				scope.occupanyLastYears = {}			
				
				function getOccupancyLastYears(){
	
					scope.dataLoaded = false;
					scope.occupanyLastYears.options = {
						//showToolTips: true,  
						scaleLabel: (label) => $filter('number')(label.value) + '%',
						tooltipTemplate: (data) => `${data.label}: ${data.value}%`,
						
					};
					scope.occupanyLastYears.yAxisID = 'y-axis-1',
					scope.occupanyLastYears.labels = [];
					scope.occupanyLastYears.data = [];
					scope.occupanyLastYears.data[0] = [];
					scope.occupanyLastYears.series = ['Series A'];
					scope.occupanyLastYears.colors = [{
								fillColor: 'rgba(47, 132, 71, 0.8)',
								strokeColor: 'rgba(100, 132, 71, 0.8)',
								highlightFill: 'rgba(100, 132, 71, 0.8)',
								highlightStroke: 'rgba(47, 132, 71, 0.8)'
							}],
					
					
					
					DashboardService.getOccupancyLastYears(scope.previous_year).then(function(success){							
						for(var i=0; i<success.data.length; i++){							
							scope.occupanyLastYears.labels[i] = success.data[i].title;
							scope.occupanyLastYears.data[0][i] = success.data[i].occupancy;										
						}					
					}, function(error){
						console.log(error);			
					}).finally(function() {			
						scope.dataLoaded = true;
					});
				}
				
				getOccupancyLastYears();

				scope.$watch('previous_year', function(newVal, oldVal){
					if(newVal != oldVal) getOccupancyLastYears();
				});
				
			}
		};
	})