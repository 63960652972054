angular.module('channel-manager')

	.controller('CmPushedReservationsController', ['$state', 'ChannelManagerService', '$scope', 'PermissionsService',
		function ($state, ChannelManagerService, $scope, PermissionsService) {
			var self = this;

			self.dataLoaded = false;
			self.reservations = [];
			self.maxSize = 5;
			self.permissions = [];
			self.canCreate = false;
			self.canShow = false;
			self.showSpinner = false;
			self.search_value;
			self.sortASC = true;

			var config = {
				params: {
					page: self.reservations.current_page,
					per_page: 30
				}
			};

			PermissionsService.getByModuleByUser('channel-manager').then(function (success) {
				self.permissions = success.data;
			}, function (error) {
				console.log(error);
			});


			function getPushedReservations() {
				 config.params.orderby = 'cm_reservations.id';
				 config.params.sort = 'DESC';
				self.dataLoaded = false;
				ChannelManagerService.getReservationsPaginate(config).then(function (success) {
					self.reservations = success.data;
				}, function (error) {
					console.log(error);
				}).finally(function () {
					self.dataLoaded = true;
					self.showSpinner = false;
				});
			}

			getPushedReservations();


			self.go = function (id) {
				 $state.go('main.channel-manager-reservation', {'id': id}, {reload: true});
			}

			self.pagination = function () {
				config.params.page = self.reservations.current_page;
				getPushedReservations();
			}
			

			self.sortTable = function (heading) {
				config.params.orderby = heading;
				config.params.sort = (self.sortASC) ? 'ASC' : 'DESC';
				self.sortASC = !self.sortASC;
				getPushedReservations();

			}

			self.search = function () {
				self.reservations.current_page = 1;
				config.params.page = self.reservations.current_page;
				config.params.search = self.search_value;
				getPushedReservations();
			}
		}])