angular.module('form-controls.directives')

.factory("ModalAddOrganisation", [function(){
	 
	var modalAddOrganisation = {};          
	
	
	modalAddOrganisation.addOrganisation = function(){ 
		return {
                template: 
				`<form name="organisationForm" novalidate>
					<div class="modal-header">
						<h3 class="modal-title">Add Organisation</h3>
					</div>
					<div class="modal-body default">           
                    
						<div class="form-group" ng-class="{'has-error': organisationForm.organisation_name.$invalid && organisationForm.organisation_name.$touched}">
							<label>Name</label>
							<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="organisation_name"
								   ng-model="organisation.organisation_name" required />
							<p ng-show="organisationForm.organisation_name.$invalid && organisationForm.organisation_name.$touched" class="help-block">Required</p> 
						</div>

						<div class="form-group"
							ng-class="{'has-error': organisationForm.address1.$invalid && organisationForm.address1.$touched}">
							<label>Address 1</label>
							<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control"
								name="address1" ng-model="organisation.address1" />
							<p ng-show="organisationForm.address1.$invalid && organisationForm.address1.$touched"
								class="help-block">Required</p>
						</div>

						<div class="form-group"
							ng-class="{'has-error': organisationForm.address2.$invalid && organisationForm.address2$touched}">
							<label>Address 2</label>
							<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control"
								name="address2" ng-model="organisation.address2" />
							<p ng-show="organisationForm.address2.$invalid && organisationForm.address2.$touched"
								class="help-block">Required</p>
						</div>

						<div class="form-group"
							ng-class="{'has-error': organisationForm.town.$invalid && organisationForm.town.$touched}">
							<label>Town</label>
							<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="town"
								ng-model="organisation.town" />
							<p ng-show="organisationForm.town.$invalid && organisationForm.town.$touched"
								class="help-block">Required</p>
						</div>

						<div class="form-group"
							ng-class="{'has-error': organisationForm.city.$invalid && organisationForm.city.$touched}">
							<label>City</label>
							<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="city"
								ng-model="organisation.city" />
							<p ng-show="organisationForm.city.$invalid && organisationForm.city.$touched"
								class="help-block">Required</p>
						</div>

						<div class="form-group"
							ng-class="{'has-error': organisationForm.postcode.$invalid && organisationForm.postcode.$touched}">
							<label>Postcode</label>
							<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control"
								name="postcode" ng-model="organisation.postcode" />
							<p ng-show="organisationForm.postcode.$invalid && organisationForm.postcode.$touched"
								class="help-block">Required</p>
						</div>
						
						<div class="form-group" ng-class="{'has-error': organisationForm.tel_number.$invalid && organisationForm.tel_number.$touched}">
							<label>Telephone Number</label>
							<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="tel_number"
								   ng-model="organisation.tel_number" />
							<p ng-show="organisationForm.tel_number.$invalid && organisationForm.tel_number.$touched" class="help-block">Required</p> 
						</div>
						
						<div class="form-group" ng-class="{'has-error': organisationForm.email_address.$invalid && organisationForm.email_address.$touched}">
							<label>Email Address</label>
							<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="email_address"
								   ng-model="organisation.email_address" />
							<p ng-show="organisationForm.email_address.$invalid && organisationForm.email_address.$touched" class="help-block">Please Enter Valid Email Address</p> 
						</div>

						<div class="form-group" ng-class="{'has-error': organisationForm.status.$invalid && organisationForm.status.$touched}">
							<label>Status</label>
							<select class="form-control" name="status" ng-model="organisation.status" required>
								<option value="active">Active</option>
								<option value="inactive">Inactive</option>                        
							</select>
							<p ng-show="organisationForm.status.$invalid && organisationForm.status.$touched" class="help-block">
								Required
							</p>
						</div>
						
					</div>
					<div class="modal-footer">   	
						<div class="form-group">
							<div class="row">
								<div class="col-sm-3">
									<my-save-button
										click-fn="save()"
										show-spinner="showSaveSpinner"												
										ng-disabled="organisationForm.$invalid"									
									></my-save-button>
									
								</div>
								<div class="col-sm-6">
									<my-form-alert				
										show-alert="showAlert"
										alert-type="alertDetails.type"
										alert-message="alertDetails.message"											
									></my-form-alert>											
								</div>
								<div class="col-sm-3">
									<button type="button" class="btn btn-lg btn-default btn-outline btn-block" 
										ng-click="cancel()">Cancel</button>										
								</div>	
							</div>    
						</div>
					</div>
                </form>`,
				animation: true,                  
				controller: function ($uibModalInstance, $scope, OrganisationsService, 
										 UtilBroadcastService, $timeout) {	
					var config = {	params: {}	};
					
					$scope.organisation = {};
					$scope.organisation.type = 'client';
					$scope.organisation.status = 'active';
					
					$scope.showSaveSpinner = false;	
					$scope.showAlert = false;
					$scope.alertDetails = {};					
											
					$scope.save = function () {						
						$scope.showSaveSpinner = true; 							
						var newId;
						OrganisationsService.postStore($scope.organisation).then(function(success){							
							newId=success.data;								
							UtilBroadcastService.showAlert($scope, 'Success', 'Record created', false);								   
						}, function(error){
							console.log(error);
							UtilBroadcastService.showAlert($scope,'Failure', 'Unable to create/update record.');
						}).finally(function(){
							$timeout(function() {
								$uibModalInstance.close(newId);
							}, 2000);
							
						});	
						
						 
					};
					
					$scope.cancel = function() {
					   $uibModalInstance.dismiss();
					}
				}
            };
			
		
	}	
	
			
	return modalAddOrganisation;
}])
