angular.module('marketing')

	.controller('MarketingController', ['MarketingService', '$uibModal', 'ModalConfirmAction', 'ModalFeedback', '$timeout', '$scope', '$filter', 'Upload', 'DevelopmentsService', 'EmailTemplatesService', '$location',
		function (MarketingService, $uibModal, ModalConfirmAction, ModalFeedback, $timeout, $scope, $filter, Upload, DevelopmentsService, EmailTemplatesService, $location) {

			var self = this;
			self.showSendSpinner = false;
			self.showNewTemplateSpinner = false;
			self.showUpdateTemplateSpinner = false;
			self.marketing = {};
			self.marketing.groups = [];
			self.marketing.guests_in_developments = [];
			self.developments = [];
			self.email_templates = [];
			self.dataLoadedEmailTemplates = true;
			self.current_email_template = {};
			self.location = $location.protocol() + '://' + $location.host();


			var config = {
				params: { 	
					type:'marketing'
				}				 
			};


			self.groupEmailTypes = MarketingService.getGroupEmailTypes();

			function getDevelopments(){
				DevelopmentsService.getDevelopments().then(function(success){
					self.developments = success.data;
				}, 
				function(error){
					console.log(error);
				});
			}

			getDevelopments();

			function getEmailTemplates(){
				EmailTemplatesService.getEmailTemplates(config).then(function(success){
					self.email_templates = success.data;
				}, function(error){
					console.log(error);
				});
			}

			getEmailTemplates();


			self.getEmailTemplate = function(email_template_id){
				EmailTemplatesService.getShow(email_template_id).then(function(success){
					self.marketing.subject = success.data.subject;
					self.marketing.body = success.data.body;
					self.marketing.title = success.data.title;

					self.current_email_template = success.data;
				}, function(error){
					console.log(error);
				});
			}
			

			self.addEmailGroup = function (group) {

				var index = self.marketing.groups.indexOf(group);
				index === -1 ? self.marketing.groups.push(group) : self.marketing.groups.splice(index, 1);

				self.marketing.groups_formatted = '';
				angular.forEach(self.marketing.groups, function (value, key) {
					self.marketing.groups_formatted = self.marketing.groups_formatted + $filter('capitalize')($filter('underscoreless')(value)) + ', ';
				});
				self.marketing.groups_formatted = self.marketing.groups_formatted.substring(0, self.marketing.groups_formatted.length - 2);
				$scope.selectedGroup = null;
			}

			self.saveNewEmailTemplate = function(){

				var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
					'Warning!',
					'Are you sure you would like to save this email template?',
					'alert-danger')
				);

				modalInstance.result.then(function (msg) {

					self.showNewTemplateSpinner = true;

					var data = {};
					data.title = self.marketing.title;
					data.subject = self.marketing.subject;
					data.body = self.marketing.body;
					data.type = 'marketing';

					EmailTemplatesService.postStore(data).then(function(success){
						var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Template Saved!', 'alert-success', true));
						$timeout(function () {
							modalInstance1.close('cancel');
						}, 2000);
					}, function(error){
						console.log(error);
						var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
							'Unable to save template. Please contact a system administrator.', 'alert-danger', true));
						$timeout(function () { modalInstance1.close('cancel'); }, 2000);
					}).finally(function(){
						getEmailTemplates();
						self.showNewTemplateSpinner = false;
					});

				});
			}

			self.updateExistingTemplate = function(){
				var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
					'Warning!',
					'Are you sure you would like to update this email template?',
					'alert-danger')
				);

				modalInstance.result.then(function (msg) {

					self.showUpdateTemplateSpinner = true;

					var data = {};
					data.title = self.marketing.title;
					data.subject = self.marketing.subject;
					data.body = self.marketing.body;
					data.type = 'marketing';

					EmailTemplatesService.postUpdate(self.current_email_template.id, data).then(function(success){
						var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Template Updated!', 'alert-success', true));
						$timeout(function () {
							modalInstance1.close('cancel');
						}, 2000);
					}, function(error){
						console.log(error);
						var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
							'Unable to update template. Please contact a system administrator.', 'alert-danger', true));
						$timeout(function () { modalInstance1.close('cancel'); }, 2000);
					}).finally(function(){
						getEmailTemplates();
						self.showUpdateTemplateSpinner = false;
					});

				});
			}

			self.sendEmail = function () {

				
				var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
					'Warning!',
					'Are you sure you would like to send this email?',
					'alert-danger')
				);

				modalInstance.result.then(function (msg) {
					self.showSendSpinner = true;

					self.marketing.guests_in_developments = [];

					angular.forEach(self.guests_in_developments, function(value, key) {
						if(value){self.marketing.guests_in_developments.push(key)};
					});

					var url = 'api/marketing/send-email';

					Upload.upload({
						url: url,
						data: {
							file: self.attachment,
							'to_free': self.marketing.to_free,
							'current_guests_all': self.marketing.current_guests_all,
							'guests_in_developments': self.marketing.guests_in_developments, 
							'previous_guests': self.marketing.previous_guests,
							'supply_team': self.marketing.supply_team,
							'reservations_team': self.marketing.reservations_team,
							'direct_client': self.marketing.direct_client,
							'potential_client': self.marketing.potential_client,
							'potential_agent': self.marketing.potential_agent,
							'agent': self.marketing.agent,
							'cc': null,
							'bcc': null,
							'subject': self.marketing.subject,
							'body': self.marketing.body
						}
					}).then(function (resp) {
						if (self.attachment) console.log('Success ' + resp.config.data.file.name + 'uploaded. Response: ' + resp.data);
						$scope.marketingEmailForm.$setPristine();
						var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Email Sent!', 'alert-success', true));
						$timeout(function () {
							modalInstance1.close('cancel');
						}, 2000);

					}, function (resp) {
						console.log('Error status: ' + resp.status);
						var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
							'Unable to send email. Please contact a system administrator.', 'alert-danger', true));
						$timeout(function () { modalInstance1.close('cancel'); }, 2000);
					}, function (evt) {
						var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);

						if (self.attachment) console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
					}).finally(function () {
						self.showSendSpinner = false;
					});

				});
			}

			self.tinymceOptions = {
				height: 500,
				plugins: [
					"advlist autolink lists link image charmap print preview anchor",
					"searchreplace visualblocks code fullscreen",
					"insertdatetime media table paste imagetools wordcount"
				],
				skin: 'oxide',
				theme: 'silver',
				formats: {
					alignleft: {selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table', styles: {textAlign: 'left'}},
					aligncenter: {selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table', styles: {textAlign: 'center'}},
					alignright: {selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table', styles: {textAlign: 'right'}}
				}
			};



		}])