angular.module('dashboard.directives')

	.directive('dashboardInvoiceVat', function (DashboardService, $filter) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				chartTitle: '@',
				type: '@'
			},      
			template:	`<div>
							<div class="row">
								<div class="col-lg-12">
									<div class="panel panel-primary">
										<div class="panel-heading" >
											<h3 class="panel-title"><i class="fa fa-long-arrow-right fa-fw"></i> {{chartTitle}}</h3>
										</div>
										<div class="panel-body" >
											<div class="col-lg-12">																							
												<div uib-datepicker 
												ng-model="selected_date"
													class="well well-sm" datepicker-options="datePickerOptions"
													datepicker-localdate ng-change="getInvoiceData()">
												</div>
												
											</div>
										
											<div ng-if="!dataLoaded" class="text-center">
												<i class="fa fa-cog fa-spin" style="font-size:48px"></i>
											</div>
											<div ng-if="dataLoaded">
												<canvas height="250px"  id="base" class="chart-horizontal-bar"
													chart-data="chart.data" 
													chart-labels="chart.labels" 
													chart-series="chart.series"
													chart-colors="chart.colors"
													chart-options="chart.options"		
												
													
												></canvas>

											</div>
										</div>
									</div>
								</div>							
							</div>
						</div>`,
			link: function (scope, element, attr) {
				
			
				scope.dataLoaded = false;
				scope.dates = {};
				scope.chart = {};		

				var series;
				var type;
				var colors = {};

				if(scope.type=="turnover"){
					series = "Turnover Per Month";
					type = "TurnoverPerMonth";
					colors.backgroundColor = "rgba(159,50,204, 0.2)";
					colors.borderColor = "rgba(159,50,204, 1)";
				}

				if(scope.type=="vat_per_month"){
					series = "VAT Per Month";
					type = "TotalVatPerMonth";
					colors.backgroundColor = "rgba(0,100,204, 0.2)";
					colors.borderColor = "rgba(0,100,204, 1)";
				}

				if(scope.type=="vat_per_quarter"){
					series = "VAT Per Quarter";
					type = "TotalVatPerQuarter";
					colors.backgroundColor = "rgba(255,100,100, 0.2)";
					colors.borderColor = "rgba(255,100,100, 1)";
				}

				scope.datePickerOptions = {
					monthColumns: 3, minMode: 'month'
				}
								
				function defaultDates(){
					scope.selected_date =  moment.utc().startOf('month');								
				}
				
				defaultDates();				
				
				scope.getInvoiceData = function(){
					scope.dataLoaded = false;

					scope.selected_date = moment(scope.selected_date);

					scope.dates.start_date = scope.selected_date.clone().add(-12, 'months').startOf('month').format('YYYY-MM-DD');				
					scope.dates.end_date = scope.selected_date.clone().endOf('month').format('YYYY-MM-DD');		
					
					var object = {};
					object.end_date = scope.dates.end_date;		
					object.start_date = scope.dates.start_date;	
					object.type = type;
					
					scope.chart.options = {
						onClick: function(e) {
							var element = this.getElementAtEvent(e);
							if (element.length) {
							   console.log(element[0])
							}
						 },
						 legend: {
							display: true,
							labels: {
								fontColor: 'rgb(255, 99, 132)'								
							},
							position: 'top'
						},
						 tooltips: {
							callbacks: {
								label: function(tooltipItem, data) {		
									return $filter('currency')(tooltipItem.value, '£');									
								}
							}
						}
					};
					
					scope.chart.labels = [];
					scope.chart.data = [];		
					scope.chart.data[0] = [];		
					scope.chart.series = [series];
					scope.chart.colors = [colors];
					
					DashboardService.postInvoiceFigures(object).then(function(success){	
						
						angular.forEach(success.data, function(value, key) {		
							if(scope.type=="vat_per_quarter"){
								scope.chart.labels.push(value.label);
							}
							else {
								scope.chart.labels.push(moment(value.label).format("MMM-YYYY"));
							}
							
							scope.chart.data[0].push(value.value);						
						  });				

					}, function(error){
						console.log(error);			
					}).finally(function() {			
						scope.dataLoaded = true;
					});
				}
				
				scope.getInvoiceData();								
				
			}
		};
	})