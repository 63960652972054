angular.module('enquiries', [])

    .controller('EnquiriesController', ['$state', 'EnquiryService', 'PermissionsService',
        function($state, EnquiryService, PermissionsService) {
        var self = this;
        
        self.dataLoaded = false;
        self.enquiries= [];        
        self.maxSize = 5;          
		self.permissions = [];       
		self.showSpinner = false;
		self.search_value;
		self.sortASC = true;		
		self.enquiry_states = ['active', 'converted'];
		
        
		var config = {
			params: { 	
				page:self.enquiries.current_page, 
				orderby: 'enquiries.id',
				active: true,
				sort: 'DESC',
				per_page:30
			}				 
		};
		
		PermissionsService.getByModuleByUser('bookings').then(function(success){   			
            self.permissions = success.data;
        }, function(error){
            console.log(error);
        });      
        
		
        function getEnquiries(){					
			self.dataLoaded = false;
			EnquiryService.getEnquiriesPaginate(config).then(function(success){
				self.enquiries = success.data;							
			}, function(error){
				console.log(error);
			}).finally(function(){
				self.dataLoaded = true;				
			});
        }

        getEnquiries();
		
		self.updateState = function(state){			
			config.params.active = (state == 'active');		
			getEnquiries();
		}

        self.updateStatus = function(status){
			self.showSpinner = true;			
			getEnquiries();
        }

        self.go = function(id){
            $state.go('main.enquiry', {'id': id}, {reload: true});
        }

        self.pagination = function(){			
			config.params.page = self.enquiries.current_page;
			getEnquiries();
		}
		
		self.sortTable = function(heading){
			config.params.orderby = heading;
			config.params.sort = (self.sortASC) ? 'ASC' : 'DESC';	
			self.sortASC = !self.sortASC;
            getEnquiries();
           
        }
		
		self.search = function(){
			self.enquiries.current_page = 1;
			config.params.page = self.enquiries.current_page;
			config.params.search = self.search_value;
			getEnquiries();
		}
    }])