angular.module('permissions.controllers', [])

.controller('PermissionsController', ['PermissionsService', '$uibModal', '$timeout', '$group', 'UtilBroadcastService',
        function(PermissionsService, $uibModal, $timeout, $group, UtilBroadcastService) {
    var self = this;
    self.permissions = []; 
	self.formPermissions = [];
    self.selection = {};
    self.showSpinner = false;
	self.showSaveSpinner = false;	
	self.showAlert = false;
	self.alertDetails = {};    
    self.module = 'Select';
	self.group = $group;
	self.dataLoaded = false;
	
	PermissionsService.getByModuleByUser('permissions').then(function(success){   			
        self.formPermissions = success.data;
	}, function(error){
		console.log(error);
	});   
    
    
    self.roles = ['director','office_manager','guest_service_manager','user'];
    
    self.include = function(obj, role){
        var role_exists = false
        for (var i=0; i < obj.length; i++) {
            if (obj[i].name === role) {
                role_exists = true;
            }
        }
        return role_exists;
    }
    
    function setModel(){
        angular.forEach(self.permissions, function(valuePermission, keyPermission) {
            angular.forEach(self.roles, function(valueRole, keyRole) {                
                self.selection[valuePermission.name+'.'+valueRole] = self.include(valuePermission.roles, valueRole)
            });
        });
    }
	
    function getPermissionsByModule(){
		self.dataLoaded = false;        
        PermissionsService.getPermissions($group).then(function(success){  		
            self.permissions = []; 
            self.permissions = success.data;            
            self.selection = {};
            setModel();           
        }, function(error){
            console.log(error);
        }).finally(function(){
			self.dataLoaded = true;
		});
    }
    
    
    getPermissionsByModule();
	
	self.save = function(){
		self.showSaveSpinner = true; 
		var promise; var message;	
		
		promise = PermissionsService.postPermissions(self.selection);
		message = 'Permissions updated';			
		
		promise.then(function(success){						
			UtilBroadcastService.showAlert(self, 'Success', message, false, false);								   
		}, function(error){
			console.log(error);
			UtilBroadcastService.showAlert(self,'Failure', 'Unable to create/update record.');
		});        
    }
  

}])