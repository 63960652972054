angular.module('angular-utils.directives', [])

.directive('myFormAlert', function () {
	return {
		restrict : 'EAC',
		replace : true,
		scope :{	
			showAlert: '=',
			alertType: '=',
			alertMessage: '='
		},      
		template:	`<div ng-show="showMe" class="alert show-fade" style="width: 100%; text-align:center;">
						<strong>{{alertType}}!</strong> {{alertMessage}}
					</div>`,
		link: function (scope, element, attr) {	
			scope.showMe = false;
			scope.$watch('showAlert', function() { 
				if(scope.alertType == 'Success') element.addClass('alert-success');
				if(scope.alertType == 'Failure') element.addClass('alert-danger');
				scope.showMe = scope.showAlert;
			});
			
				
			
		}
	};
})

