angular.module('angular-utils.services')

	.factory('UtilService', ['$rootScope', function ($rootScope) {

		var utilService = {};

		utilService.include = function (arr, obj) {
			for (var i = 0; i < arr.length; i++) {
				if (arr[i] == obj) return true;
			}
		};

		function deg2rad(deg) {
			return deg * (Math.PI / 180)
		}

		utilService.getDistance = function (lat1, lon1, lat2, lon2) {
			var R = 6371; // Radius of the earth in km
			var dLat = deg2rad(lat2 - lat1);  // deg2rad below
			var dLon = deg2rad(lon2 - lon1);
			var a =
				Math.sin(dLat / 2) * Math.sin(dLat / 2) +
				Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
				Math.sin(dLon / 2) * Math.sin(dLon / 2)
				;
			var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
			var d = R * c; // Distance in km
			return d;
		};

		utilService.orderObjectBy = function (items, field, reverse) {
			var filtered = [];
			angular.forEach(items, function (item, key) {
				item["key"] = key;
				filtered.push(item);
			});
			filtered.sort(function (a, b) {
				if (a[field] > b[field]) return 1;
				if (a[field] < b[field]) return -1;
				return 0;
			});
			if (reverse) filtered.reverse();
			//console.log(JSON.stringify(filtered));
			return filtered;
		};

		utilService.findObjectByKey = function (arrayOfObjects, key, value) {
			var object;
			for (var i = 0; i < arrayOfObjects.length; i++) {
				if (arrayOfObjects[i].hasOwnProperty(key)) {
					if (arrayOfObjects[i][key] == value) object = arrayOfObjects[i];
				}
			}
			return object;
		};

		utilService.addDate = function (date1, days) {
			var date2 = moment.utc(date1).startOf('day').add(days, 'days').format('YYYY-MM-DD');
			return date2;
		};

		utilService.getDateDiff = function (date1, date2) {
			var startDate = moment(date1).startOf('day');
			var endDate = moment(date2).startOf('day');
			var duration = endDate.diff((startDate), 'days');

			return duration;
		};

		utilService.getWeekDay = function (dateString) {
			var daysOfWeek = new Array('Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday');
			return daysOfWeek[new Date(dateString).getDay()];
		};

		utilService.componentToHex = function (c) {
			var hex = c.toString(16);
			return hex.length == 1 ? "0" + hex : hex;
		}

		utilService.rgbToHex = function (r, g, b) {
			return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
		}

		utilService.hexToRgb = function (hex) {
			var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
			return result ? {
			  r: parseInt(result[1], 16),
			  g: parseInt(result[2], 16),
			  b: parseInt(result[3], 16)
			} : null;
		}

		utilService.generateColorShades = function(color, n)
		{
			var r = color.r
			var g = color.g
			var b = color.b
			
			
			r = (r + (33*n)) % 255;
			g = (g + (33*n)) % 255;
			b = (b + (33*n)) % 255;
			
			return {r, g, b}
		}

		utilService.padNumber = function (num) {
			return ("00000" + num).slice(-5);
		}

		utilService.pad = function(n, width, z) {
			z = z || '0';
			n = n + '';
			return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
		}

		utilService.formatHotelId = function(hotelId){
			var id = hotelId.substr(hotelId.length - 2) * 1;
			return id;
		}

		return utilService;


	}])