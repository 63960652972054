angular.module('developments.controllers')

.controller('DevelopmentController', ['DevelopmentsService', '$scope', 'UtilBroadcastService', 
	'AreasService', '$stateParams', '$uibModal', '$state', 'ModalConfirmAction', 'ModalFeedback',  
                        'PermissionsService', 'GoogleMapsService', '$timeout',
        
	function(DevelopmentsService, $scope, UtilBroadcastService, AreasService, $stateParams, $uibModal, $state, 
				ModalConfirmAction, ModalFeedback, PermissionsService, GoogleMapsService, $timeout) {
	var self = this;	
	
	self.dataLoaded = false;
	self.title='Development';
	self.development={};
	self.showSaveSpinner = false;	
	self.showAlert = false;
	self.alertDetails = {};
	var id = $stateParams.id;
	self.isNew = (id=='add-new');
	self.permissions = [];
	var config = {	params: {}	};
	
	PermissionsService.getByModuleByUser('developments').then(function(success){   			
		self.permissions = success.data;
	}, function(error){
		console.log(error);
	});      
   
	function getDevelopment(){
		DevelopmentsService.getDevelopment(id).then(function(success){           
			self.development = success.data;				
			self.dataLoaded = true;			
		}, function(error){
			console.log(error);
		});		
	}
	
	function getAreas(){
		config.params={};
		config.params.orderby = 'areas.area';
		config.params.findby = 'status';
		config.params.findbyvalue = 'active';
		AreasService.getAreas(config).then(function(success){     
			self.areas = success.data;            
		}, function(error){
			console.log(error);
		});  
	}
	
	getAreas();
	
	self.getGeocode = function(){
		GoogleMapsService.getGeocode(self.development.postcode).then(function(success){
			self.development.lat = success.data.results[0].geometry.location.lat;
			self.development.lng = success.data.results[0].geometry.location.lng;
		}, function(error){
			console.log(error);
		});
	}	
	
	if( !self.isNew ){ 
		getDevelopment(); 
	} else {               
		self.dataLoaded = true;
		self.development.scheduler_color = '#DDA5F0';
		self.development.status = 'active';
	}
		
	self.saveRecord = function(){
		self.showSaveSpinner = true; 
		var promise; var message;	
		if( self.isNew ){
			self.showSaveSpinner = true; 
			promise = DevelopmentsService.postStore(self.development);
			message = 'Record created';			
		} else {
			promise = DevelopmentsService.postUpdate(id, self.development);
			message = 'Record updated';			
		}
		
		promise.then(function(success){
			var newId=false;
			if(self.isNew) newId = success.data;
			$scope.developmentForm.$setPristine();				
			UtilBroadcastService.showAlert(self, 'Success', message, newId, 'main.development');								   
		}, function(error){
			console.log(error);
			UtilBroadcastService.showAlert(self,'Failure', 'Unable to create/update record.');
		}).finally(function(){
			UtilBroadcastService.broadcastUpdate();
		});	
	}
	
	self.deleteRecord = function(){
		var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
			'Warning!', 
			'Are you sure you would like to delete this record? Deletions cannot be undone.', 
			'alert-danger')
		);
		
		modalInstance.result.then(function (msg) {
			DevelopmentsService.getDelete(id).then(function(success){
				var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Record deleted!', 'alert-success', true));						
				$timeout(function() { 
					modalInstance1.close('cancel'); 
					$state.go('main.developments', {}, {reload: true});
				}, 2000);
				
			}, function(error){
				console.log(error);
				var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
							'Unable to delete record. Please contact a system administrator.', 'alert-danger', true));			
				$timeout(function() { modalInstance1.close('cancel'); }, 2000);
			});
		});
	  
	}
	
	 
}])