angular.module('form-controls.directives')

	.factory("ModalAddContact", [function () {

		var modalAddContact = {};


		modalAddContact.addContact = function () {
			return {
				template:
					`<form name="contactForm" novalidate>
					<div class="modal-header">
						<h3 class="modal-title">Add Contact</h3>
					</div>
					<div class="modal-body default">     
					
						<div class="row" >
							<div class="col-md-6" >
								<div class="form-group" ng-class="{'has-error': contactForm.contact_name.$invalid && contactForm.contact_name.$touched}">
									<label>Contact Name</label>
									<input  type="text" class="form-control" name="contact_name" 
										ng-model="contact.contact_name" required />
									<p ng-show="contactForm.contact_name.$invalid && contactForm.contact_name.$touched" class="help-block">Required</p>                   
								</div>
							</div>
							<div class="col-md-6" >
								<div class="form-group" ng-class="{'has-error': contactForm.company.$invalid && contactForm.company.$touched}">
									<label>Company</label>
									<input  type="text" class="form-control" name="company" 
										ng-model="contact.company" maxlength="255"/>         
								</div>
							</div>
						</div>

						<div class="row" >
							<div class="col-md-6" >
								<div class="form-group"
									ng-class="{'has-error': contactForm.address_1.$invalid && contactForm.address_1.$touched}">
									<label>Address 1</label>
									<input type="text" class="form-control" name="address_1"
										ng-model="contact.address_1" />
								</div>

							</div>
							<div class="col-md-6" >
								<div class="form-group"
									ng-class="{'has-error': contactForm.address_2.$invalid && contactForm.address_2.$touched}">
									<label>Address 2</label>
									<input type="text" class="form-control" name="address_2"
										ng-model="contact.address_2" />
								</div>					
							</div>
						</div>

						<div class="row" >
							<div class="col-md-4" >

								<div class="form-group"
									ng-class="{'has-error': contactForm.town.$invalid && contactForm.town.$touched}">
									<label>Town</label>
									<input type="text" class="form-control" name="town" ng-model="contact.town" />
								</div>
							</div>
							<div class="col-md-4" >
								<div class="form-group"
									ng-class="{'has-error': contactForm.city.$invalid && contactForm.city.$touched}">
									<label>City</label>
									
									<input type="text" class="form-control" name="city" ng-model="contact.city" />
								</div>					
							</div>
							<div class="col-md-4" >
								<div class="form-group"
									ng-class="{'has-error': contactForm.postcode.$invalid && contactForm.postcode.$touched}">
									<label>Postcode</label>
									<input type="text" class="form-control" name="postcode"
										ng-model="contact.postcode" />
								</div>

							</div>
						</div>

						<div class="row" >
							<div class="col-md-6" >
								<div class="form-group" ng-class="{'has-error': contactForm.email_address.$invalid && contactForm.email_address.$touched}">
									<label>Email Address</label>
									<input  type="email" class="form-control" name="email_address" 
										ng-model="contact.email_address" />
									<p ng-show="contactForm.email_address.$invalid && contactForm.email_address.$touched" class="help-block">Please enter valid email address</p>                   
								</div>
							</div>
							<div class="col-md-6" >
								<div class="form-group" ng-class="{'has-error': contactForm.telephone_number.$invalid && contactForm.telephone_number.$touched}">
									<label>Telephone Number</label>
									<input  type="text" class="form-control" name="telephone_number" 
										ng-model="contact.telephone_number" />
									<p ng-show="contactForm.telephone_number.$invalid && contactForm.telephone_number.$touched" class="help-block">Required</p>                   
								</div>                    
							</div>
						</div>

					

						<div class="row" >
							<div class="col-md-6" >
								<div class="form-group">
									<label>Add/Remove Type</label>
									<select class="form-control" name="add_type" ng-change="addType(selectedType)" ng-model="selectedType">
										<option value="{{type}}" ng-repeat="type in types">{{type | underscoreless | capitalize}}</option>                    
									</select>
								</div>							
							</div>
							<div class="col-md-6" >
									
								<div class="form-group" ng-class="{'has-error': contactForm.types_formatted.$invalid}">
									<label>Type(s)</label>
									<input type="text" class="form-control" name="types_formatted" readonly
										ng-model="contact.types_formatted" />
									<p ng-show="contactForm.types_formatted.$invalid" class="help-block">
										Required - Please add a type from below
									</p>
									
								</div>
					
							</div>
						</div>

						<div class="row" >
							<div class="col-md-6" >
								<div class="form-group" ng-class="{'has-error': contactForm.country_of_residence.$invalid && contactForm.country_of_residence.$touched}">
									<label>Country of Residence</label>
									<input  type="text" class="form-control" name="country_of_residence" 
										ng-model="contact.country_of_residence" maxlength="255"/>         
								</div>

							</div>
							<div class="col-md-6" >
								<div class="form-group" ng-class="{'has-error': contactForm.status.$invalid && contactForm.status.$touched}">
									<label>Status</label>
									<select class="form-control" name="status" ng-model="contact.status" required>
										<option value="Active">Active</option>
										<option value="Inactive">Inactive</option>                        
									</select>
									<p ng-show="contactForm.status.$invalid && contactForm.status.$touched" class="help-block">
										Required
									</p>
								</div>						
							</div>
						</div>			
					</div>
					<div class="modal-footer">   	
						<div class="form-group">
							<div class="row">
								<div class="col-sm-3">
									<my-save-button
										click-fn="save()"
										show-spinner="showSaveSpinner"												
										ng-disabled="contactForm.$invalid"									
									></my-save-button>
									
								</div>
								<div class="col-sm-6">
									<my-form-alert				
										show-alert="showAlert"
										alert-type="alertDetails.type"
										alert-message="alertDetails.message"											
									></my-form-alert>											
								</div>
								<div class="col-sm-3">
									<button type="button" class="btn btn-lg btn-default btn-outline btn-block" 
										ng-click="cancel()">Cancel</button>										
								</div>	
							</div>    
						</div>
					</div>
                </form>`,
				animation: true,
				windowClass: 'app-modal-window',
				controller: function ($uibModalInstance, $scope, ContactsService, $filter,
					UtilBroadcastService, $timeout) {
					$scope.contact = {};
					$scope.contact.type = 'Guest';
					$scope.contact.status = 'Active';
					$scope.contact.contact_types = [];
					$scope.types = ContactsService.getTypes();

					$scope.showSaveSpinner = false;
					$scope.showAlert = false;
					$scope.alertDetails = {};

					$scope.addType = function(type){
						var index = $scope.contact.contact_types.indexOf(type); 
						index === -1 ? $scope.contact.contact_types.push(type) :  $scope.contact.contact_types.splice(index, 1);
						$scope.selectedType = null;
					}

					function updateTypes(){
						if($scope.contact.contact_types.length == 0){
							$scope.contact.types_formatted = 'Please choose at least 1 contact type';
							$scope.contactForm.types_formatted.$setValidity('required', false);
						} else {
							$scope.contact.types_formatted = '';
							angular.forEach($scope.contact.contact_types, function(value, key){
								$scope.contact.types_formatted = $scope.contact.types_formatted + $filter('capitalize')($filter('underscoreless')(value)) +', ';
							});
							$scope.contact.types_formatted = $scope.contact.types_formatted.substring(0, $scope.contact.types_formatted.length - 2);
							$scope.contactForm.types_formatted.$setValidity('required', true);
						}
					}
				
					$scope.$watch(function () {
						return $scope.contact.contact_types;
					},function(newValue, oldValue){
						if(newValue != oldValue){
							updateTypes();
						}		
					}, true);

					$scope.save = function () {
						$scope.showSaveSpinner = true;
						var newId;
						ContactsService.postStore($scope.contact).then(function (success) {
							newId = success.data;
							UtilBroadcastService.showAlert($scope, 'Success', 'Record created', false);
						}, function (error) {
							console.log(error);
							UtilBroadcastService.showAlert($scope, 'Failure', 'Unable to create/update record.');
						}).finally(function () {
							$timeout(function () {
								$uibModalInstance.close(newId);
							}, 2000);

						});


					};

					$scope.cancel = function () {
						$uibModalInstance.dismiss();
					}

				}
			};


		}


		return modalAddContact;
	}])
