angular.module('schedulers.modals')

.factory("ModalSchedulerAddEnquiryDetail", [function(){
	 
	var modalSchedulerAddEnquiryDetail = {};          
	
	
	modalSchedulerAddEnquiryDetail.addDetail = function(data){ 
		return {
			animation: true,     
			template: 
			`<div class="modal-header">
				<h3 class="modal-title">{{title}}</h3>
			</div>
			<div class="modal-body alert">           
				<form name="enquiryDetailForm">
				
					<div >
						<div class="row" >
							<div class="col-md-6">
								<div class="form-group" >       
									<label>Check In Date</label>									
									<p >
									{{data.check_in_date | date}}
									</p>
								</div>
							</div>	
							
							<div class="col-md-6">
								<div class="form-group" >       
									<label>Check Out Date</label>									
									<p >
									{{data.check_out_date | date}}
									</p>
								</div>
							</div>	
						</div>
						
						<div class="row" >
							<div class="col-md-12">
								<organisation-dropdown
									ng-model="data.organisation_id" 
									form-name="enquiryDetailForm" 
									is-new="true"
									show-add-button="true"
									is-disabled="false"
									title="Client">
								</organisation-dropdown>
							</div>	
						</div>
						<div class="row" >
							<div class="col-md-12">
							
								<apartment-dropdown
									ng-model="data.apartment_id" 
									form-name="enquiryDetailForm" 
									is-new="true"
									available-by-dates="true"
									available-start-date="data.check_in_date"
									available-end-date="data.check_out_date"
									title="Apartment">
								</apartment-dropdown>
							
							
							</div>
						</div>
						<div class="row" >
							<div class="col-md-12">
								<div class="form-group" ng-class="{'has-error': enquiryDetailForm.contact.$invalid && enquiryDetailForm.contact.$touched}">       
									<div class="form-group">       
										<label>Contact</label>
										<input type="text" class="form-control" name="contact" ng-model="data.contact" required/> 
										<p ng-show="enquiryDetailForm.contact.$invalid && enquiryDetailForm.contact.$touched" class="help-block">
											Required
										</p>
									</div>
								</div>							
							</div>							
						</div>		
						
						<div class="row">
							<div class="col-md-12">
								<div class="form-group" ng-class="{'has-error': enquiryDetailForm.amount.$invalid && enquiryDetailForm.amount.$touched}">       
									<div class="form-group">       
										<label>Amount</label>
										<input type="text" class="form-control" name="amount" ng-model="data.amount" required/> 
										<p ng-show="enquiryDetailForm.amount.$invalid && enquiryDetailForm.amount$touched" class="help-block">
											Required
										</p>
									</div>
								</div>
							</div>
						</div>
						
						<div class="row" >
							<div class="col-md-12">
								<div class="form-group" ng-class="{'has-error': enquiryDetailForm.notes.$invalid && enquiryDetailForm.notes.$touched}">       
									<div class="form-group">       
										<label>Notes</label>
										<textarea type="text" class="form-control" name="notes" ng-model="data.notes"></textarea>
										<p ng-show="enquiryDetailForm.notes.$invalid && enquiryDetailForm.notes.$touched" class="help-block">
											Required
										</p>
									</div>
								</div>
							</div>					
						</div>
					</div>
				</form>
			</div>
			
			<div class="modal-footer" >    
				<div class="row">
					<div class="col-sm-3">
						<my-save-button
							click-fn="saveRecord()"
							show-spinner="showSaveSpinner"												
							ng-disabled="enquiryDetailForm.$invalid"							
						></my-save-button>
						
					</div>
					<div class="col-sm-6">
						<my-form-alert				
							show-alert="showAlert"
							alert-type="alertDetails.type"
							alert-message="alertDetails.message"											
						></my-form-alert>											
					</div>
					<div class="col-sm-3">
						<button class="btn btn-warning btn-lg btn-block" type="button" ng-click="cancel()">Cancel</button>								
					</div>    
				</div>    
				
			</div>`,
			
			resolve: {					
				title: function(){
					return "Add Enquiry Detail"
				},
				data: function(){
					return data;
				}
			},
			controller: function ($uibModalInstance, $scope, data, title, UtilBroadcastService, $timeout, EnquiryService, ApartmentsService) {	
				
				var config = {};
				$scope.title = title;				
				$scope.data = data;	
				$scope.alertDetails = {};
				$scope.showSaveSpinner = false;
				$scope.showAlert = false;
				
				$scope.enquiry_detail
				
				$scope.addEnquiryDetail = function(){
					console.log('addEnquiryDetail');
				}
				
				
				$scope.saveRecord = function(){
					$scope.showSaveSpinner = true;   
					var promise; var message;	
					
					promise = EnquiryService.postEnquiryDetailStore($scope.data);
					message = 'Record created';			
					
					promise.then(function(success){						
						$scope.enquiryDetailForm.$setPristine();		
						UtilBroadcastService.showAlert($scope, 'Success', message, false);								   
					}, function(error){
						console.log(error);
						UtilBroadcastService.showAlert($scope,'Failure', 'Unable to create/update record.');
					}).finally(function(){
						UtilBroadcastService.broadcastUpdate();
						$timeout(function(){
							$uibModalInstance.close('success');
						}, 2000);
					});					
				}
			
				
				$scope.cancel = function() {
				   $uibModalInstance.dismiss();
				}	
			
			}	
		}
	}		
	return modalSchedulerAddEnquiryDetail;
}])
