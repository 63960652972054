angular.module('schedulers.modals')

	.factory("ModalSchedulerAddEnquiry", [function () {

		var modalSchedulerAddEnquiry = {};


		modalSchedulerAddEnquiry.AddEnquiry = function (query, apartments, permissions) {
			return {
				animation: true,
				template:
					`<div class="modal-header">
				<div class="col-sm-9">
					<h3 class="modal-title">{{title}}</h3>
				</div>
				<div class="col-sm-3">
					<a ng-show="data.id != 'new' " ng-click="goToEnquiry()"
						class="btn btn-primary pull-right"><i class="fa fa-arrow-right" aria-hidden="true"></i> Go To Enquiry</a>
				</div>
			</div>
			<div class="modal-body alert">           
				<form name="enquiryForm" novalidate>
					<div class="row">	
						<div class="col-sm-2">
							<div class="form-group" >       
								<div class="form-group">       
									<label>ID</label>
									<input type="text" class="form-control" name="id" ng-model="data.id" readonly/>          
								</div>
							</div>
						</div>
						<div class="col-md-5">
							<div class="form-group" >       
								<label>Check In Date</label>									
								<p>
								{{data.check_in_date | date}}
								</p>
							</div>
						</div>	
						
						<div class="col-md-5">
							<div class="form-group" >       
								<label>Check Out Date</label>									
								<p>
								{{data.check_out_date | date}}
								</p>
							</div>
						</div>	
					</div>					
					<div class="row" >						
						<div class="col-md-6">
							<organisation-dropdown
								ng-model="data.organisation_id" 
								form-name="enquiryForm" 
								is-new="true"
								show-add-button="true"
								is-disabled="false"
								title="Client*">
							</organisation-dropdown>
						</div>					
						<div class="col-md-6">
							<div class="form-group" ng-class="{'has-error': enquiryForm.contact.$invalid && enquiryForm.contact.$touched}">       
								<div class="form-group">       
									<label>Contact/Telephone*</label>
									<input type="text" class="form-control" name="contact" ng-model="data.contact" required/> 
									<p ng-show="enquiryForm.contact.$invalid && enquiryForm.contact.$touched" class="help-block">
										Required
									</p>
								</div>
							</div>							
						</div>			
					</div>
					<div class="row" >						
						<div class="col-md-6">
							<div class="form-group" ng-class="{'has-error': enquiryForm.email_address.$invalid && enquiryForm.email_address.$touched}">       
								<div class="form-group">       
									<label>Email Address</label>
									<input type="email" class="form-control" name="email_address" ng-model="data.email_address"/> 
									<p ng-show="enquiryForm.email_address.$invalid && enquiryForm.email_address.$touched" class="help-block">
										Please enter a valid email address
									</p>
								</div>
							</div>	
						</div>				
						<div class="col-md-6" ng-class="{'has-error': enquiryForm.apartment_type.$invalid && enquiryForm.apartment_type.$touched}">  
							<label>Apartment Type*</label>
							<select class="form-control" required name="apartment_type"
								ng-model="data.apartment_type">
								<option value="{{apartment_type}}" ng-repeat="apartment_type in booking_apartment_types">{{apartment_type | underscoreless | capitalize}}</option>
							</select>
							<p ng-show="enquiryForm.apartment_type.$invalid && enquiryForm.apartment_type.$touched" class="help-block">
										Required
									</p>
						</div>							
					</div>
					<div class="row" >
						<div class="col-md-12">
							<div class="form-group" ng-class="{'has-error': enquiryForm.notes.$invalid && enquiryForm.notes.$touched}">       
								<div class="form-group">       
									<label>Notes</label>
									<textarea type="text" class="form-control" name="notes" ng-model="data.notes"></textarea>
									<p ng-show="enquiryForm.notes.$invalid && enquiryForm.notes.$touched" class="help-block">
										Required
									</p>
								</div>
							</div>
						</div>					
					</div>

					<div class="row" >						
						<div class="col-md-4">
							<development-dropdown 
								ng-model="data.development_id" 
								form-name="enquiryForm" 
								is-new="true"
								show-add-button="true"
								is-disabled="false"
								title="Development*">
							</development-dropdown>						
						</div>					
						<div class="col-md-4">
							<div class="form-group"
								ng-class="{'has-error':  enquiryForm.rate.$invalid &&  enquiryForm.rate.$touched}">
								<label>Room Rate*</label>
								<input ng-focus="focus=false" ng-blur="focus=true" type="number" class="form-control" min="1"
									max="1000" name="rate" ng-model="data.rate" required />
								<p ng-show=" enquiryForm.rate.$invalid &&  enquiryForm.rate.$touched"
									class="help-block">Required - Please enter a number greater than 0</p>
							</div>								
						</div>		
						<div class="col-md-4">
							<div class="form-group"
								ng-class="{'has-error':  enquiryForm.budget.$invalid &&  enquiryForm.budget.$touched}">
								<label>Budget</label>
								<input ng-focus="focus=false" ng-blur="focus=true" type="number" class="form-control" min="1"
									max="1000" name="budget" ng-model="data.budget" />
								<p ng-show=" enquiryForm.budget.$invalid &&  enquiryForm.budget.$touched"
									class="help-block">Required - Please enter a number greater than 0</p>
							</div>								
						</div>			
					</div>
				</form>    	
			<div class="modal-footer" >    
				<div class="row">					
					<div class="col-sm-3">
						<my-save-button click-fn="saveEnquiry()"
						show-spinner="showSaveEnquirySpinner" ng-disabled="enquiryForm.$invalid"
						ng-show="permissions['bookings.update']"></my-save-button>														
					</div>
					<div class="col-sm-6">
						<my-form-alert show-alert="showAlert"
							alert-type="alertDetails.type"
							alert-message="alertDetails.message"></my-form-alert>														
					</div>					 
					<div class="col-sm-3">
						<button class="btn btn-warning btn-block" type="button" ng-click="cancel()">Cancel</button>
					</div>
				</div>
			</div>`,

				resolve: {
					title: function () {
						return "Add Enquiry"
					},
					query: function () {
						return query;
					},
					apartments: function () {
						return apartments;
					},
					permissions: function () {
						return permissions;
					}
				},
				controller: function ($uibModalInstance, $scope, query, apartments, title, permissions,
					UtilBroadcastService, UtilLookUpService, EnquiryService, ModalConfigurationService, UtilService, $state) {

					var config = {};
					$scope.title = title;
					$scope.permissions = permissions;
					$scope.query = query;
					$scope.apartments = UtilService.orderObjectBy(apartments, 'address1');
					$scope.alertDetails = {};
					$scope.showSaveEnquirySpinner = false;
					$scope.showSaveEnquiryDetailSpinner = false;
					$scope.showAlert = false;
					$scope.enquiries = [];
					$scope.enquiry_details = [];
					$scope.enquiryDetailsDataLoaded = true;
					$scope.data = {};

					$scope.booking_apartment_types = UtilLookUpService.getApartmentTypeForBookings();
					$scope.booking_cancellation_terms = UtilLookUpService.getCancellationTermsForBookings();
					$scope.booking_deposit_information = UtilLookUpService.getDepositInformationForBookings();

					var has_dates = ($scope.query.start_date != null);

					function default_data() {
						$scope.data.id = 'new';
						$scope.data.check_in_date = $scope.query.start_date;
						$scope.data.check_out_date = $scope.query.end_date;

					}

					default_data();

					function reset_enquiry_detail() {
						$scope.enquiry_detail = { apartment_id: null, amount: null };
					}

					reset_enquiry_detail();

					$scope.saveEnquiry = function () {
						$scope.showSaveEnquirySpinner = true;
						const message = 'Record Created';
						EnquiryService.postStore($scope.data).then(function (success) {
							$scope.data.id = success.data;
							$scope.enquiryForm.$setPristine();
							UtilBroadcastService.showAlert($scope, 'Success', message, false, 'main.enquiry');
						}, function (error) {
							console.log('error');
							UtilBroadcastService.showAlert($scope, 'Failure', 'Unable to create/update record.');
						}).finally(function () {
							$scope.showSaveEnquirySpinner = false;
						});
					}

					$scope.saveEnquiryDetail = function () {
						$scope.showSaveEnquiryDetailSpinner = true;
						$scope.enquiry_detail.enquiry_id = $scope.data.id;

						EnquiryService.postEnquiryDetailStore($scope.enquiry_detail).then(function (success) {

						}, function (error) {
							console.log(error);
							UtilBroadcastService.showAlert($scope, 'Failure', 'Unable to create/update record.');
						}).finally(function () {
							$scope.showSaveEnquiryDetailSpinner = false;
							reset_enquiry_detail();
							getEnquiryDetails($scope.data.id);
						});

					}



					function getEnquiryDetails(enquiry_id) {
						$scope.enquiryDetailsDataLoaded = false;
						config = {};
						config = { params: { findby: 'enquiry_id', findbyvalue: enquiry_id } };
						EnquiryService.getEnquiryDetails(config).then(function (success) {
							$scope.enquiry_details = success.data;
						}, function (error) {
							console.log(error);
						}).finally(function () {
							$scope.enquiryDetailsDataLoaded = true;
						});
					}



					$scope.selectApartment = function () {
						var apartment = UtilService.findObjectByKey($scope.apartments, 'id', $scope.enquiry_detail.apartment_id);
						$scope.enquiry_detail.amount = apartment.charge[0];
					}

					$scope.cancel = function () {
						$uibModalInstance.dismiss();
					}

					$scope.goToEnquiry = function () {
						$state.go('main.enquiry', { 'id': $scope.data.id }, {});
						$uibModalInstance.dismiss();
					}


					$scope.convertToBooking = function (item) {

						var input = {};

						input.id = 'new';
						input.check_in_date = $scope.data.check_in_date;
						input.check_out_date = $scope.data.check_out_date;
						input.duration = UtilService.getDateDiff($scope.data.check_in_date, $scope.data.check_out_date);
						input.charge_per_night = item.amount;
						input.apartment_id = item.apartment_id;
						input.organisation_id = $scope.data.organisation_id;
						input.show_booking_start_on_schduler = 1;
						input.show_end_booking_notification = 'Yes';
						input.status = 'active';
						input.type = 'booking';

						input.enquiry_id = $scope.data.id;
						input.bookingdetails = [];


						var modalInstance = ModalConfigurationService.getSchedulerBooking(input, $scope.permissions);

						modalInstance.result.then(function (result) {
							if (result == 'close') {

							} else {
								$state.go('main.booking', { 'id': result }, {});
							}
						}, function (error) {
							console.log(error);
						});

						modalInstance.close('cancel');

					}


				}
			}
		}
		return modalSchedulerAddEnquiry;
	}])
