angular.module('cleanings.controllers')

.controller('CleaningController', ['$scope', 'UtilBroadcastService', 'CleaningsService', '$stateParams', '$uibModal', 
				'$state', 'ModalConfirmAction', 'ModalFeedback', 'PermissionsService', '$timeout',
        function($scope, UtilBroadcastService, CleaningsService, $stateParams, $uibModal, $state, 
						ModalConfirmAction, ModalFeedback, PermissionsService, $timeout) {
    var self = this;
	var id = $stateParams.id;
	var config={};
    self.dataLoaded = false;
    self.title;
    self.cleaning;
	self.showSaveSpinner = false;			
	self.showAlert = false;
	self.alertDetails = {};    
    self.isNew = (id=='add-new');
    self.permissions = [];
    self.cleaning_types = CleaningsService.getTypeForCleaning();
	
	PermissionsService.getByModuleByUser('cleanings').then(function(success){   			
		self.permissions = success.data;
	}, function(error){
		console.log(error);
	});  

    function getCleaning(){
        CleaningsService.getCleaning(id).then(function(success){
            self.cleaning = success.data;
            self.dataLoaded = true;
        }, function(error){
            console.log(error);
        });
    }

    if( !self.isNew ){ 
        getCleaning() ;
    } else {
        self.dataLoaded = true;
    };
		
    self.saveRecord = function(){
		self.showSaveSpinner = true;
		var promise; var message;	
		if( self.isNew ){
			self.showSaveSpinner = true; 
			promise = CleaningsService.postStore(self.cleaning);
			message = 'Record created';			
		} else {
			promise = CleaningsService.postUpdate(id, self.cleaning);
			message = 'Record updated';			
		}
		
		promise.then(function(success){
			var newId=false;
			if(self.isNew) newId = success.data;
			$scope.cleaningForm.$setPristine();				
			UtilBroadcastService.showAlert(self, 'Success', message, newId, 'main.cleaning');								   
		}, function(error){
			console.log(error);
			UtilBroadcastService.showAlert(self,'Failure', 'Unable to create/update record.');
		}).finally(function(){
			UtilBroadcastService.broadcastUpdate();
		});		
    }

    self.deleteRecord = function(){
        var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
				'Warning!', 
				'Are you sure you would like to delete this record? Deletions cannot be undone.', 
				'alert-danger')
			);

        modalInstance.result.then(function (msg) {
            CleaningsService.getDelete(id).then(function(success){
                var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Record deleted!', 'alert-success', true));						
				$timeout(function() { 
					modalInstance1.close('cancel'); 
					$state.go('main.cleanings', {}, {reload: true});
				}, 2000);
				
			}, function(error){
				console.log(error);
				var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
							'Unable to delete record. Please contact a system administrator.', 'alert-danger', true));			
				$timeout(function() { modalInstance1.close('cancel'); }, 2000);
			});
        });

    }


}])