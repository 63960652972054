angular.module('invoices.modals')

.factory("ModalInvoiceDetail", [function(){
	 
	var modalInvoiceDetail = {};          
	
	
	modalInvoiceDetail.show = function(data, permissions){ 
		return {
                template: 
				`<div class="modal-header">
					<h3 class="modal-title">Invoice Detail</h3>
				</div>
				<div class="modal-body alert">    
					<div class="show-slide-side">
						<div class="row">
							<div class="col-md-4">
								Start: {{data.start_date | date:shortDate}}
							</div>
							<div class="col-md-4">
								End: {{data.end_date | date:shortDate}}
							</div>
							<div class="col-md-4">
								Duration: {{data.duration}}
							</div>
						</div>
						<form name="invoiceDetailsForm">
							<input ng-focus="focus=false" ng-blur="focus=true" type="hidden" class="form-control" name="id" ng-model="data.id" readonly="" />
							
							<div class="row">
								<div class="col-md-4">
									<div class="form-group" ng-class="{'has-error': invoiceDetailsForm.invoice_id.$invalid && invoiceDetailsForm.invoice_id.$touched}">       
										<label>Invoice ID</label>
										<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="invoice_id."
											ng-model="data.invoice_id" readonly/>          
									</div>	
								</div>
								<div class="col-md-4">
									<div class="form-group" ng-class="{'has-error': invoiceDetailsForm.quantity.$invalid && invoiceDetailsForm.quantity.$touched}">
									   <label>Quantity *</label>
									   <input ng-focus="focus=false" 
											ng-readonly="!permissions['invoices.update']"
											ng-blur="focus=true" type="text" class="form-control" name="quantity"
											  ng-model="data.quantity" required/>
									   <p ng-show="invoiceDetailsForm.quantity.$invalid && invoiceDetailsForm.quantity.$touched" class="help-block">
													   Required
									   </p>
								   </div>
								</div>
								<div class="col-md-4">
									<div class="form-group" ng-class="{'has-error': invoiceDetailsForm.unit_price.$invalid && invoiceDetailsForm.unit_price.$touched}">
										<label>Unit Price *</label>
										<input ng-focus="focus=false" 
												ng-readonly="!permissions['invoices.update']"
												ng-blur="focus=true" type="text" class="form-control" name="unit_price"
											   ng-model="data.unit_price" required/>
										<p ng-show="invoiceDetailsForm.unit_price.$invalid && invoiceDetailsForm.unit_price.$touched" class="help-block">
													   Required
										</p>
									</div>
								</div>
							</div>											

							
						
							
							<div class="row">
								<div class="col-md-4">
									<div class="form-group" ng-class="{'has-error': invoiceDetailsForm.total.$invalid && invoiceDetailsForm.total.$touched}">
										<label>Total</label>
										<input ng-focus="focus=false"
											ng-blur="focus=true" type="text" class="form-control" name="total"
											ng-model="data.total" readonly required/>
										<p ng-show="invoiceDetailsForm.total.$invalid && invoiceDetailsForm.total.$touched" class="help-block">
														Required
										</p>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group" ng-class="{'has-error': invoiceDetailsForm.vat_rate.$invalid && invoiceDetailsForm.vat_rate.$touched}">
										<label>VAT Rate *</label>
										<input ng-focus="focus=false" 
												ng-readonly="!permissions['invoices.update']"
												ng-blur="focus=true" type="percent" class="form-control" name="vat_rate"
												ng-model="data.vat_rate" xl-percentage required/>
										<p ng-show="invoiceDetailsForm.vat_rate.$invalid && invoiceDetailsForm.vat_rate.$touched" class="help-block">
														Required
										</p>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group" ng-class="{'has-error': invoiceDetailsForm.vat.$invalid && invoiceDetailsForm.vat.$touched}">
									   <label>VAT</label>
									   <input ng-focus="focus=false" 
											ng-blur="focus=true" type="text" class="form-control" name="vat"
											  ng-model="data.vat" readonly required/>
									   <p ng-show="invoiceDetailsForm.vat.$invalid && invoiceDetailsForm.vat.$touched" class="help-block">
													   Required
									   </p>
								   </div>
									
								</div>
							</div>

							<div class="row">
								<div class="col-md-4">
									<label class="form-group dropdown-wide" ng-class="{'has-error': invoiceDetailsForm.type.$invalid && invoiceDetailsForm.type.$touched}">
										<label>Type *</label>
										<select class="form-control" required name="type" ng-model="data.type">
											<option value="{{type}}" ng-selected="data.type===type" ng-repeat="type in invoice_detail_types">{{type
												| capitalize | underscoreless}}</option>
										</select>
										<p ng-show="invoiceDetailsForm.type.$invalid && invoiceDetailsForm.type.$touched" class="help-block">
											Required
										</p>
									</label>
								</div>
								<div class="col-md-8">
									<div class="form-group" ng-class="{'has-error': invoiceDetailsForm.description.$invalid && invoiceDetailsForm.description.$touched}">       
										<label>Description</label>
										<input ng-focus="focus=false" 
												ng-readonly="!permissions['invoices.update']"
												ng-blur="focus=true" 				
												type="text" class="form-control" name="description"
											ng-model="data.description" required/>  
										<p ng-show="invoiceDetailsForm.description.$invalid && invoiceDetailsForm.description.$touched" class="help-block">
														Required
										</p>
									</div>
								</div>
							</div>

						
							
						</form>
					</div>		
				</div>
				<div class="modal-footer" ng-show="section != 'addDetail'">   	
					<div class="form-group">
						<div class="row">
							<div class="col-sm-3">
								<my-save-button
									click-fn="saveRecord()"
									show-spinner="showSaveSpinner"												
									ng-disabled="invoiceDetailsForm.$invalid"									
								></my-save-button>
								
							</div>
							<div class="col-sm-6">
								<my-form-alert				
									show-alert="showAlert"
									alert-type="alertDetails.type"
									alert-message="alertDetails.message"											
								></my-form-alert>											
							</div>
							<div class="col-sm-3">
								<button type="button" class="btn btn-lg btn-default btn-outline btn-block" 
									ng-click="cancel()">Cancel</button>										
							</div>	
						</div>    
					</div>
				</div>
                `,
				animation: true,      
				resolve: {
					data: function () {
					  return data;
					},
					title: function(){
						return "Invoice Details"
					},
					permissions: function(){
						return permissions;
					}
				},
				controller: function($scope, $uibModalInstance, data, title, permissions, 
							InvoicesService, UtilBroadcastService, UtilService, $timeout) {
					
					$scope.title = title;
					$scope.data =  data;
					$scope.showSaveSpinner = false;			
					$scope.showAlert = false;
					$scope.alertDetails = {};
					$scope.permissions = permissions;	
					$scope.invoice_detail_types= InvoicesService.getInvoiceDetailTypes();
					$scope.data.duration = UtilService.getDateDiff($scope.data.start_date, $scope.data.end_date);

					
					function init(){
						if($scope.data.id === 'new'){
							$scope.data.quantity = $scope.data.duration;
							$scope.data.unit_price = $scope.data.charge_per_night;
							$scope.data.description = 'Apartment';
							amendTotal();
						}
					}
								
					init();
					
					
				
					$scope.saveRecord = function () {	
						$scope.showSaveSpinner = true;
						
						var promise; var message;	
						if( $scope.data.id==='new' ){							
							promise = InvoicesService.postInvoiceDetailStore($scope.data);
							message = 'Record created';			
						} else {
							promise = InvoicesService.postInvoiceDetailUpdate($scope.data.id, $scope.data);
							message = 'Record updated';			
						}
						
						promise.then(function(success){						
							$scope.invoiceDetailsForm.$setPristine();		
							UtilBroadcastService.showAlert($scope, 'Success', message, false);	
							if($scope.data.id==='new') $scope.data.id = success.data;
						}, function(error){
							console.log(error);
							UtilBroadcastService.showAlert($scope,'Failure', 'Unable to create/update record.');
						}).finally(function(){
							UtilBroadcastService.broadcastUpdate();							
							$timeout(function() {
								$uibModalInstance.close('close');
							}, 2000);						
						});							
					}

					$scope.cancel = function() {
						$uibModalInstance.dismiss();
					}

					function amendTotal(){
						//console.log()
						if ($scope.data.quantity == null || $scope.data.unit_price == null) return;
						$scope.data.total = ($scope.data.quantity * $scope.data.unit_price).toFixed(2);
						amendVat();
					}

					function amendVat(){
						//console.log()
						if ($scope.data.total == null || $scope.data.vat_rate == null) return;
						$scope.data.vat = ($scope.data.total * $scope.data.vat_rate).toFixed(2);
					}

					$scope.$watch(function () {
						return $scope.data.quantity;
					}, function (value) {
						amendTotal();
					}, true);

					$scope.$watch(function () {
						return $scope.data.unit_price;
					}, function (value) {
						amendTotal();
					}, true);

					$scope.$watch(function () {
						return $scope.data.vat_rate;
					}, function (value) {
						amendVat();
					}, true);

				}
            };
	}	
	
			
	return modalInvoiceDetail;
}])
