angular.module('users.controllers')

.controller('ProfileController', ['$scope', 'UsersService', '$stateParams', '$uibModal', '$state', 
				'PermissionsService', '$timeout', 'UtilBroadcastService',
			
	function($scope, UsersService,$stateParams, $uibModal, $state, PermissionsService, $timeout, UtilBroadcastService) {
	var self = this;	
	
	var id = $stateParams.id;		
	self.dataLoaded = false;	
	self.showSaveSpinner = false;			
	self.showAlert = false;
	self.alertDetails = {};    
			
	/********************* End ******************/ 
   
	function getUser(){
		UsersService.getProfile().then(function(success){  	   
			self.user = success.data;
			self.dataLoaded = true;				
		}, function(error){
			console.log(error);
		});			
	}

	getUser();
	

	self.updatePassword = function(){
		self.showSaveSpinner = true;  
		
		UsersService.postProfileUpdate(self.user).then(function(success){  		
			UtilBroadcastService.showAlert(self, 'Success', 'Password updated.', false, false);	
			self.user.password ='';
			self.user.old_password ='';
			self.user.repeat_password ='';
			$scope.userForm.$setPristine();	
			$scope.userForm.$setUntouched();
		}, function(error){
			console.log(error);
			UtilBroadcastService.showAlert(self,'Failure', 'Unable to update password.');
		});		
	}
	
	 
}])