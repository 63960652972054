angular.module('schedulers.controllers')

.controller('SchedulerApartmentOrderController', [ 'DevelopmentsService', 'ApartmentsService', 
					'SchedulersService', '$uibModal', 'ModalFeedback', '$timeout',
				function(DevelopmentsService, ApartmentsService, SchedulersService, $uibModal, ModalFeedback, $timeout) {
	var self = this;
	
	self.developments = [];
	self.apartments = [];
	self.showSpinner = 0;
	self.apartmentsType='active';
	
	function getDevelopments(){
		var config={};
		config.params = { 	orderby: 'developments.name', dropdown: true	};
		DevelopmentsService.getDevelopments(config).then(function(success){
			self.developments = success.data;				
		}, function(error){
			console.log(error);
		});
	}
	
	getDevelopments();
	
	self.showApartments = function(){
		var config;
		if(self.development_id != null){
			config = {
					params: { 	
								findby: 'development_id',
								findbyvalue: self.development_id,
								dropdown: true									
							}				 
				};			
				
			if(self.apartmentsType=='active'){					
				config.params.status = 'active';
			} 
			
			ApartmentsService.getApartments(config).then(function(success){					
				self.apartments = success.data;
			}, function(error){
				console.log(error);
			});	
		}
		
	}
	
	function swapArrayElements(indexA, indexB) {
		var temp = self.apartments[indexA];
		self.apartments[indexA] = self.apartments[indexB];
		self.apartments[indexB] = temp;
	};
	
	self.moveApartment = function(direction, index){
		if(direction==='up'){
			if(index>0){
				swapArrayElements(index, index-1);
			}
		}
		if(direction==='down'){
			if(index<self.apartments.length-1){
				swapArrayElements(index, index+1);
			}
		}
		if(direction==='upFull'){
			if(index>0){
				for(var i = index; i>0; i--){						
					swapArrayElements(i, i-1);
				}
			}
		}
		if(direction==='downFull'){
			if(index<self.apartments.length-1){
				for(var i = index; i<self.apartments.length-1; i++){					
					swapArrayElements(i, i+1);
				}
			}
		}
	}
	
	self.save = function(){
		self.showSpinner = 1;
		SchedulersService.postApartmentOrder(self.apartments).then(function(success){
			self.showSpinner = 0;
			var modalInstance = $uibModal.open(ModalFeedback.showFeedback('Success', 'Records updated!', 'alert-success', true));						
			$timeout(function() { 
				modalInstance.close('cancel'); 						
			}, 2000);				
		}, function(error){
			console.log(error);
		});
	}
		
}])