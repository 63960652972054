angular.module('reports.directives')
	
.directive('reportDetails', [function() {
	return {
		restrict: 'EA',    
		replace: true,     
		scope:{
			reportItems:"="
		},		
		templateUrl : 'templates/reports/reports-details.html',		
		link : function(scope, element, attrs) {
			
		}
	}
}])