angular.module('apartments.modals')

.factory("ModalApartmentCharges", [function(){
	 
	var modalApartmentCharges = {};          
	
	
	modalApartmentCharges.showCharge = function(data, permissions){ 
		return {
                template: 
				`<form name="apartmentChargesForm" novalidate>
					<div class="modal-header">
						<h3 class="modal-title">Rates</h3>
					</div>
					<div class="modal-body default">  				
						 <div class="form-group" ng-class="{'has-error': apartmentChargesForm.start_nights.$invalid && apartmentChargesForm.start_nights.$touched}">
							<label>Start Nights</label>
							<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="start_nights"
								ng-readonly="!permissions['apartments.update']"
								   ng-model="data.start_nights" required/>
							<p ng-show="apartmentChargesForm.start_nights.$invalid && apartmentChargesForm.start_nights.$touched" class="help-block">
											Required
							</p>
						</div>
						<div class="form-group" ng-class="{'has-error': apartmentChargesForm.end_nights.$invalid && apartmentChargesForm.end_nights.$touched}">
							<label>End Nights</label>
							<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="end_nights"
								ng-readonly="!permissions['apartments.update']"
								ng-model="data.end_nights" required/>
							<p ng-show="apartmentChargesForm.end_nights.$invalid && apartmentChargesForm.end_nights.$touched" class="help-block">
											Required
							</p>
						</div> 
						<div class="form-group" ng-class="{'has-error': apartmentChargesForm.amount.$invalid && apartmentChargesForm.amount.$touched}">
							<label>Amount</label>
							<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="amount"
								ng-readonly="!permissions['apartments.update']"
							   ng-model="data.amount" required/>
							<p ng-show="apartmentChargesForm.amount.$invalid && apartmentChargesForm.amount.$touched" class="help-block">
											Required
							</p>
						</div> 

					</div>
					<div class="modal-footer">   	
						<div class="form-group">
							<div class="row">
								<div class="col-sm-3">
									<my-save-button
										click-fn="saveRecord()"
										show-spinner="showSaveSpinner"												
										ng-disabled="apartmentChargesForm.$invalid"									
									></my-save-button>
									
								</div>
								<div class="col-sm-6">
									<my-form-alert				
										show-alert="showAlert"
										alert-type="alertDetails.type"
										alert-message="alertDetails.message"											
									></my-form-alert>											
								</div>
								<div class="col-sm-3">
									<button type="button" class="btn btn-lg btn-default btn-outline btn-block" 
										ng-click="cancel()">Cancel</button>										
								</div>	
							</div>    
						</div>
					</div>
                </form>`,
				animation: true,      
				resolve: {
					data: function () {
					  return data;
					},
					title: function(){
						return "Apartment Charges"
					},
					permissions: function(){
						return permissions;
					}
				},
				controller: function($scope, $uibModalInstance, data, title, permissions, ApartmentsService, UtilBroadcastService, $timeout) {
					
					$scope.title = title;
					$scope.data =  data;
					$scope.showSaveSpinner = false;			
					$scope.showAlert = false;
					$scope.alertDetails = {};
					$scope.permissions = permissions;	
				
					$scope.saveRecord = function () {	
						$scope.showSaveSpinner = true;
						
						var promise; var message;	
						if( $scope.data.id==='new' ){							
							promise = ApartmentsService.postApartmentChargeStore($scope.data);
							message = 'Record created';			
						} else {
							promise = ApartmentsService.postApartmentChargeUpdate($scope.data.id, $scope.data);
							message = 'Record updated';			
						}
						
						promise.then(function(success){						
							$scope.apartmentChargesForm.$setPristine();		
							UtilBroadcastService.showAlert($scope, 'Success', message, false);	
							if($scope.data.id==='new') $scope.data.id = success.data;
						}, function(error){
							console.log(error);
							UtilBroadcastService.showAlert($scope,'Failure', 'Unable to create/update record.');
						}).finally(function(){
							UtilBroadcastService.broadcastUpdate();							
							$timeout(function() {
								$uibModalInstance.close('close');
							}, 2000);						
						});							
					}

					$scope.cancel = function() {
						$uibModalInstance.dismiss();
					}
				}
            };
	}	
	
			
	return modalApartmentCharges;
}])