angular
    .module("credit-notes")

    .directive("creditNoteAllocations", function (CreditNotesService) {
        return {
            restrict: "EAC",
            replace: true,
            scope: {
                creditNoteId: "=",
                permissions: "=",
            },
            template: `<div>
							<div ng-show="!dataLoaded" class="row text-center">
								<span ><i class="fa fa-cog fa-spin" style="font-size:48px"></i></span>
							</div>
							<div ng-show="dataLoaded">
								<table class="table"  >
										<th>Invoice</th>										
										<th>Date</th>
										<th>Amount</th>	
										<th>Ref</th>										
									<tr ng-repeat="item in credit_note_allocations">        
										<td><a href="" ui-sref="main.invoice({ id: '{{item.invoice_id}}' })"  >{{item.invoice_number}}</a></td>
										<td>{{item.date_paid | date:shortDate}}</td>                       
										<td style="text-align: right">{{item.amount_paid}}</td>
										<td>{{item.ref}}</td>  										
                                    </tr>     
                                    <tr>
                                        <td>Total:</td>
                                        <td></td>
                                        <td style="text-align: right">{{ credit_note_allocation_total }}</td>
                                        <td></td>
                                    </tr>                       
								</table>
							</div>
						</div>`,
            link: function (scope, element, attr) {
                scope.dataLoaded = false;
                scope.credit_note_allocations = [];
                scope.credit_note_allocation_total = 0;

                function getCreditNoteAllocations() {
                    CreditNotesService.getInvoicePaymentByCreditNote(scope.creditNoteId)
                        .then(
                            function (success) {
                                scope.credit_note_allocations = success.data;
                                scope.credit_note_allocation_total = scope.credit_note_allocations
                                    .reduce((acc, x) => acc + parseFloat(x.amount_paid), 0)
                                    .toFixed(2);
                            },
                            function (error) {
                                console.log(error);
                            }
                        )
                        .finally(function () {
                            scope.dataLoaded = true;
                        });
                }

                getCreditNoteAllocations();

                scope.$on("updateSidebars", function (event, result) {
                    getCreditNoteAllocations();
                });

                scope.$watch("creditNoteId", function (newVal, oldVal) {
                    if (newVal != oldVal) {
                        getCreditNoteAllocations();
                    }
                });
            },
        };
    });
