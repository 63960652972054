angular.module('issues.modals', [])

.factory("ModalIssueAttachment", [function(){
     
    var modalIssueAttachment = {};          
    
    
    modalIssueAttachment.show = function(data, permissions, issue_types, issue_states){ 
        return {
            template: 
            `<form name="IssueAttachmentForm" novalidate>
                <div class="modal-header">
                    <h3 class="modal-title">Issue Attachment</h3>
                </div>
                <div class="modal-body default">    
                    <div class="form-group" ng-class="{'has-error': IssueAttachmentForm.title.$invalid && IssueAttachmentForm.title.$touched}">
                        <label>Title</label>
                        <input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="title"
                            ng-readonly="!permissions['issues.update']"
                            ng-model="data.title" required/>
                        <p ng-show="IssueAttachmentForm.title.$invalid && IssueAttachmentForm.title.$touched" class="help-block">
                                        Required
                        </p>
                    </div>          
                    

                    <div ng-show="data.id != 'new'" class="form-group" ng-class="{'has-error': IssueAttachmentForm.file.$invalid && IssueAttachmentForm.file.$touched}">
                        <label>Attachment</label>
                        <input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="attachment"
                            ng-readonly="true"
                            ng-model="data.attachment"/>                        
                    </div>  

                    <div class="form-group" ng-class="{'has-error': IssueAttachmentForm.file.$invalid && IssueAttachmentForm.file.$touched}">
                        
                        <label>Add Attachment</label>
                            <input type="file" ngf-select ng-model="file" name="file" ngf-pattern="'.docx,.doc,.pdf,.jpg,.png,.txt,.msg,.xls,.xlsx'"
                                ngf-accept="'.docx,.doc,.pdf,.jpg,.png,.txt,.msg,.xls,.xlsx'" ngf-max-size="4MB">
                            <p ng-show="IssueAttachmentForm.file.$invalid && IssueAttachmentForm.file.$touched" class="help-block">
                                Please ensure image size is no greater than 4MB
                            </p>  
                        
                    </div>  

                </div>
                <div class="modal-footer">      
                    <div class="form-group">
                        <div class="row">
                            <div class="col-sm-3">
                                <my-save-button
                                    click-fn="saveRecord()"
                                    show-spinner="showSaveSpinner"                                              
                                    ng-disabled="IssueAttachmentForm.$invalid"                                    
                                ></my-save-button>
                                
                            </div>
                            <div class="col-sm-6">
                                <my-form-alert              
                                    show-alert="showAlert"
                                    alert-type="alertDetails.type"
                                    alert-message="alertDetails.message"                                            
                                ></my-form-alert>                                           
                            </div>
                            <div class="col-sm-3">
                                <button type="button" class="btn btn-lg btn-default btn-outline btn-block" 
                                    ng-click="cancel()">Cancel</button>                                     
                            </div>  
                        </div>    
                    </div>
                </div>
            </form>`,
            animation: true,      
            resolve: {
                data: function () {
                  return data;
                },
                title: function(){
                    return "Issue Attachment"
                },
                permissions: function(){
                    return permissions;
                }
            },
            controller: function($scope, Upload, $uibModalInstance, data, title, permissions, UtilBroadcastService, $timeout) {
                
                $scope.title = title;
                $scope.data =  data;
                $scope.showSaveSpinner = false;         
                $scope.showAlert = false;
                $scope.alertDetails = {};
                $scope.permissions = permissions;   

                $scope.saveRecord = function () {   
                    $scope.showSaveSpinner = true;
                    var message, url;

                    if($scope.data.id != 'new'){
                        url = 'api/issue-attachments/update/'+$scope.data.id;
                        message = "Issue Attachment Updated."
                    } else {
                        url = 'api/issue-attachments/store';
                        message = "Issue Attachment Stored";
                    }

                                       
                    Upload.upload({
                        url: url,
                        data: {file: $scope.file, 
                                'issue_id': $scope.data.issue_id,
                                'title': $scope.data.title
                                }
                    }).then(function (resp) {
                        if($scope.file) console.log('Success ' + resp.config.data.file.name + 'uploaded. Response: ' + resp.data);
                        
                        self.sendSpinner = false;                   
                        
                        $scope.IssueAttachmentForm.$setPristine();    
                        UtilBroadcastService.showAlert($scope, 'Success', message, false);  
                        if($scope.data.id==='new') $scope.data.id = resp.data;                  
                        
                    }, function (resp) {
                        console.log('Error status: ' + resp.status);
                        UtilBroadcastService.showAlert($scope,'Failure', 'Unable to create/update record.');
                    }, function (evt) {
                        var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
    
                        if($scope.file)console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
                    }).finally(function(){
                        UtilBroadcastService.broadcastUpdate(); 
                        $timeout(function() {
                            $uibModalInstance.close('close');
                        }, 2000);                                       
                    });                 
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss();
                }

                $scope.$watch('file', function(newData, oldData) {
                    if((newData != oldData) && (newData != null)) $scope.data.attachment = $scope.file.name;
                });
            }
        };
}   

        
return modalIssueAttachment;
}])

