angular.module('xero')

.factory("XeroService", ['$http',
	function($http){
	 
	var xeroService = {};                  
	
	xeroService.getSyncPayments = function(config){ 
		if(config==null) config=[];		
		return $http.get('api/xero/sync-payments', config);
	}

	xeroService.getSyncInvoices = function(config){ 
		if(config==null) config=[];		
		return $http.get('api/xero/sync-invoices', config);
	}
				
	return xeroService;
}])