angular.module('issues.directives')

	.directive('issueAttachments', function ($rootScope, IssuesService, ModalFeedback, 
				ModalConfirmAction, $uibModal, $timeout, ModalIssueAttachment, AttachmentsService) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				issueId: '=',					
				permissions: '='
			},      
			template:	`<div>
							<div ng-show="!dataLoaded" class="row text-center">
								<span ><i class="fa fa-cog fa-spin" style="font-size:48px"></i></span>
							</div>
							<div ng-show="dataLoaded">
								<table class="table">									
									<tr>
										<th>Title</th>										
										<th>Attachment</th>
										<th colspan="2" style="width: 10%"><div class="text-center">Action</div></th>	
									</tr>
									<tr ng-repeat="item in issue_attachments">
										<td>{{item.title}}</td>										
										<td><a href="" ng-click="openAttachment(item)">{{item.attachment}}</a></td>
										<td><my-sub-form-button click-fn="showIssueAttachment(item)" button-type="edit"></my-sub-form-button></td>
										<td><my-sub-form-button ng-show="permissions['issues.update']" click-fn="deleteIssueAttachment(item)" button-type="delete"></my-sub-form-button></td>                                
									</tr>									
								</table>
								<div class="pull-right">
									<my-sub-form-button ng-show="permissions['issues.create']" 
									click-fn="createIssueAttachment()" button-type="add">
								</div>
							</div>
						
						</div>`,
			link: function (scope, element, attr) {
				
				const id = scope.issueId;
				var issueAttachmentData;
				scope.dataLoaded = false;
				scope.issue_attachments = [];									
					
				scope.dataLoaded = true;

				function getIssueAttachments(){					
					scope.dataLoaded = false;
					IssuesService.getIssueAttachmentByIssue(id).then(function(success){     
						scope.issue_attachments = success.data;    						
					}, function(error){
						console.log(error);
					}).finally(function(){
						scope.dataLoaded = true;
					});
				}
				
				getIssueAttachments();
				
				function broadcastUpdate(){
					console.log('broadcasting update');
					$rootScope.$broadcast('updateSidebars', {
						data: true
					});
				}
				
				scope.$on('updateSidebars', function (event, result) {					
					getIssueAttachments();
				});

				scope.openAttachment = function(item){

					var params = {};
					params.disk = 'issue-attachments';
					params.item_id = item.issue_id;
					params.file = item.attachment;
					
					AttachmentsService.getAttachment(params).then(function(url){
						var hiddenElement = document.createElement('a');
	
						hiddenElement.href = url;
						hiddenElement.target = '_blank';
						hiddenElement.download = item.attachment;
						document.body.appendChild(hiddenElement);
						hiddenElement.click();
						$timeout(function(){
							hiddenElement.remove();
						}, 50);
					});			
				
				}
				
				function showIssueAttachment(){
					var modalInstance = $uibModal.open(ModalIssueAttachment.show(issueAttachmentData, scope.permissions));					
					modalInstance.result.then(function (data) {
						getIssueAttachments();     
					});
				}
				
				scope.showIssueAttachment = function(item){
					issueAttachmentData = item;
					showIssueAttachment();					
				}
				
				scope.createIssueAttachment = function(){
					issueAttachmentData = {id: 'new', issue_id: id};
					showIssueAttachment();
				}
				
				scope.deleteIssueAttachment = function(item){
					var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
						'Warning!', 
						'Are you sure you would like to delete this record? Deletions cannot be undone.', 
						'alert-danger')
					);
					
					modalInstance.result.then(function (data) {						
						IssuesService.getIssueAttachmentDelete(item.id).then(function(success){                      
							var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Record deleted!', 'alert-success'));						
							$timeout(function() { modalInstance1.close('cancel'); }, 2000);
							broadcastUpdate();								
						}, function(error){
							console.log(error);
							var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
										'Unable to delete record. Please contact a system administrator.', 'alert-danger'));			
							$timeout(function() { modalInstance1.close('cancel'); }, 2000);
						});						      
					});
				}
			}
		};
	})