angular.module('dashboard.directives')

	.directive('dashboardOccupancyByDateContainer', function () {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{				
				title: '@'				
			},      
			template:	`<div>
                            <div class="row">
								<div class="col-lg-12">
									<div class="panel panel-success">
										<div class="panel-heading" >
											<h3 class="panel-title">{{title}}</h3>	
										</div>
										<div class="panel-body" >
											<div class="col-lg-3">
												<label>Start Date</label>
												<p class="input-group">
													<input ng-model="occupancy_dates.start_date" type="text" class="form-control" 
														uib-datepicker-popup="{{'dd-MMM-yyyy'}}" 
														datepicker-localdate
														is-open="occupancy_start_date_open" datepicker-options="{formatYear: 'yy', startingDay: 1}" 
														ng-required="true" close-text="Close" alt-input-formats="altInputFormats" 
														ng-change="getOccupancy30()"
														ng-focus="focus=false" ng-blur="focus=true" name="occupancy_start_date" required/>
														<span class="input-group-btn">
															<button type="button" tabindex="-1" class="btn btn-default" 
															ng-click="occupancy_start_date_open = !occupancy_start_date_open"><i class="glyphicon glyphicon-calendar"></i></button>
														</span>
												</p>
											</div>								
											<div class="col-lg-3">
												<label>End Date</label>
												<p class="input-group">										
													<input ng-model="occupancy_dates.end_date" type="text" class="form-control" 
														uib-datepicker-popup="{{'dd-MMM-yyyy'}}" 
														datepicker-localdate
														is-open="occupancy_end_date_open" datepicker-options="{formatYear: 'yy', startingDay: 1}" 
														ng-required="true" close-text="Close" alt-input-formats="altInputFormats" 
														ng-change="getOccupancy30()"
														ng-focus="focus=false" ng-blur="focus=true" name="occupancy_end_date" required/>
														<span class="input-group-btn">
															<button type="button" tabindex="-1" class="btn btn-default" ng-click="occupancy_end_date_open = !occupancy_end_date_open"><i class="glyphicon glyphicon-calendar"></i></button>
														</span>
												</p>	
											</div>
											<div class="col-lg-6"></div>
										</div>
									</div>
								</div>
							</div>
							
							<div class="row" ng-if="dataLoaded">
								<div class="col-lg-3">
									<dashboard-occupancy-by-date
										start-date="occupancy_dates.start_date" 
										end-date="occupancy_dates.end_date"
										chart-title="Date Range"										
									></dashboard-occupancy-by-date>
								</div>
								
								<div class="col-lg-3">
									<dashboard-occupancy-by-date
										start-date="occupancy90_start_date" 
										end-date="occupancy_end_date"
										chart-title="Last 3 Months"										
									></dashboard-occupancy-by-date>
								</div>
								
								<div class="col-lg-3">
									<dashboard-occupancy-by-date
										start-date="occupancy180_start_date" 
										end-date="occupancy_end_date"
										chart-title="Last 6 Months"										
									></dashboard-occupancy-by-date>
								</div>
								
								<div class="col-lg-3">
									<dashboard-occupancy-by-date
										start-date="occupancy365_start_date" 
										end-date="occupancy_end_date"
										chart-title="Last 12 Months"										
									></dashboard-occupancy-by-date>
								</div>
							</div>
                        </div>`,
			
			link: function (scope, element, attr) {	
				scope.dataLoaded = false;
				scope.occupancy_dates = {};
				
				function setOccupancyDates(){
					scope.occupancy_dates.start_date = moment().utc().startOf('day').format('YYYY-MM-DD');					
					scope.occupancy_dates.end_date = moment().add(3, 'M').startOf('day').format('YYYY-MM-DD');
					scope.occupancy_end_date = moment().startOf('day').format('YYYY-MM-DD');
					scope.occupancy90_start_date = moment().day(-90).startOf('day').format('YYYY-MM-DD');
					scope.occupancy180_start_date = moment().day(-180).startOf('day').format('YYYY-MM-DD');
					scope.occupancy365_start_date = moment().day(-365).startOf('day').format('YYYY-MM-DD');
					
					scope.dataLoaded = true;
				}
				
				setOccupancyDates();		
				
			}
		};
	})