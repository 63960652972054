angular.module('apartments.directives')

    .directive('apartmentAttachments', function ($rootScope, ApartmentsService, ModalFeedback, 
                ModalConfirmAction, $uibModal, $timeout, AttachmentsService, ModalConfigurationService) {
        return {
            restrict : 'EAC',
            replace : true,
            scope :{
                apartmentId: '=',                   
                permissions: '='
            },      
            template:   `<div>
                            <div ng-show="!dataLoaded" class="row text-center">
                                <span ><i class="fa fa-cog fa-spin" style="font-size:48px"></i></span>
                            </div>
                            <div ng-show="dataLoaded">
                                <table class="table">                                   
                                    <tr>
										<th>Title</th>
										<th>Type</th>                                      
                                        <th>Attachment</th>
                                        <th colspan="2" style="width: 10%"><div class="text-center">Action</div></th>   
                                    </tr>
                                    <tr ng-repeat="item in apartment_attachments">
										<td>{{item.title}}</td> 
										<td>{{item.type | underscoreless | capitalize}}</td>                                     
                                        <td><a href="" ng-click="openAttachment(item)">{{item.attachment}}</a></td>
                                        <td><my-sub-form-button click-fn="showApartmentAttachment(item)" button-type="edit"></my-sub-form-button></td>
                                        <td><my-sub-form-button ng-show="permissions['apartments.update']" click-fn="deleteApartmentAttachment(item)" button-type="delete"></my-sub-form-button></td>                                
                                    </tr>                                   
                                </table>
                                <div class="pull-right">
                                   <my-sub-form-button ng-show="permissions['apartments.create']" 
                                    click-fn="createApartmentAttachment()" button-type="add">
                                </div>
                            </div>
                        
                        </div>`,
            link: function (scope, element, attr) {
                
                const id = scope.apartmentId;
                var apartmentAttachmentData;
                scope.dataLoaded = false;
                scope.apartment_attachments = [];                                   
                    
                scope.dataLoaded = true;

                function getApartmentAttachments(){                 
                    scope.dataLoaded = false;
                    ApartmentsService.getApartmentAttachmentByApartment(id).then(function(success){     
                        scope.apartment_attachments = success.data;                         
                    }, function(error){
                        console.log(error);
                    }).finally(function(){
                        scope.dataLoaded = true;
                    });
                }
                
                getApartmentAttachments();
                
                function broadcastUpdate(){
                    $rootScope.$broadcast('updateSidebars', {
                        data: true
                    });
                }
                
                scope.$on('updateSidebars', function (event, result) {                  
                    getApartmentAttachments();
                });

                scope.openAttachment = function(item){

                    var params = {};
                    params.disk = 'apartment-attachments';
                    params.item_id = item.apartment_id;
                    params.file = item.attachment;
                    
                    AttachmentsService.getAttachment(params).then(function(url){
                        var hiddenElement = document.createElement('a');
    
                        hiddenElement.href = url;
                        hiddenElement.target = '_blank';
                        hiddenElement.download = item.attachment;
                        document.body.appendChild(hiddenElement);
                        hiddenElement.click();
                        $timeout(function(){
                            hiddenElement.remove();
                        }, 50);
                    });         
                
                }
                
                function showApartmentAttachment(){
                    var modalInstance = ModalConfigurationService.getApartmentAttachment(apartmentAttachmentData, scope.permissions);                  
                    modalInstance.result.then(function (data) {
                        getApartmentAttachments();     
                    });
                }
                
                scope.showApartmentAttachment = function(item){
                    apartmentAttachmentData = item;
                    showApartmentAttachment();                  
                }
                
                scope.createApartmentAttachment = function(){
                    apartmentAttachmentData = {id: 'new', apartment_id: id};
                    showApartmentAttachment();
                }
                
                scope.deleteApartmentAttachment = function(item){
                    var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
                        'Warning!', 
                        'Are you sure you would like to delete this record? Deletions cannot be undone.', 
                        'alert-danger')
                    );
                    
                    modalInstance.result.then(function (data) {                     
                        ApartmentsService.getApartmentAttachmentDelete(item.id).then(function(success){                      
                            var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Record deleted!', 'alert-success'));                     
                            $timeout(function() { modalInstance1.close('cancel'); }, 2000);
                            broadcastUpdate();                              
                        }, function(error){
                            console.log(error);
                            var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
                                        'Unable to delete record. Please contact a system administrator.', 'alert-danger'));            
                            $timeout(function() { modalInstance1.close('cancel'); }, 2000);
                        });                           
                    });
                }
            }
        };
    })

