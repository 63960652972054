angular
    .module("credit-notes")

    .controller("CreditNoteController", [
        "$scope",
        "$rootScope",
        "UtilBroadcastService",
        "CreditNotesService",
        "InvoicesService",
        "$stateParams",
        "$uibModal",
        "$state",
        "ModalGetEmailAddress",
        "ModalConfirmAction",
        "ModalFeedback",
        "PermissionsService",
        "$timeout",
        "$window",
        "SettingsService",
        function (
            $scope,
            $rootScope,
            UtilBroadcastService,
            CreditNotesService,
            InvoicesService,
            $stateParams,
            $uibModal,
            $state,
            ModalGetEmailAddress,
            ModalConfirmAction,
            ModalFeedback,
            PermissionsService,
            $timeout,
            $window,
            SettingsService
        ) {
            var self = this;
            var config = { params: {} };

            var id = $stateParams.id;

            self.booking_id = $stateParams.booking_id;
            self.dataLoaded = false;
            self.showSaveSpinner = false;
            self.showEmailSpinner = false;
            self.showSendToXeroSpinner = false;
            self.showCommissionSpinner = false;
            self.showCommissionButton = false;
            self.showAlert = false;
            self.alertDetails = {};
            self.title;
            self.credit_note = { creditnotedetails: [] };
            self.credit_notes = [];
            self.show_credit_note_details_alert = false;
            self.related_invoice = {};
            self.invoicemode = true;
            self.isNew = id == "add-new";
            self.permissions = [];
            self.report_data = { data: "no" };
            self.credit_note_states = CreditNotesService.getStatusForCreditNotes();
            self.credit_note_types = CreditNotesService.getTypesForCreditNotes();

            self.invoiceSentToXero = true;
            self.xero_payments = 0;
            self.sams_payments = 0;
            self.paymentsReconciled = true;

            var config;

            self.params = $stateParams;

            PermissionsService.getByModuleByUser("credit-notes|invoices|bookings|channel-manager|accountancy").then(
                function (success) {
                    self.permissions = success.data;
                    init();
                },
                function (error) {
                    console.log(error);
                }
            );

            function init() {
                if (!self.isNew) {
                    getCreditNote();
                } else {
                    self.credit_note.invoice_date = moment().format("YYYY-MM-DD");
                    self.credit_note.raise_room_rate = 0;
                    self.credit_note.credit_note = 0;
                    self.credit_note.status = "new";
                    getVatRate();
                    self.dataLoaded = true;
                }
            }

            function getCreditNote() {
                CreditNotesService.getCreditNote(id).then(
                    function (success) {
                        self.title = success.data.credit_note_number;
                        self.credit_note = success.data;
                        if (self.credit_note.creditnotedetails.length === 0 && !self.isNew) {
                            var msg = {};
                            msg.title = "No Credit Note Details";
                            msg.description = "Warning: Please add credit note details to this credit note.";
                            modalMissingInfoAlert(msg);
                        }
                        self.dataLoaded = true;
                        broadcastUpdate();
                    },
                    function (error) {
                        console.log(error);
                    }
                );
            }

            function getVatRate() {
                config = {};
                config.setting = "vat_rate_1";
                SettingsService.postBySetting(config).then(
                    function (success) {
                        self.credit_note.vat_rate = success.data.value;
                    },
                    function (error) {
                        console.log(error);
                    }
                );
            }

            function getInvoices() {
                config.params = {};
                config.params.dropdown = true;
                config.params.orderby = "invoices.invoice_number";
                config.params.sort = "DESC";
                InvoicesService.getInvoices(config).then(
                    function (success) {
                        self.invoices = success.data;
                    },
                    function (error) {
                        console.log(error);
                    }
                );
            }

            getInvoices();

            $scope.$watch(
                angular.bind(this, function () {
                    return self.credit_note.booking_id;
                }),
                function (oldVal, newVal) {
                    if (oldVal != newVal && self.credit_note.booking_id != null) getBooking();
                }
            );

            if (id == "add-new" && self.booking_id != null) {
                self.credit_note.booking_id = self.booking_id;
            }

            function validateDates() {
                if (self.credit_note.start_date == false || self.credit_note.end_date == false) return;

                if (self.credit_note.start_date > self.credit_note.end_date) {
                    $scope.creditNoteForm.end_date.$setValidity("endDateBeforeStartDate", false);
                } else {
                    $scope.creditNoteForm.end_date.$setValidity("endDateBeforeStartDate", true);
                }
            }

            /**************************************/
            /*			Modal Missing Info        */
            /**************************************/

            function modalMissingInfoAlert(msg) {
                var modalInstance = $uibModal.open(ModalFeedback.showFeedback(msg.title, msg.description, "alert-default", false));
            }

            self.addCalculateEndDate = function (days) {
                var date = moment.utc(self.credit_note.start_date).startOf("day");
                self.credit_note.end_date = date.add(days, "days");
            };

            /**************************************/
            /*			Send Emails               */
            /**************************************/

            self.sendEmail = function () {
                var modalInstance = $uibModal.open(ModalGetEmailAddress.show(self.related_invoice.booking_id, self.permissions, "Email Invoice"));

                modalInstance.result.then(function (email_address) {
                    var mailConfig = { params: {} };
                    mailConfig.params.email_address = email_address;
                    self.showEmailSpinner = true;
                    CreditNotesService.getEmailCreditNote(id, mailConfig)
                        .then(
                            function (success) {
                                var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback("Success", "Email Sent!", "alert-success"));
                                $timeout(function () {
                                    modalInstance1.close("cancel");
                                }, 2000);
                            },
                            function (error) {
                                console.log(error);
                                var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback("Error!", "Unable to send email.", "alert-danger"));
                                $timeout(function () {
                                    modalInstance1.close("cancel");
                                }, 2000);
                            }
                        )
                        .finally(function () {
                            self.showEmailSpinner = false;
                        });
                });
            };

            /**************************************/
            /*		  	Save Record              */
            /**************************************/

            self.saveRecord = function () {
                self.showSaveSpinner = true;
                var promise;
                var message;
                if (self.isNew) {
                    self.showSaveSpinner = true;
                    promise = CreditNotesService.postStore(self.credit_note);
                    message = "Record created";
                } else {
                    promise = CreditNotesService.postUpdate(id, self.credit_note);
                    message = "Record updated";
                }

                promise
                    .then(
                        function (success) {
                            var newId = false;
                            if (self.isNew) newId = success.data;
                            $scope.creditNoteForm.$setPristine();
                            UtilBroadcastService.showAlert(self, "Success", message, newId, "main.credit-note");
                        },
                        function (error) {
                            const errors = error.data.errors;
                            const key = Object.keys(errors);

                            if(typeof(errors) === 'object')
                            {
                                UtilBroadcastService.showAlert(self, "Failure", errors[key][0]);
                            }
                            else 
                            {
                                UtilBroadcastService.showAlert(self, "Failure", "Unable to create/update record.");
                            }                           

                            broadcastUpdate();
                        }
                    )
                    .finally(function () {
                        if (!self.isNew) getCreditNote();
                        UtilBroadcastService.broadcastUpdate();
                    });
            };

            function broadcastUpdate() {
                $rootScope.$broadcast("updateSidebars", {
                    data: true,
                });
            }

            self.deleteRecord = function () {
                var modalInstance = $uibModal.open(
                    ModalConfirmAction.confirmAction(
                        "Warning!",
                        "Are you sure you would like to delete this record? Deletions cannot be undone.",
                        "alert-danger"
                    )
                );

                modalInstance.result.then(function (msg) {
                    CreditNotesService.getDelete(id).then(
                        function (success) {
                            var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback("Success", "Record deleted!", "alert-success"));
                            $timeout(function () {
                                modalInstance1.close("cancel");
                            }, 2000);
                            $state.go("main.invoices", {}, { reload: true });
                        },
                        function (error) {
                            console.log(error);
                            var modalInstance1 = $uibModal.open(
                                ModalFeedback.showFeedback(
                                    "Error!",
                                    "Unable to delete record. Please contact a system administrator.",
                                    "alert-danger"
                                )
                            );
                            $timeout(function () {
                                modalInstance1.close("cancel");
                            }, 2000);
                        }
                    );
                });
            };

            self.print = function (type) {
                var config = { params: { type: type, credit_note: self.credit_note.credit_note } };
                self.showSpinner = 1;

                CreditNotesService.getStandardCreditNoteReport(id, config)
                    .then(
                        function (url) {
                            var printwWindow = $window.open(url);
                        },
                        function (error) {
                            console.log(error);
                        }
                    )
                    .finally(function () {
                        self.showSpinner = 0;
                    });
            };

            self.printWord = function (type) {
                var params = {};
                params.type = type;
                var data = {};
                data.type = type;
                data.credit_note = self.credit_note.credit_note;
                self.showSpinner = 1;

                CreditNotesService.getStandardInvoiceReportForWord(id, data, params)
                    .then(
                        function (url) {
                            var hiddenElement = document.createElement("a");
                            hiddenElement.href = url;
                            hiddenElement.target = "_blank";
                            hiddenElement.download = self.credit_note.credit_note_number + ".docx";
                            document.body.appendChild(hiddenElement);
                            hiddenElement.click();
                            $timeout(function () {
                                hiddenElement.remove();
                            }, 50);
                        },
                        function (error) {
                            console.log(error);
                        }
                    )
                    .finally(function () {
                        self.showSpinner = 0;
                    });
            };

            $scope.$on("updateCreditNote", function (event, result) {
                getCreditNote();
            });

            $scope.$watch(
                function () {
                    return self.credit_note.end_date;
                },
                function (newValue, oldValue) {
                    if (newValue != oldValue && angular.isDefined(newValue)) {
                        validateDates();
                    }
                }
            );

            $scope.$watch(
                function () {
                    return self.credit_note.start_date;
                },
                function (newValue, oldValue) {
                    if (newValue != oldValue && angular.isDefined(newValue)) {
                        validateDates();
                    }
                }
            );

            self.updateCreditNoteNumber = function (item) {
                console.log(item);
            };

            $scope.$watch(
                function () {
                    return self.credit_note.related_invoice_id;
                },
                function (newValue, oldValue) {
                    if (self.isNew && newValue != oldValue && angular.isDefined(newValue)) {
                        const invoice = self.invoices.find((x) => parseInt(x.id) === parseInt(newValue));
                        self.credit_note.credit_note_number = `CN-${invoice.invoice_number}`;
                    }
                }
            );
        },
    ]);
