angular.module('bookings.controllers')

.controller('BookingConfirmationController', ['$scope', 'Upload', 'BookingsService', 
						'$stateParams', '$state', '$timeout',
	function($scope, Upload, BookingsService, $stateParams, $state, $timeout) {
		
	var self = this;
	var id = $stateParams.id;		
	self.sendSpinner = false;
	self.dataLoaded = false;
	self.showAlert = false;
	self.alertDetails = {};
	self.data = {};
	
	function getBookingConfirmationData(){
		BookingsService.getBookingConfirmationData(id).then(function(success){
			self.data = success.data;				
			self.dataLoaded = true;
		}, function(error){
			console.log(error);
		});			
	}
	
	getBookingConfirmationData();
	
	function showAlert(type, message){
		self.sendSpinner = false;
		self.showAlert = true;
		self.alertDetails.type = type;
		self.alertDetails.message = message;

		$timeout(function() {
			self.showAlert = false;				
		}, 2000);			
		
	}             		
			
	self.send = function(){		
		if ($scope.bookingConfirmationForm.file.$valid && $scope.file) {
			$scope.upload($scope.file);
		}	
	}
				
	
	$scope.upload = function (file) {		
		self.sendSpinner = true;
		Upload.upload({
			url: 'api/booking/send-booking-confirmation-with-attachment',
			data: {file: file, 
					'email': self.data.email,
					'bcc_address': self.data.bcc,
					'from_address': self.data.from_address,
					'from_label': self.data.from_label,
					'subject': self.data.subject,
					'body': self.data.body
					}
		}).then(function (resp) {
			console.log('Success ' + resp.config.data.file.name + 'uploaded. Response: ' + resp.data);
			self.sendSpinner = false;
			
			showAlert('Success', 'Email Sent');		
			$scope.bookingConfirmationForm.$setPristine();						
			
		}, function (resp) {
			console.log('Error status: ' + resp.status);
			showAlert('Failure', 'Unable to send email');		
		}, function (evt) {
			var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
			console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
		});
	};	
	
	self.goToBooking = function(){
		$state.go('main.booking', {'id': id}, {'reload': true});
	}

}])