angular.module('schedulers.modals')

.factory("ModalSchedulerConvertEnquiryToBooking", [function(){
	 
	var modalSchedulerConvertEnquiryToBooking = {};          
	
	
	modalSchedulerConvertEnquiryToBooking.convert = function(data, permissions){ 
		return {
			animation: true,     
			template: 
			`<div class="modal-header">
				<h3 class="modal-title">{{title}}</h3>
			</div>
			<div class="modal-body alert">           
				<form name="enquiryBookingForm">
				
					<div class="row">					
						<div class="col-sm-6">
							<div class="form-group" ng-class="{'has-error': enquiryBookingForm.booking_date.$invalid && enquiryBookingForm.booking_date.$touched}">       
								<label>Booking Date</label>
								<p class="input-group">
									<input ng-model="data.booking.booking_date" type="text" class="form-control" uib-datepicker-popup="{{'dd-MMM-yyyy'}}" 
										    datepicker-localdate
										   is-open="booking_date_open" datepicker-options="{formatYear: 'yy', startingDay: 1}" 
										   ng-required="true" close-text="Close" alt-input-formats="altInputFormats" 
										   ng-focus="focus=false" ng-blur="focus=true" name="check_in_date"                                 
										   required/>
									<span class="input-group-btn">
										<button tabindex="-1" type="button" class="btn btn-default" ng-click="booking_date_open = !booking_date_open"><i class="glyphicon glyphicon-calendar"></i></button>
									</span>
								</p>
								<p ng-show="enquiryBookingForm.booking_date.$invalid && enquiryBookingForm.booking_date.$touched" class="help-block"> 
												Required
								</p>
							</div>
						</div>
						<div class="col-sm-6">
							<apartment-dropdown
								ng-model="data.booking_detail.apartment_id" 
								form-name="enquiryBookingForm" 
								is-new="true"
								available-by-dates="true"
								available-start-date="data.dates.check_in_date"
								available-end-date="data.dates.check_out_date"
								title="Apartment">
							</apartment-dropdown>
						</div>		
					</div>	
						
					<div class="row">					
						<div class="col-sm-6">
							<div class="form-group" ng-class="{'has-error': enquiryBookingForm.enquiry_date.$invalid && enquiryBookingForm.enquiry_date.$touched}">       
								<label>Start Date</label>
								<p class="input-group">
									<input ng-model="data.dates.check_in_date" type="text" class="form-control" uib-datepicker-popup="{{'dd-MMM-yyyy'}}" 
										   ng-change="datesChanged()"
										   datepicker-localdate
										   is-open="check_in_date_open" datepicker-options="{formatYear: 'yy', startingDay: 1}" 
										   ng-required="true" close-text="Close" alt-input-formats="altInputFormats" 
										   ng-focus="focus=false" ng-blur="focus=true" name="check_in_date"                                 
										   required/>
									<span class="input-group-btn">
										<button tabindex="-1" type="button" class="btn btn-default" ng-click="check_in_date_open = !check_in_date_open"><i class="glyphicon glyphicon-calendar"></i></button>
									</span>
								</p>
								<p ng-show="enquiryBookingForm.check_in_date.$invalid && enquiryBookingForm.check_in_date.$touched" class="help-block"> 
												Required
								</p>
							</div>
						</div>
						
						<div class="col-sm-6">
							<div class="form-group" ng-class="{'has-error': enquiryBookingForm.check_out_date.$invalid && enquiryBookingForm.check_out_date.$touched}">       
								<label>End Date</label>
								<p class="input-group">
									<input ng-model="data.dates.check_out_date" type="text" class="form-control" uib-datepicker-popup="{{'dd-MMM-yyyy'}}" 
										   ng-change="datesChanged()"
										   datepicker-localdate
										   is-open="check_out_date_open" datepicker-options="{formatYear: 'yy', startingDay: 1}" 
										   ng-required="true" close-text="Close" alt-input-formats="altInputFormats" 
										   ng-focus="focus=false" ng-blur="focus=true" name="check_out_date"                                 
										   required/>
									<span class="input-group-btn">
										<button tabindex="-1" type="button" class="btn btn-default" ng-click="check_out_date_open = !check_out_date_open"><i class="glyphicon glyphicon-calendar"></i></button>
									</span>
								</p>
								<p ng-show="enquiryBookingForm.check_out_date.$invalid && enquiryBookingForm.check_out_date.$touched" class="help-block"> 
												Required
								</p>
							</div>
						</div>						
					</div>	
					
					<div class="row" >
						<div class="col-md-6">
							<organisation-dropdown
								ng-model="data.booking.organisation_id" 
								form-name="enquiryBookingForm" 
								is-new="true"
								show-add-button="true"
								is-disabled="false"
								title="Client">
							</organisation-dropdown>
						</div>	
						<div class="col-md-6">	
							<contact-dropdown 
								ng-model="data.booking.contact_id" 
								form-name="enquiryBookingForm" 
								is-new="true"
								show-add-button="true"
								is-disabled="false"
								title="Guest">
							</contact-dropdown>
						</div>	
					</div>
									
					<div class="row">			
						<div class="col-sm-4">
							<div class="form-group" ng-class="{'has-error': enquiryBookingForm.commission.$invalid && enquiryBookingForm.commission.$touched}">       
								<label>Commission</label>
								<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="commission"
									ng-model="data.booking.commission" required/>  
								<p ng-show="enquiryBookingForm.commission.$invalid && enquiryBookingForm.commission.$touched" class="help-block">
												Required
								</p>
							</div>
						</div>  		
						<div class="col-sm-4">
							<div class="form-group" >       
								<label>Status</label>
								<p>{{data.booking.status | capitalize}}</p>
							</div>
						</div>
						
						<div class="col-sm-4">
							<div class="form-group" >       
								<label>Type</label>
								<p>{{data.booking.type | capitalize}}</p>
							</div>
						</div>						
					</div>

					<div class="row" ng-show="overlapping_bookings.length>0">
						<div class="col-md-12">
							<h3>Cannot save due to overlapping bookings</h3>
							<table class="table alert-danger">
								<thead>
									<tr>
									  <th>ID</th>
									  <th>Apartment</th>
									  <th>Check-in</th>
									  <th>Check-out</th>
									</tr>
								</thead>
								<tbody>
									<tr ng-repeat="item in overlapping_bookings">
									  <td>{{item.booking_id}}</td>
									  <td>{{item.address1}}</td>
									  <td>{{item.check_in_date}}</td>
									  <td>{{item.check_out_date}}</td>
									</tr>                     
								</tbody>
							</table>
						</div>
					</div>				
				</form>
			</div>
			
			<div class="modal-footer" >    
				<div class="row">					
					<div class="col-sm-3">
								<my-save-button
									click-fn="saveRecord()"
									show-spinner="showSaveSpinner"												
									ng-disabled="enquiryBookingForm.$invalid || overlapping_bookings.length>0"
									ng-show="permissions['bookings.create']"
								></my-save-button>
								
							</div>
							<div class="col-sm-6">
								<my-form-alert				
									show-alert="showAlert"
									alert-type="alertDetails.type"
									alert-message="alertDetails.message"											
								></my-form-alert>											
							</div>				 
					<div class="col-sm-3">
						<button class="btn btn-warning btn-block" type="button" ng-click="cancel()">Cancel</button>
					</div>
				</div>
			</div>`,	
			
			resolve: {					
				title: function(){
					return "Convert Enquiry To Booking"
				},
				data: function(){
					return data;
				},				
				permissions: function(){
					return permissions;
				}
			},
			controller: function ($uibModalInstance, $scope, data, title, permissions, BookingsService,
							UtilBroadcastService, $timeout, EnquiryService, ModalSchedulerAddEnquiryDetail, $uibModal, $state) {	
				
				var config = {};
				$scope.title = title;
				$scope.permissions = permissions;
				$scope.data = data;				
				$scope.alertDetails = {};
				$scope.showSaveSpinner = false;
				$scope.showAlert = false;	
				$scope.overlapping_bookings = [];
							
				function getOverlappingBookings(){
					$scope.data.booking_detail.check_in_date = $scope.data.dates.check_in_date;
					$scope.data.booking_detail.check_out_date = $scope.data.dates.check_out_date;
					BookingsService.postValidateDates(-1, $scope.data.booking_detail).then(function(success){
						$scope.overlapping_bookings = success.data;
					}, function(error){
						console.log(error);
					});
				}
				
				getOverlappingBookings();
				
				$scope.saveRecord = function(){
					
					var promise; var message; var booking_id;
					$scope.showSaveSpinner = true; 

					$scope.data.booking.check_in_date = $scope.data.booking_detail.check_in_date =  $scope.data.dates.check_in_date;
					$scope.data.booking.check_out_date = $scope.data.booking_detail.check_out_date = $scope.data.dates.check_out_date;						  
					
					BookingsService.postStore($scope.data.booking).then(function(success){
						booking_id = success.data;
						$scope.data.booking_detail.booking_id = booking_id;
						return BookingsService.postBookingDetailStore($scope.data.booking_detail);							   
					}).then(function(success1){
						$scope.data.enquiry.booking_id = booking_id;
						return EnquiryService.postUpdate( $scope.data.enquiry.id, $scope.data.enquiry );						
					}).then(function(success2){
						$scope.enquiryBookingForm.$setPristine();		
						UtilBroadcastService.showAlert($scope, 'Success', 'Record created', false, 'main.booking');	
					}, function(error){
						console.log(error);
						UtilBroadcastService.showAlert($scope,'Failure', 'Unable to create/update record.');
					}).finally(function(){						
						UtilBroadcastService.broadcastUpdate();
						$timeout(function(){
							$state.go('main.booking', {'id': booking_id}, {reload: true});	
							$uibModalInstance.close('converted');
						}, 2000);						
					});		
				}
				
				function getCommission(){		
					BookingsService.getAccountingInformation(data.booking.organisation_id).then(function(success){     	
						data.booking.commission = success.data.commission;		
					}, function(error){
						console.log(error);
					});				
				}

				$scope.$watch('data.booking.organisation_id', function(newVal, oldVal){	
					if( angular.isDefined(newVal))	getCommission();				
				}, true);
							
				$scope.$watch('data.booking_detail.apartment_id', function(newVal, oldVal){					
					if(newVal != oldVal) getOverlappingBookings();
				}, true);
				
				$scope.$watch('data.dates.check_in_date', function(newVal, oldVal){					
					if(newVal != oldVal) getOverlappingBookings();
				}, true);
				
				$scope.$watch('data.dates.check_out_date', function(newVal, oldVal){					
					if(newVal != oldVal) getOverlappingBookings();
				}, true);
				
				$scope.cancel = function() {
				   $uibModalInstance.dismiss();
				}	
			}	
		}
	}		
	return modalSchedulerConvertEnquiryToBooking;
}])
