angular.module('apartments.controllers')

.controller('ApartmentReportsController', ['ApartmentsService',
	function(ApartmentsService) {
		var self = this;      

		self.report_data = {'data': 'no'};
		self.apartments = [];			
		
		function getApartments(){
			ApartmentsService.getApartments().then(function(success){
				self.apartments = success.data;
			}, function(error){
				console.log(error);
			});	
		}

		getApartments();		
		
		self.printApartmentDetail = function(id){
			ApartmentsService.getApartmentDetailsReport(id).then(function(success) {   
				self.report_data= {
					"template": { "shortid" : "NyfOL7E7-" },
					"title": "Apartment Details",
					"data": success.data
				};                
			});
		}		
		
	}
])