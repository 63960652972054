angular.module('invoice-templates', [])

.factory("InvoiceTemplatesService", ['$http', 
	function($http){
	 
	var invoiceTemplatesService = {};

	invoiceTemplatesService.getIndex = function(config){
		return $http.get('api/invoice-template', config == null ? [] : config);
	}
	
	invoiceTemplatesService.getShow = function(id) {            
		return $http.get('api/invoice-template/show/'+id);
	}
		   
	invoiceTemplatesService.postUpdate = function(id, invoice){            
		return $http.post('api/invoice-template/update/'+id, invoice);
	}
		
	invoiceTemplatesService.postStore = function(invoice){            
		return $http.post('api/invoice-template/store', invoice);
	}  
			
	invoiceTemplatesService.getDelete = function(id){            
		return $http.get('api/invoice-template/destroy/'+id);
	} 

	return invoiceTemplatesService;
}])