angular.module('contacts.directives', [])

	.directive('contactWarnings', function ($rootScope, ContactsService, $uibModal, 
					ModalContactWarning, ModalConfirmAction, ModalFeedback, $timeout) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				contactId: '=',					
				permissions: '='
			},      
			template:	`<div>
							<div ng-show="!dataLoaded" class="row text-center">
								<span ><i class="fa fa-cog fa-spin" style="font-size:48px"></i></span>
							</div>
							<div ng-show="dataLoaded">
								<table class="table"  >
									<tr>
										<th>Warning</th>
										<th>Date</th>
										<th>User</th>
										<th>Status</th>
										<th style="width: 10%"></th>
										<th style="width: 10%"></th>
									</tr>
									<tr ng-repeat="item in contactWarnings">
										<td>{{item.warning}}</td>
										<td>{{item.created_at}}</td>
										<td>{{item.user}}</td>
										<td><span class="badge">{{item.status | capitalize}}</span></td>
										<td><my-sub-form-button click-fn="showContactWarning(item)" button-type="edit"></my-sub-form-button></td>
										<td><my-sub-form-button ng-show="permissions['contacts.delete']" 
												click-fn="deleteContactWarning(item)" button-type="delete"></my-sub-form-button></td>                                
																	   
									</tr>                           
								</table>
								<div class="pull-right">
									<my-sub-form-button ng-show="permissions['contacts.create']" 
									click-fn="createContactWarning()" button-type="add"></my-sub-form-button>
								</div>
							</div>
						</div>`,
			link: function (scope, element, attr) {
				
				const id = scope.contactId;				
				var config = {	params: {}	};
				var contactWarningData;
				scope.dataLoaded = false;
				scope.contactWarnings = [];	
				
				function getContactWarnings(){					
					config.params.findby = 'contact_id';
					config.params.findbyvalue = id;					
					ContactsService.getContactWarnings(config).then(function(success){     
						scope.contactWarnings = success.data;  				
					}, function(error){
						console.log(error);
					}).finally(function(){
						scope.dataLoaded = true;
					});
				}
				
				getContactWarnings();				
				
				function broadcastUpdate(){
					$rootScope.$broadcast('updateSidebars', {
						data: true
					});
				}
				
				scope.$on('updateSidebars', function (event, result) {					
					getContactWarnings();	
				});
				
				function showContactWarning(){
					var modalInstance = $uibModal.open(ModalContactWarning.show(contactWarningData, scope.permissions));
					
					modalInstance.result.then(function (data) {
						getContactWarnings();     
					});
				}
				
				scope.showContactWarning = function(item){
					contactWarningData = item;
					showContactWarning();
					
				}
				
				scope.createContactWarning = function(){
					contactWarningData = {id: 'new', contact_id: id, status: 'active'};
					showContactWarning();
				}				
				
				scope.deleteContactWarning = function(item){
					var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
						'Warning!', 
						'Are you sure you would like to delete this record? Deletions cannot be undone.', 
						'alert-danger')
					);
					
					
					modalInstance.result.then(function (data) {                  
						ContactsService.getContactWarningsDelete(item.id).then(function(success){                      
							var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Record deleted!', 'alert-success'));						
							$timeout(function() { modalInstance1.close('cancel'); }, 2000);
							broadcastUpdate();	
						}, function(error){
							console.log(error);
							var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
										'Unable to delete record. Please contact a system administrator.', 'alert-danger'));			
							$timeout(function() { modalInstance1.close('cancel'); }, 2000);
						});
						
					});
				}				
			}
		};
	})