angular.module('users.services', [])

.factory("UsersService", ['$http', '$rootScope', '$auth',
	function($http, $rootScope, $auth){
	 
	var usersService = {};                  
	
	usersService.getUsers = function(config){
		return $http.get('api/user', config == null ? [] : config);
	}
	
	usersService.getUsersPaginate = function(config){
		return $http.get('api/user/paginate', config == null ? [] : config);
	}

	usersService.getRoles = function(){            
		return $http.get('api/role');
	}
	
	usersService.getUser = function(id){            
		return $http.get('api/user/show/'+id);
	}
	
	usersService.postUpdate = function(id, user){            
		return $http.post('api/user/update/'+id, user);
	}
	
	usersService.postStore = function(user){            
		return $http.post('api/user/store', user);
	}     
	
	usersService.getDelete = function(id){            
		return $http.get('api/user/destroy/'+id);
	}
	
	usersService.getPasswordNew = function(id){            
		return $http.get('api/user/password-new/'+id);
	}
	
	usersService.getProfile = function(){            
		return $http.get('api/user/profile');
	}
	
	usersService.postProfileUpdate = function(user){    		
		return $http.post('api/user/profile-update', user);
	}

	usersService.getStatusForUsers = function(){            
		return ['active', 'inactive'];
	}

	usersService.postLogMeInAs = function(id){
		
		const newUser = {id: id};
		return $http.post('api/user/log-me-in-as', newUser)
		.then(function (success) {
			$auth.setToken(success.data.token);
			return $http.get('api/authenticate/user');
		}).then(function (response) {		
			var user = JSON.stringify(response.data);
			localStorage.setItem('user', user);
			$rootScope.authenticated = true;
			$rootScope.currentUser = response.data;	
		}, function (error) {
			console.log(error.data.error);
		});
	}
				
	return usersService;
}])