angular.module('channel-manager', [])

.factory("ChannelManagerService", ['$http',
	function($http){
	 
	var channelManagerService = {};          
	
	channelManagerService.getDevelopments = function(){ 
		return $http.get('api/channel-manager/developments');
	}

	channelManagerService.getDevelopmentsPrefix = function(){ 
		return $http.get('api/channel-manager/developments-prefix');
	}

	channelManagerService.postDevelopment = function(data){ 
		return $http.post('api/channel-manager/development', data);
	}

	channelManagerService.postDevelopmentDelete = function(data){ 
		return $http.post('api/channel-manager/development-delete', data);
	}
	
	channelManagerService.getRoomTypesByDevelopment = function(development_id){ 
		return $http.get('api/channel-manager/apartments/'+development_id);
	}

	channelManagerService.getRatePlansByDevelopment = function(development_id){ 
		return $http.get('api/channel-manager/rate-plans/'+development_id);
	}

	channelManagerService.getReservationsPaginate = function(config){ 
		if(config==null) config=[];		
		return $http.get('api/channel-manager/reservations-paginate', config);
	}

	channelManagerService.getReservationsShow = function(id){ 
		return $http.get('api/channel-manager/reservations-show/'+id);
	}

	channelManagerService.postApartment = function(data){ 
		return $http.post('api/channel-manager/apartment', data);
	}

	channelManagerService.postApartmentsByDevelopment = function(development_id){ 
		return $http.post('api/channel-manager/apartments-by-development/'+development_id);
	}

	channelManagerService.postRatePlan = function(data){ 
		return $http.post('api/channel-manager/rate-plan', data);
	}

	channelManagerService.postAvailability = function(data){ 
		return $http.post('api/channel-manager/availability', data);
	}  

	channelManagerService.postAvailabilityList = function(data){ 
		return $http.post('api/channel-manager/availability-list', data);
	}  

	channelManagerService.postReservationList = function(data){ 
		return $http.post('api/channel-manager/reservation-list', data);
	}  

	channelManagerService.postCreateBooking = function(data){ 
		return $http.post('api/channel-manager/create-booking', data);
	}  

	channelManagerService.getAssignReservation = function(id){ 
		return $http.get('api/channel-manager/assign-reservation/'+id);
	}  
			
	return channelManagerService;
}])