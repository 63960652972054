angular.module('modals.services')

.factory("ModalFeedback", [function(){
	 
	var modalFeedback = {};          
	
	
	modalFeedback.showFeedback = function(title, message, superClass, showAnimation){ 
		return {
			template: 
				`<div class="modal-header">
					<h3 class="modal-title">{{title}}</h3>
				</div>
				<div class="modal-body alert" ng-class="superClass">       
					<span>{{message}}</span>
				</div>
				<div class="modal-footer">
					<div class="row">					
						<div class="col-lg-9"></div>
						<div class="col-lg-3"><button id="btnModalFeedback" class="btn btn-warning btn-block btn-outline" 
							type="button" ng-click="cancel()">Close</button></div>
					</div>				
				</div>`,
			animation: showAnimation,      
			resolve: {					
				title: function(){
					return title;
				},
				message: function(){
					return message;
				},
				superClass: function(){
					return superClass;
				}				
			},
			controller: function($scope, $uibModalInstance, title, message, superClass) {
				
				$scope.title = title;				
				$scope.message = message;
				$scope.superClass = superClass;
				
				$scope.ok = function () {
					 $uibModalInstance.close('confirmed');
				};				

				$scope.cancel = function() {
					$uibModalInstance.dismiss();
				}
			}
		};
	}	
	
			
	return modalFeedback;
}])