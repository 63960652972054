angular
    .module("invoices.directives")

    .directive(
        "invoiceRelatedCreditNotes",
        function ($rootScope, $state, CreditNotesService ) {
            return {
                restrict: "EAC",
                replace: true,
                scope: {
                    invoiceId: "=",
                    permissions: "=",
                },
                template: `<div>
							<div ng-show="!dataLoaded" class="row text-center">
								<span ><i class="fa fa-cog fa-spin" style="font-size:48px"></i></span>
							</div>
							<div ng-show="dataLoaded">
								<table ng-show="permissions['invoices.list'] && dataLoaded" class="table"  >
                                    <tr>
                                        <th>Number</th>		
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Total</th>
                                        <th>Status</th>									
                                        <th style="width: 10%" ng-if="permissions['invoices.show']">View</th>
                                    </tr>
                                    <tr ng-repeat="item in credit_notes">
                                        <td><b>{{item.credit_note_number}}</b></td>	
                                        <td>{{item.start_date | date:'mediumDate'}}</td>
                                        <td>{{item.end_date | date:'mediumDate'}}</td>
                                        <td class="text-right">{{item.total | number:2}}</td>
                                        <td><span ng-class="{'label-info': item.status == 'new', 'label-warning': item.status == 'sent', 'label-success': item.status == 'allocated', 'label-danger': item.status == 'overdue'}" class="label">{{item.status | capitalize}}</span></td>				
                                        <td><a ng-if="permissions['invoices.show']" href="" ng-click="go(item.id)" class="btn btn-primary btn-outline btn-sm"><i class="fa fa-binoculars"></i></a></td>
                                    </tr>								
                                </table>
							</div>
						</div>`,
                link: function (scope, element, attr) {
                    scope.dataLoaded = false;
                    scope.credit_notes = [];

                    scope.go = function (id) {
                        $state.go("main.credit-note", { id: id }, {});
                    };

                    var config = {
                        params: {
                            findby: "related_invoice_id",
                            findbyvalue: scope.invoiceId,
                        },
                    };

                    function getCreditNotes() {
                        scope.dataLoaded = false;
                        CreditNotesService.getCreditNotes(config)
                            .then(
                                function (success) {
                                    scope.credit_notes = success.data;
                                },
                                function (error) {
                                    console.log(error);
                                }
                            )
                            .finally(function () {
                                scope.dataLoaded = true;
                            });
                    }

                    getCreditNotes();

                    scope.$on("updateSidebars", function (event, result) {
                        getCreditNotes();
                    });

                    scope.$watch("invoiceId", function (newVal, oldVal) {
                        if (newVal != oldVal) {
                            getCreditNotes();
                        }
                    });
                },
            };
        }
    );
