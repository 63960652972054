angular.module('angular-utils.services')

.factory('UtilBroadcastService', ['$rootScope', '$timeout', '$state', function($rootScope, $timeout, $state) {
	
	var utilBroadcastService = {};
	
	utilBroadcastService.broadcastUpdate = function() {    
		$rootScope.$broadcast('updateSidebars', {
			data: true
		});
	};

	utilBroadcastService.broadcastUpdateRecord = function() {    
		$rootScope.$broadcast('updateRecord', {
			data: true
		});
	};
	
	utilBroadcastService.showAlert = function(scope, type, message, id = false, state){
		scope.showSaveSpinner = false;
		scope.showAlert = true;
		scope.alertDetails.type = type;
		scope.alertDetails.message = message;                       
		
		$timeout(function() {
			scope.showAlert = false;
			if(id) $state.go(state, {'id': id}, {reload: true});
		}, 2000);
	}   
	
	return utilBroadcastService;


}])