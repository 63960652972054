angular.module('channel-manager')

    .controller('ModalCmCreateBookingController', function ($scope, $uibModal, data, title, permissions, ChannelManagerService, ApartmentsService,
        UtilBroadcastService, $timeout, ModalConfirmAction, ModalFeedback, $uibModalInstance) {

        $scope.title = title;
        $scope.data = data;
        $scope.showSaveSpinner = false;
        $scope.showAlert = false;
        $scope.alertDetails = {};
        $scope.permissions = permissions;
        $scope.data = data;
        $scope.apartment_id;
        $scope.apartments;

        var config = {};

        function getApartments() {
            config.params = {};
            config.params.orderby = 'address1';
            config.params.findby = 'apartments.development_id';
            config.params.findbyvalue = $scope.data.development_id;
            config.params.status = 'active';

            ApartmentsService.getApartments(config).then(function (success) {
                $scope.apartments = success.data;
            }, function (error) {
                console.log(error);
            });
        }

        getApartments();   

        $scope.saveRecord = function (item) {
            var postData = {};
            var bookingId=false;
            postData.apartment_id = data.apartment_id*1;
            postData.cm_room_reservation_id = data.cm_room_reservation_id*1
            var message;
            message = 'Record updated';		
           
            $scope.showSaveSpinner = true;
            ChannelManagerService.postCreateBooking(postData).then(function (success) {
                $scope.createBookingForm.$setPristine();		
                UtilBroadcastService.showAlert($scope, 'Success', message, false);
                bookingId = success.data;
            }, function (error) {
                console.log(error);
            	UtilBroadcastService.showAlert($scope,'Failure', 'Unable to create/update record.');
            }).finally(function(){
                $scope.showSaveSpinner = false;            				
            	$timeout(function() {
            		$uibModalInstance.close(bookingId);
            	}, 2000);						
            });		
        }    

        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        }

    })