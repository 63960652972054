angular.module('dashboard.directives')

	.directive('dashboardInvoiceOccPerMonth', function (DashboardService, $filter) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				chartTitle: '@',
			},      
			template:	`<div>
							<div class="row">
								<div class="col-lg-12">
									<div class="panel panel-primary">
										<div class="panel-heading" >
											<h3 class="panel-title"><i class="fa fa-long-arrow-right fa-fw"></i> {{chartTitle}}</h3>
										</div>
										<div class="panel-body" >
											<div class="col-lg-12">																							
												<div uib-datepicker 
												ng-model="selected_date"
													class="well well-sm" datepicker-options="datePickerOptions"
													datepicker-localdate ng-change="getInvoiceOccPerMonth()">
												</div>
												
											</div>
										
											<div ng-if="!dataLoaded" class="text-center">
												<i class="fa fa-cog fa-spin" style="font-size:48px"></i>
											</div>
											<div ng-if="dataLoaded">
												<canvas height="250px"  id="base" class="chart-horizontal-bar"
													chart-data="chart.data" 
													chart-labels="chart.labels" 
													chart-series="chart.series"
													chart-colors="chart.colors"
													chart-options="chart.options"		
												
													
												></canvas>

											</div>
										</div>
									</div>
								</div>							
							</div>
						</div>`,
			link: function (scope, element, attr) {
				
			
				scope.dataLoaded = false;
				scope.dates = {};
				scope.chart = {};		

				scope.datePickerOptions = {
					monthColumns: 3, minMode: 'month'
				}
								
				function defaultDates(){
					scope.selected_date =  moment.utc().startOf('month');								
				}
				
				defaultDates();				
				
				scope.getInvoiceOccPerMonth = function(){
					scope.dataLoaded = false;

					scope.selected_date = moment(scope.selected_date);

					scope.dates.start_date = scope.selected_date.clone().add(-24, 'months').startOf('month').format('YYYY-MM-DD');				
					scope.dates.end_date = scope.selected_date.clone().endOf('month').format('YYYY-MM-DD');		
					
					var object = {};
					object.end_date = scope.dates.end_date;		
					object.start_date = scope.dates.start_date;	
					object.type = 'OCCPerMonth';
					
					scope.chart.options = {
						onClick: function(e) {
							var element = this.getElementAtEvent(e);
							if (element.length) {
							   console.log(element[0])
							}
						 },
						 legend: {
							display: true,
							labels: {
								fontColor: 'rgb(255, 99, 132)'								
							},
							position: 'top'
						},
						 tooltips: {
							callbacks: {
								label: function(tooltipItem, data) {		
									return $filter('number')(tooltipItem.value*100, 2) + '%'; 									
								}
							}
						}
					};
					
					scope.chart.labels = [];
					scope.chart.data = [];		
					scope.chart.data[0] = [];		
					scope.chart.series = ['Occupancy Per Month'];
					scope.chart.colors = [
							{
								backgroundColor: "rgba(0,150,150, 0.2)",
								borderColor: "rgba(0,150,150, 1)"
							}
						];
					
					DashboardService.postBookingFigures(object).then(function(success){	
						
						angular.forEach(success.data, function(value, key) {		
					
							scope.chart.labels.push(moment(value.month_date).format("MMM-YYYY"));
							scope.chart.data[0].push(value.occupancy);						
						  });				
						
					}, function(error){
						console.log(error);			
					}).finally(function() {			
						scope.dataLoaded = true;
					});
				}
				
				scope.getInvoiceOccPerMonth();								
				
			}
		};
	})