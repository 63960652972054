angular.module('organisations.directives')

	.directive('contactOrganisation', function ($rootScope, OrganisationsService, ModalConfigurationService, $uibModal, ModalConfirmAction, ModalFeedback, $timeout) {
		return {
			restrict: 'EAC',
			replace: true,
			scope: {
				organisationId: '=',	
				permissions: '='
			},
			template: `<div>
							<div ng-show="!dataLoaded" class="row text-center">
								<span ><i class="fa fa-cog fa-spin" style="font-size:48px"></i></span>
							</div>
							<div ng-show="dataLoaded">
								<table class="table"  >
									<tr>
										<th>Contact</th>
										<th>Notes</th>
										<th colspan="2" style="width: 10%"><div class="text-center">Action</div></th>
									</tr>
									<tr ng-repeat="item in contact_organisations">
										<td>{{item.contact_name}}</td>
										<td>{{item.notes}}</td>
										<td><my-sub-form-button click-fn="showContactOrganisation(item)" button-type="edit"></my-sub-form-button></td>
										<td><my-sub-form-button ng-show="permissions['organisations.delete']" 
												click-fn="deleteContactOrganisation(item)" button-type="delete"></my-sub-form-button></td>                                
																	
									</tr>                           
								</table>
								<div class="pull-right">
									<my-sub-form-button ng-show="permissions['organisations.create']" 
									click-fn="createContactOrganisation()" button-type="add"></my-sub-form-button>
								</div>
							</div>
						</div>`,
			link: function (scope, element, attr) {
				var config = {	params: {}	};
				scope.dataLoaded = false;
				scope.contact_organisations = [];
				scope.apartments = [];			
				
				function getContactsByOrganisation(){
					OrganisationsService.getContactsByOrganisation(scope.organisationId).then(function(success){     
						scope.contact_organisations = success.data;  
						scope.dataLoaded = true;
					}, function(error){
						console.log(error);
					});
				}
				
				getContactsByOrganisation();
				
				function broadcastUpdate(){
					$rootScope.$broadcast('updateSidebars', {
						data: true
					});
				}
				
				scope.$on('updateSidebars', function (event, result) {					
					getContactsByOrganisation();
				});
				
				
				function showContactOrganisation(data){
					var modalInstance = ModalConfigurationService.getContactOrganisation(data, scope.permissions);                  
                    modalInstance.result.then(function (result) {
                        getContactsByOrganisation();     
                    });
				}
				
				scope.showContactOrganisation = function(data){
					showContactOrganisation(data);					
				}
				
				scope.createContactOrganisation = function(){
					var data = {id: 'new', organisation_id: scope.organisationId};
					showContactOrganisation(data);
				}
				
				scope.deleteContactOrganisation = function(item){
					var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
						'Warning!', 
						'Are you sure you would like to delete this record? Deletions cannot be undone.', 
						'alert-danger')
					);
					
					modalInstance.result.then(function (data) {
						if(data=='confirmed'){
							OrganisationsService.getContactOrganisationDelete(item.id).then(function(success){                      
								var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback(
										'Success',
										'Record deleted!',
										'alert-success'
									)
								);						
								$timeout(function() { modalInstance1.close('cancel'); }, 2000);
								broadcastUpdate();							
							}, function(error){
								var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback(
										'Error!',
										'Unable to delete record. Please contact a system administrator.',
										'alert-danger'
									)
								);			
								$timeout(function() { modalInstance1.close('cancel'); }, 2000);
							});
						}					  
					});
				}
				
			}
		};
	})