angular.module('auth.controllers', [])

    .controller('AuthController', ['$auth', '$state', '$rootScope', '$http', '$uibModal', 'ModalFeedback', '$location',  '$window',
        function ($auth, $state, $rootScope, $http, $uibModal, ModalFeedback, $location, $window) {
            var self = this;

            self.loginError = false;
            self.loginErrorText;
            self.showSpinner = false;
            self.mode = 'production';


            function getMode() {
                var url = $location.$$host;
                if (url == 'localhost') {
                    self.mode = 'testing';
                }
                if (url.split('.')[2] == 'sams-testing') {
                    self.mode = 'testing';
                }
            }

            getMode();

            self.login = function () {

                self.showSpinner = true;

                var credentials = {
                    email: self.email,
                    password: self.password
                }

                // Use Satellizer's $auth service to login
                $auth.login(credentials)
                    .then(function () {
                        return $http.get('api/authenticate/user');
                    }).then(function (response) {
                        // Stringify the returned data to prepare it
                        // to go into local storage
                        var user = JSON.stringify(response.data);

                        // Set the stringified user data into local storage
                        localStorage.setItem('user', user);

                        // The user's authenticated state gets flipped to
                        // true so we can now show parts of the UI that rely
                        // on the user being logged in
                        $rootScope.authenticated = true;

                        // Putting the user's data on $rootScope allows
                        // us to access it anywhere across the app
                        $rootScope.currentUser = response.data;

                        // Everything worked out so we can now redirect to
                        // the users state to view the data
                        $state.go('main.dashboard', {}, { reload: true });
                    }, function (error) {
                        self.loginError = true;
                        self.loginErrorText = error.data.error;
                        console.log(error.data.error);
                        if (self.loginErrorText == 'invalid_credentials') $uibModal.open(ModalFeedback.showFeedback('Error!', 'Unable to login. Please check your username and password.', 'alert-danger', true));
                        if (self.loginErrorText == 'over_login_attempts_limit') $uibModal.open(ModalFeedback.showFeedback('Error!', 'You are over your limit for login attempts.', 'alert-danger', true));

                        // Because we returned the $http.get request in the $auth.login
                        // promise, we can chain the next promise to the end here
                    }).finally(function () {
                        self.showSpinner = false;
                    });
            }

            self.logout = function () {
                console.log('authLogout');
                $http({
                    method: 'GET', url: 'api/authenticate/logout', headers: {
                        'Authorization': 'Bearer ' + $auth.getToken()
                    }
                }).then(function (success) {
                    console.log('logout success');
                }, function (error) {
                    console.log(error);
                }).finally(function () {
                    $auth.logout().then(function () {
                        // Remove the authenticated user from local storage
                        localStorage.removeItem('user');

                        // Flip authenticated to false so that we no longer
                        // show UI elements dependant on the user being logged in
                        $rootScope.authenticated = false;

                        // Remove the current user info from rootscope
                        $rootScope.currentUser = null;

                        $window.location.href = '/login-redirect';
                    });
                });
            }

        }]);