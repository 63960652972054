angular.module('enquiries')

	.directive('enquiryDetails', function ($rootScope, EnquiryService, PermissionsService, 
										ModalEnquiryDetail, ModalConfigurationService, $state,
										$uibModal, UtilService, ModalFeedback, ModalConfirmAction, $timeout) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				enquiryId: '=',
				enquiry: '=',
				checkInDate: '=',
				checkOutDate: '=',				
				permissions: '='
			},      
			template:	`<div>
							<div ng-show="!dataLoaded" class="row text-center">
								<span ><i class="fa fa-cog fa-spin" style="font-size:48px"></i></span>
							</div>
							<div ng-show="dataLoaded">
								<table class="table"  >
									<tr>										
										<th style="width: 50%;" >Apartment</th>
										<th style="width: 30%;" >Amount</th>
										<th colspan="2" style="width: 10%; text-align:centre"><div class="text-center">Action</div></th>
										<th  ng-if="enquiry.booking_id == null" style="width: 10%;"><div class="text-center">Convert</div></th>										
									</tr>
									<tr ng-repeat="item in enquiry_details">										
										<td>{{item.address1}}</td>
										<td>{{item.amount}}</td>
										<td><my-sub-form-button click-fn="showEnquiryDetail(item)" button-type="edit"></my-sub-form-button></td>
										<td><my-sub-form-button ng-show="permissions['bookings.delete']" 
												click-fn="deleteEnquiryDetail(item)" button-type="delete"></my-sub-form-button></td>  
										<td ng-if="enquiry.booking_id == null">
											<div class="text-center">
												<button 				
													id="btnConvertEnquiryToBooking{{$index}}"									
													style="width: 100px;" 
													class="btn btn-primary btn-block btn-outline" 
													ng-click="convertToBooking(item)">Convert</button>
											</div>
										</td> 			
																	   
									</tr>                           
								</table>
								<div class="pull-right">
									<my-sub-form-button ng-show="permissions['bookings.create'] && checkInDate != null && checkOutDate != null" 
									click-fn="createEnquiryDetail(item)" button-type="add"></my-sub-form-button>
								</div>
							</div>
						</div>`,
			link: function (scope, element, attr) {
				var config = {	params: {}	};
				var enquiryDetailData;
				scope.dataLoaded = false;
				scope.permissions = [];
				scope.enquiry_details = [];
				scope.areas = [];
				scope.emailSpinner = 0;
				
				function getEnquiryDetails(){
					config.params = {};	
					config = {	params: { 	findby: 'enquiry_id', findbyvalue: scope.enquiryId }  };
					EnquiryService.getEnquiryDetails(config).then(function(success){     
						scope.enquiry_details = success.data;					
					}, function(error){
						console.log(error);
					}).finally(function(){
						scope.dataLoaded = true;
					});
				}
				
				getEnquiryDetails();

				PermissionsService.getByModuleByUser('bookings').then(function(success){   			
					scope.permissions = success.data;			
				}, function(error){
					console.log(error);
				});  
								
				function broadcastUpdate(){
					$rootScope.$broadcast('updateSidebars', {
						data: true
					});
				}
				
				scope.$on('updateSidebars', function (event, result) {					
					getEnquiryDetails();
				});
				
				function showEnquiryDetail(){
					var modalInstance = $uibModal.open(ModalEnquiryDetail.show(enquiryDetailData, scope.permissions));
					
					modalInstance.result.then(function (data) {
						getEnquiryDetails();
					});
				}
				
				scope.showEnquiryDetail = function(item){
					enquiryDetailData = item;
					showEnquiryDetail();
					
				}
				
				scope.createEnquiryDetail = function(){
					enquiryDetailData = {id: 'new', enquiry_id: scope.enquiryId, checkInDate: scope.checkInDate, checkOutDate: scope.checkOutDate};
					showEnquiryDetail();
				}
				
						
				scope.deleteEnquiryDetail = function(item){
					var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
						'Warning!', 
						'Are you sure you would like to delete this record? Deletions cannot be undone.', 
						'alert-danger')
					);
					
					modalInstance.result.then(function (data) {						
						EnquiryService.getEnquiryDetailDelete(item.id).then(function(success){                      
							var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Record deleted!', 'alert-success'));						
							$timeout(function() { modalInstance1.close('cancel'); }, 2000);
							broadcastUpdate();	
						}, function(error){
							console.log(error);
							var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
										'Unable to delete record. Please contact a system administrator.', 'alert-danger'));			
							$timeout(function() { modalInstance1.close('cancel'); }, 2000);
						});
						        
					});
				}

						

				scope.convertToBooking = function(item){
					input = {};

					input.id = 'new';						
					input.check_in_date = scope.enquiry.check_in_date;
					input.check_out_date = scope.enquiry.check_out_date;
					input.duration = UtilService.getDateDiff(scope.enquiry.check_in_date, scope.enquiry.check_out_date);
					input.charge_per_night = item.amount;	
					input.apartment_id = item.apartment_id;
					input.organisation_id = scope.enquiry.organisation_id;
					input.show_booking_start_on_schduler = 1;
					input.show_end_booking_notification = 'Yes';
					input.status = 'active';
					input.type = 'booking';					

					input.enquiry_id = scope.enquiryId;				
					input.bookingdetails = [];				
				
				
					console.log(input);
					
					var modalInstance = ModalConfigurationService.getSchedulerBooking(input, scope.permissions);
	
					modalInstance.result.then(function (result) {  					
						if(result == 'close'){
							
						} else {
							$state.go('main.booking', {'id': result}, {});							
						}
					}, function(error){
						console.log(error);                        
					});
					
					modalInstance.close('cancel');
					
				
				}			
			}
		};
	})
