angular.module('enquiries')

	.controller('EnquiryController', ['$scope', 'UtilBroadcastService', 'EnquiryService', '$location',
		'$stateParams', '$uibModal', '$state', 'PermissionsService', 'ModalConfirmAction', 'ModalFeedback',
		'$timeout', 'UtilLookUpService',
		function ($scope, UtilBroadcastService, EnquiryService, $location,
			$stateParams, $uibModal, $state, PermissionsService, ModalConfirmAction,
			ModalFeedback, $timeout, UtilLookUpService) {

			var self = this;
			self.id = $stateParams.id;
			var config = { params: {} };
			self.dataLoaded = false;
			self.showSaveSpinner = false;
			self.showAlert = false;
			self.alertDetails = {};
			self.isNew = (self.id == 'add-new');
			self.permissions = [];
			self.enquiry = {};
			self.showEmailConfirmationSpinner = false;
			self.sources = UtilLookUpService.getEnquirySources();

			self.location = $location.protocol() + '://' + $location.host();

			self.booking_apartment_types = UtilLookUpService.getApartmentTypeForBookings();
			self.booking_cancellation_terms = UtilLookUpService.getCancellationTermsForBookings();
			self.booking_deposit_information = UtilLookUpService.getDepositInformationForBookings();

			PermissionsService.getByModuleByUser('bookings').then(function (success) {
				self.permissions = success.data;
			}, function (error) {
				console.log(error);
			});

			function getEnquiry() {
				EnquiryService.getShow(self.id).then(function (success) {
					self.enquiry = success.data;
					self.dataLoaded = true;
				}, function (error) {
					console.log(error);
				});
			}

			if (!self.isNew) {
				getEnquiry();
			} else {
				self.dataLoaded = true;
			}

	
			self.sendEnquiryEmail = function () {

				var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
					'Conifirmation',
					'Are you sure you would like to send this enquiry confirmation?',
					'alert-danger')
				);

				modalInstance.result.then(function (data) {
					self.showEmailConfirmationSpinner = true;
					EnquiryService.getSendEmailConfirmation(self.id).then(function (success) {
						var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Email Sent!', 'alert-success'));
						$timeout(function () { modalInstance1.close('cancel'); }, 2000);
						broadcastUpdate();
					}, function (error) {
						console.log(error);
						var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
							`Unable to send email. Please contact a system administrator.`, 'alert-danger'));
						$timeout(function () { modalInstance1.close('cancel'); }, 2000);
					}).finally(function () {
						self.showEmailConfirmationSpinner = false;
					});

				});

			}

			self.saveRecord = function () {
				self.showSaveSpinner = true;
				var promise; var message;
				if (self.isNew) {
					self.showSaveSpinner = true;
					promise = EnquiryService.postStore(self.enquiry);
					message = 'Record created';
				} else {
					promise = EnquiryService.postUpdate(self.id, self.enquiry);
					message = 'Record updated';
				}

				promise.then(function (success) {
					var newId = false;
					if (self.isNew) newId = success.data;
					$scope.enquiryForm.$setPristine();
					UtilBroadcastService.showAlert(self, 'Success', message, newId, 'main.enquiry');
				}, function (error) {
					console.log(error);
					UtilBroadcastService.showAlert(self, 'Failure', 'Unable to create/update record.');
				}).finally(function () {
					UtilBroadcastService.broadcastUpdate();
				});
			}

			self.deleteRecord = function () {
				var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
					'Warning!',
					'Are you sure you would like to delete this record? Deletions cannot be undone.',
					'alert-danger')
				);

				modalInstance.result.then(function (msg) {
					EnquiryService.getDelete(self.id).then(function (success) {
						var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Record deleted!', 'alert-success', true));
						$timeout(function () {
							modalInstance1.close('cancel');
							$state.go('main.enquiries', {}, { reload: true });
						}, 2000);

					}, function (error) {
						console.log(error);
						var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
							'Unable to delete record. Please contact a system administrator.', 'alert-danger', true));
						$timeout(function () { modalInstance1.close('cancel'); }, 2000);
					});
				});


			}


			$scope.$on('updateSidebars', function (event, result) {
				console.log('updating sidebars from controller');
			});




		}])
