angular
    .module("credit-notes")

    .controller("CreditNotesController", [
        "$state",
        "CreditNotesService",
        "PermissionsService",
        "$timeout",
        "$window",
        function ($state, CreditNotesService, PermissionsService, $timeout, $window) {
            var self = this;

            self.dataLoaded = false;
            self.credit_notes = [];
            self.bookings = [];
            self.credit_notes.current_page = 1;
            self.maxSize = 5;
            self.search_value;
            self.sortASC = true;
            self.permissions = [];
            self.report_data = { data: "no" };

            var config = {
                params: {
                    page: self.credit_notes.current_page,
                    orderby: "credit_notes.id",
                    sort: "DESC",
                    per_page: 30,
                },
            };

            PermissionsService.getByModuleByUser("credit_notes").then(
                function (success) {
                    self.permissions = success.data;
                },
                function (error) {
                    console.log(error);
                }
            );

            function getCreditNotes() {
                self.dataLoaded = false;
                CreditNotesService.getCreditNotesPaginate(config).then(
                    function (success) {
                        self.credit_notes = success.data;
                        self.dataLoaded = true;
                    },
                    function (error) {
                        console.log(error);
                    }
                );
            }

            getCreditNotes();

            self.go = function (id) {
                $state.go("main.credit-note", { id: id }, {});
            };

            self.pagination = function () {
                config.params.page = self.credit_notes.current_page;
                getCreditNotes();
            };

            self.sortTable = function (heading) {
                config.params.orderby = heading;
                config.params.sort = self.sortASC ? "ASC" : "DESC";
                self.sortASC = !self.sortASC;
                getCreditNotes();
            };

            self.search = function () {
                self.credit_notes.current_page = 1;
                config.params.page = self.credit_notes.current_page;
                config.params.search = self.search_value;
                getCreditNotes();
            };

            self.print = function (type, item) {
                var config = { params: { type: type, credit_note: item.credit_note } };
                item.showSpinner = 1;

                CreditNotesService.getStandardCreditNoteReport(item.id, config)
                    .then(
                        function (url) {
                            var printwWindow = $window.open(url);
                        },
                        function (error) {
                            console.log(error);
                        }
                    )
                    .finally(function () {
                        item.showSpinner = 0;
                    });
            };
        },
    ]);
