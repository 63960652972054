angular
    .module("credit-notes")

    .directive(
        "modalCreditNoteDetail",
        function ( CreditNotesService, UtilService, UtilBroadcastService, $timeout) {
            return {
                restrict: "EAC",
                replace: true,
                scope: {
                    data: "=",
                    permissions: "=",
                    updateFn: "&",
                },
                template: `<div>
                            <div class="show-slide-side">
                                <div class="row">
                                    <div class="col-md-4">
                                        Start: {{data.start_date | date:shortDate}}
                                    </div>
                                    <div class="col-md-4">
                                        End: {{data.end_date | date:shortDate}}
                                    </div>
                                    <div class="col-md-4">
                                        Duration: {{data.duration}}
                                    </div>
                                </div>
                                <form name="creditNoteDetailsForm">
                                    <input ng-focus="focus=false" ng-blur="focus=true" type="hidden" class="form-control" name="id" ng-model="data.id" readonly="" />
                                    
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group" ng-class="{'has-error': creditNoteDetailsForm.credit_note_id.$invalid && creditNoteDetailsForm.credit_note_id.$touched}">       
                                                <label>Credit Note ID</label>
                                                <input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="credit_note_id."
                                                    ng-model="data.credit_note_id" readonly/>          
                                            </div>	
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group" ng-class="{'has-error': creditNoteDetailsForm.quantity.$invalid && creditNoteDetailsForm.quantity.$touched}">
                                            <label>Quantity *</label>
                                            <input ng-focus="focus=false" 
                                                    ng-readonly="!permissions['credit-notes.update']"
                                                    ng-blur="focus=true" type="text" class="form-control" name="quantity"
                                                    ng-model="data.quantity" required/>
                                            <p ng-show="creditNoteDetailsForm.quantity.$invalid && creditNoteDetailsForm.quantity.$touched" class="help-block">
                                                            Required
                                            </p>
                                        </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group" ng-class="{'has-error': creditNoteDetailsForm.unit_price.$invalid && creditNoteDetailsForm.unit_price.$touched}">
                                                <label>Unit Price *</label>
                                                <input ng-focus="focus=false" 
                                                        ng-readonly="!permissions['credit-notes.update']"
                                                        ng-blur="focus=true" type="text" class="form-control" name="unit_price"
                                                    ng-model="data.unit_price" required/>
                                                <p ng-show="creditNoteDetailsForm.unit_price.$invalid && creditNoteDetailsForm.unit_price.$touched" class="help-block">
                                                            Required
                                                </p>
                                            </div>
                                        </div>
                                    </div>						
                                    
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group" ng-class="{'has-error': creditNoteDetailsForm.total.$invalid && creditNoteDetailsForm.total.$touched}">
                                                <label>Total</label>
                                                <input ng-focus="focus=false"
                                                    ng-blur="focus=true" type="text" class="form-control" name="total"
                                                    ng-model="data.total" readonly required/>
                                                <p ng-show="creditNoteDetailsForm.total.$invalid && creditNoteDetailsForm.total.$touched" class="help-block">
                                                                Required
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group" ng-class="{'has-error': creditNoteDetailsForm.vat_rate.$invalid && creditNoteDetailsForm.vat_rate.$touched}">
                                                <label>VAT Rate *</label>
                                                <input ng-focus="focus=false" 
                                                        ng-readonly="!permissions['credit-notes.update']"
                                                        ng-blur="focus=true" type="percent" class="form-control" name="vat_rate"
                                                        ng-model="data.vat_rate" xl-percentage required/>
                                                <p ng-show="creditNoteDetailsForm.vat_rate.$invalid && creditNoteDetailsForm.vat_rate.$touched" class="help-block">
                                                                Required
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group" ng-class="{'has-error': creditNoteDetailsForm.vat.$invalid && creditNoteDetailsForm.vat.$touched}">
                                            <label>VAT</label>
                                            <input ng-focus="focus=false" 
                                                    ng-blur="focus=true" type="text" class="form-control" name="vat"
                                                    ng-model="data.vat" readonly required/>
                                            <p ng-show="creditNoteDetailsForm.vat.$invalid && creditNoteDetailsForm.vat.$touched" class="help-block">
                                                            Required
                                            </p>
                                        </div>
                                            
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-4">
                                            <label class="form-group dropdown-wide" ng-class="{'has-error': creditNoteDetailsForm.type.$invalid && creditNoteDetailsForm.type.$touched}">
                                                <label>Type *</label>
                                                <select class="form-control" required name="type" ng-model="data.type">
                                                    <option value="{{type}}" ng-selected="data.type===type" ng-repeat="type in credit_note_detail_types">{{type
                                                        | capitalize | underscoreless}}</option>
                                                </select>
                                                <p ng-show="creditNoteDetailsForm.type.$invalid && creditNoteDetailsForm.type.$touched" class="help-block">
                                                    Required
                                                </p>
                                            </label>
                                        </div>
                                        <div class="col-md-8">
                                            <div class="form-group" ng-class="{'has-error': creditNoteDetailsForm.description.$invalid && creditNoteDetailsForm.description.$touched}">       
                                                <label>Description</label>
                                                <input ng-focus="focus=false" 
                                                        ng-readonly="!permissions['credit-notes.update']"
                                                        ng-blur="focus=true" 				
                                                        type="text" class="form-control" name="description"
                                                    ng-model="data.description" required/>  
                                                <p ng-show="creditNoteDetailsForm.description.$invalid && creditNoteDetailsForm.description.$touched" class="help-block">
                                                                Required
                                                </p>
                                            </div>
                                        </div>
                                    </div>							
                                </form>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm-3">
                                        <my-save-button
                                            click-fn="saveRecord()"
                                            show-spinner="showSaveSpinner"												
                                            ng-disabled="creditNoteDetailsForm.$invalid"									
                                        ></my-save-button>
                                        
                                    </div>
                                    <div class="col-sm-6">
                                        <my-form-alert				
                                            show-alert="showAlert"
                                            alert-type="alertDetails.type"
                                            alert-message="alertDetails.message"											
                                        ></my-form-alert>											
                                    </div>
                                    <div class="col-sm-3">
                                        <button type="button" class="btn btn-lg btn-default btn-outline btn-block" 
                                            ng-click="cancel()">Cancel</button>										
                                    </div>	
                                </div>    
                            </div>                            
						</div>`,
                link: function (scope, element, attr) {
                    scope.showSaveSpinner = false;
                    scope.showAlert = false;
                    scope.alertDetails = {};
                    scope.credit_note_detail_types = CreditNotesService.getCreditNoteDetailTypes();
                    scope.data.duration = UtilService.getDateDiff(scope.data.start_date, scope.data.end_date);

                    function init() {
                        if (scope.data.id === "new") {
                            scope.data.quantity = scope.data.duration;
                            scope.data.unit_price = scope.data.charge_per_night;
                            scope.data.description = "Apartment";
                            amendTotal();
                        }
                    }

                    init();

                    scope.saveRecord = function () {
                        scope.showSaveSpinner = true;

                        var promise;
                        var message;
                        if (scope.data.id === "new") {
                            promise = CreditNotesService.postCreditNoteDetailStore(scope.data);
                            message = "Record created";
                        } else {
                            promise = CreditNotesService.postCreditNoteDetailUpdate(scope.data.id, scope.data);
                            message = "Record updated";
                        }

                        promise
                            .then(
                                function (success) {
                                    scope.creditNoteDetailsForm.$setPristine();
                                    UtilBroadcastService.showAlert(scope, "Success", message, false);
                                    if (scope.data.id === "new") scope.data.id = success.data;
                                },
                                function (error) {
                                    console.log(error);
                                    UtilBroadcastService.showAlert(scope, "Failure", "Unable to create/update record.");
                                }
                            )
                            .finally(function () {
                                UtilBroadcastService.broadcastUpdate();
                                $timeout(function () {
                                    //scope.uibModalInstance.close("close");
                                    scope.updateFn({ myVal: "close" });
                                }, 2000);
                            });
                    };

                    scope.cancel = function () {
                        //scope.uibModalInstance.dismiss();
                        scope.updateFn( { myVal: 'dismiss'});
                    };

                    function amendTotal() {
                        //console.log()
                        if (scope.data.quantity == null || scope.data.unit_price == null) return;
                        scope.data.total = (scope.data.quantity * scope.data.unit_price).toFixed(2);
                        amendVat();
                    }

                    function amendVat() {
                        //console.log()
                        if (scope.data.total == null || scope.data.vat_rate == null) return;
                        scope.data.vat = (scope.data.total * scope.data.vat_rate).toFixed(2);
                    }

                    scope.$watch(
                        function () {
                            return scope.data.quantity;
                        },
                        function (value) {
                            amendTotal();
                        },
                        true
                    );

                    scope.$watch(
                        function () {
                            return scope.data.unit_price;
                        },
                        function (value) {
                            amendTotal();
                        },
                        true
                    );

                    scope.$watch(
                        function () {
                            return scope.data.vat_rate;
                        },
                        function (value) {
                            amendVat();
                        },
                        true
                    );
                },
            };
        }
    );
