angular.module('reports.directives', [])
.directive('displayReport', ['$http', '$window', '$timeout', function($http, $window, $timeout) {
	return {
		restrict: 'E',    
		replace: true,     
		scope:{
			reportData:"=",
			pdfContent: '@',
		},
		controller:function($scope){
			function getReport(){
				$http({
					method: 'POST',
					skipAuthorization: true,
					responseType: 'arraybuffer',
					url: 'https://sams-wla-reports.jsreportonline.net/api/report',
					data: $scope.reportData,
					headers: {
								'Authorization': 'Basic YXVzdGlucm9iZXJ0NDM3QGdtYWlsLmNvbTpqNSJlOVw4OT4jNCpMe0Y=',
								"Content-Type": "application/json"
							}
				}).then(function(success){            
					var file = new Blob([success.data], {type: 'application/pdf'});
					var fileURL = URL.createObjectURL(file);               
					
					$window.open(fileURL);
					$scope.reportData.data = 'no';	
					
					$timeout(function() {
						$scope.$apply();
					}, 1000);
				}, function(error){
					console.log(error);
				});
			}
			
			getReport();
			
			//var blob = new Blob(["Hello, world!"], {type: "text/plain;charset=utf-8"});
			
			
			$scope.$watch('reportData', function(newValue, oldValue) {         
				if(newValue != oldValue){
					getReport();
				}
				
			});
			
		},   
		template:
			'<div class="modal-content" onloadstart="">' +
				'<object data="{{pdfContent}}"  type="application/pdf" style="width:100%; height:1000px" />' +
			'</div>'
	}
}])