angular.module('dashboard.directives')

	.directive('dashboardIssues', function (IssuesService) {
		return {
			restrict: 'EAC',
			replace: true,
			scope: {
				chartTitle: '@',
			},
			template: `<div class="panel panel-green border">
                            <div class="panel-heading clearfix">
                                <span class="panel-title pull-left"><i class="fa fa-clock-o fa-fw"></i> {{chartTitle}}</span>
								<div class="pull-right">
									<button type="button" class="btn btn-default rounded" 
											ng-click="getIssuesWithType('my_issues')">
										My Issues
									</button>
								
									<button type="button" class="btn btn-default rounded" 
											ng-click="getIssuesWithType('all_issues')">
											All Issues
									</button>						
								</div>
                            </div>
                            <div class="panel-body" style="height: 350px; overflow-y: scroll;">
								<div ng-if="!dataLoaded" class="text-center">
									<i class="fa fa-cog fa-spin" style="font-size:48px"></i>
								</div>
                                <table ng-show="dataLoaded" 
									class="table table-striped table-hover clickable"  >
									<thead>
										<tr>
											<th>Site</th>	
											<th>Issue</th>	
											<th>Type</th>
											<th>Assigned To</th>											
											<th>Date Created</th>
											<th>Due By</th>											
											<th>Status</th>
										</tr>
									</thead>
									<tbody >
										<tr ng-repeat="item in issues" 
											class="table-row" 
											ui-sref="main.issue({ id: '{{item.id}}' })"
											href="">
											<td>{{item.site}}</td>	
											<td>{{item.issue}}</td>	
											<td><span class="lb-md label"
												ng-class="{'label-danger': item.type=='red',
														'label-warning': item.type=='amber',
														'label-success': item.type=='green'}"
												  >{{item.type | capitalize}}</span></td>
											<td>{{item.assigned_to_name}}</td>
											<td>{{item.created_at}}</td>	
											<td>{{item.due_by}}</td>												
											<td><span class="badge">{{item.status | capitalize }}</span></td>
										</tr>											
									</tbody>
									
								</table>                
                            </div>
                        </div>`,
			link: function (scope, element, attr) {
				var config = { params: {} };
				var issues_type = 'my_issues';
				scope.dataLoaded = false;
				scope.issues = [];

				scope.getIssuesWithType = function (issue_type) {
					issues_type = issue_type;
					scope.getIssues();
				}

				scope.getIssues = function () {
					var promise;
					scope.dataLoaded = false;
					config.params = {};

					config.params.status = 'active, new, in progress';

					if (issues_type == 'all_issues') {
						config.params.status = 'active, new, in progress';
						config.params.orderby = 'due_by';
						config.params.extended = 'true';
						promise = IssuesService.getIssues(config);
					}

					if (issues_type == 'my_issues') {
						promise = IssuesService.getMyIssues();
					}

					promise.then(function (success) {
						scope.issues = success.data;
					}, function (error) {
						console.log(error);
					}).finally(function () {
						scope.dataLoaded = true;
					});
				}

				scope.getIssues();
			}
		};
	})