(function() {
	
	window.ui = window.ui || {};
	
	var CleaningStrip = function (data, xVal, yVal, color) {
		
		this.data = data;
		this.xVal=xVal;		
		this.yVal=yVal;
		this.color=color;			
		this.initialize();
		
	}
	
	var c = CleaningStrip.prototype = new createjs.Container();
	
	c.data;	
	c.xVal;
	c.yVal;
	c.color;
	c.Container_initialize = c.initialize;
	
	c.initialize = function () {
		
		this.Container_initialize();
		this.createCleaningStrip();	
		this.setButtonListeners();
		this.x = this.xVal;
		this.y = this.yVal;
	}
	
	c.setButtonListeners = function (){
		this.cursor = 'pointer';		
	}
	
	c.createCleaningStrip = function(){	
		
		this.addShape();		
		
	}	
	
	c.addShape = function(){
		var back = new createjs.Shape();	
		var borderWidth = 3;
		if(this.data.type==='Service') borderWidth=5;							
							
		back.graphics.beginFill('#fff').drawRoundRect(0, 0, 21, 21, 1, 1, 1, 1);
		
		back.alpha = 0.5;
		var outline = new createjs.Shape();
		if(this.data.type==='Service') outline.graphics.setStrokeDash([2,2]);
		if(this.data.type==='Note') outline.graphics.setStrokeDash([3,1]);
		outline.graphics.setStrokeStyle(borderWidth).beginStroke(this.color).drawRoundRect(0, 0, 21, 21, 1, 1, 1, 1);
		outline.shadow = new createjs.Shadow("#000000", 3, 3, 10);
		this.addChild(back);
		this.addChild(outline);		
	}	

	
	window.ui.CleaningStrip = CleaningStrip;
	
}());