angular.module('schedulers.modals')

.factory("ModalSchedulerMultipleClean", [function(){
	 
	var modalSchedulerAddEditClean = {};          
	
	
	modalSchedulerAddEditClean.AddMultipleClean = function(data, apartments, permissions){ 
		return {
			animation: true,     
			template: 
			`<div class="modal-header">
				<h3 class="modal-title">{{title}}</h3>
			</div>
			<div class="modal-body alert">           
				<form name="cleaningForm">
					<div class="row">	
						<div class="col-sm-6">
							<div class="form-group" ng-class="{'has-error': cleaningForm.cleaning_date.$invalid && cleaningForm.cleaning_date.$touched}">       
								<label>Start Date</label>
								<p class="input-group">
									<input ng-model="data.start_date" type="text" class="form-control" uib-datepicker-popup="{{'dd-MMM-yyyy'}}" 
										   ng-change="getActiveApartments()"
										   datepicker-localdate
										   is-open="start_date_open" datepicker-options="{formatYear: 'yy', startingDay: 1}" 
										   ng-required="true" close-text="Close" alt-input-formats="altInputFormats" 
										   ng-focus="focus=false" ng-blur="focus=true" name="start_date"                                 
										   required/>
									<span class="input-group-btn">
										<button tabindex="-1" type="button" class="btn btn-default" ng-click="start_date_open = !start_date_open"><i class="glyphicon glyphicon-calendar"></i></button>
									</span>
								</p>
								<p ng-show="cleaningForm.start_date.$invalid && cleaningForm.start_date.$touched" class="help-block"> 
												Required
								</p>
							</div>
						</div>
						
						<div class="col-sm-6">
							<div class="form-group" ng-class="{'has-error': cleaningForm.end_date.$invalid && cleaningForm.end_date.$touched}">       
								<label>End Date</label>
								<p class="input-group">
									<input ng-model="data.end_date" type="text" class="form-control" uib-datepicker-popup="{{'dd-MMM-yyyy'}}" 
										   ng-change="getActiveApartments()"
										   datepicker-localdate
										   is-open="end_date_open" datepicker-options="{formatYear: 'yy', startingDay: 1}" 
										   ng-required="true" close-text="Close" alt-input-formats="altInputFormats" 
										   ng-focus="focus=false" ng-blur="focus=true" name="end_date"                                 
										   required/>
									<span class="input-group-btn">
										<button tabindex="-1" type="button" class="btn btn-default" ng-click="end_date_open = !end_date_open"><i class="glyphicon glyphicon-calendar"></i></button>
									</span>
								</p>
								<p ng-show="cleaningForm.end_date.$invalid && cleaningForm.end_date.$touched" class="help-block"> 
												Required
								</p>
							</div>
						</div>
						
					</div>
					
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" >       
								<label>Apartment</label>
								<select class="form-control"  ng-model="data.apartment_id" 
									ng-disabled="data.start_date == null  || data.end_date == null"
									ng-options="option.id as (option.address1 + ', ' + option.address2 + ', ' + option.type + ', ' + option.beds + ' bed') for option in apartments" required>
									 <option></option>
								</select>
								<p ng-show="cleaningForm.apartment_id.$invalid && cleaningForm.apartment_id.$touched" class="help-block">
									Required
								</p>
							</div>
						</div>		
						<div class="col-sm-6">
							<label class="form-group dropdown-wide"  ng-class="{'has-error': cleaningForm.type.$invalid && cleaningForm.status.$touched}">
								<label>Type</label>
								<select class="form-control" required name="type"  ng-model="data.type">
									<option value="{{type}}" ng-repeat="type in cleaning_types">{{type | capitalize}}</option>                    
								</select>
								<p ng-show="cleaningForm.type.$invalid && cleaningForm.type.$touched" class="help-block">
									Required
								</p>
							</label> 
						</div>
						
					</div>		
				</form>
			</div>
			<div class="modal-footer">    
				<div class="row">					
					<div class="col-sm-3">
						<my-save-button
							click-fn="saveRecord()"
							show-spinner="showSaveSpinner"												
							ng-disabled="cleaningForm.$invalid"
							ng-show="permissions['cleanings.create']"
						></my-save-button>
						
					</div>
					<div class="col-sm-6">
						<my-form-alert				
							show-alert="showAlert"
							alert-type="alertDetails.type"
							alert-message="alertDetails.message"											
						></my-form-alert>											
					</div>					 
					<div class="col-sm-3">
						<button class="btn btn-warning btn-lg" type="button" ng-click="cancel()">Cancel</button>
					</div>
				</div>
			</div>`,	
			resolve: {					
				title: function(){
					return "Multiple Cleans"
				},
				data: function(){
					return data;
				},
				apartments: function(){
					return apartments;
				},
				permissions: function(){
					return permissions;
				}
			},
			controller: function ($uibModalInstance, $scope, data, title, apartments, permissions,
							UtilBroadcastService, $timeout, ApartmentsService,  CleaningsService) {	
				
				var config = {};
				$scope.title = title;
				$scope.permissions = permissions;
				$scope.data = data;
				$scope.data.type = 'Service';
				$scope.alertDetails = {};
				$scope.showSaveSpinner = false;
				$scope.showAlert = false;
					
				
				$scope.cleaning_types = CleaningsService.getTypeForCleaning();  
				$scope.apartments = apartments;
				
				$scope.getActiveApartments = function(){	
					if($scope.data.start_date == null || $scope.data.end_date == null) return;
					config.params = {};	
					config = {	params: {  	
						status_by_dates: true, 
						status_start_date: $scope.data.start_date,
						status_end_date: $scope.data.end_date,
						orderby: 'apartments.address1', 
						dropdown: true	
					}  };
					ApartmentsService.getApartments(config).then(function(success){     		
						$scope.apartments = success.data;
					}, function(error){
						console.log(error);
					});
				}	
						  
				$scope.saveRecord = function () {
					$scope.showSaveSpinner = true;
					
					CleaningsService.postStoreMultiple($scope.data).then(function(success){						
						UtilBroadcastService.showAlert($scope, 'Success', 'Multiple cleans created.');						
					}, function(error){
						console.log('error');
						UtilBroadcastService.showAlert($scope, 'Failure', 'Unable to add cleans');
					}).finally(function(){
						$scope.showSaveSpinner = false;
						$timeout(function () {									
							$uibModalInstance.close('close');	
						}, 2000);				
					});
				}
				
				
				$scope.cancel = function() {
				   $uibModalInstance.dismiss();
				}	
				
				$scope.formatLabel = function(model) {					
					for (var i=0; i< $scope.apartments.length; i++) {
					 if (model === $scope.apartments[i].id) {
					   return $scope.apartments[i].address1;
					 }
				   }
				};
			}	
		}
	}		
	return modalSchedulerAddEditClean;
}])
