angular.module('invoices.controllers')

.controller('InvoiceSummaryDateToDateReportController', ['InvoicesService', '$timeout',
    function(InvoicesService, $timeout) {
        var self = this; 
		var start_month = 1;
		self.dataLoaded = false;
		self.showSpinner = false;		
		self.report_data = {'data': 'no'};
		self.dates = {};
		self.invoices = [];
				
		function defaultDates(){
			var now = moment(); 
			self.dates.end_date = now.format('YYYY-MM-DD');
			self.dates.start_date = moment().year(now.year()).month(now.month()-1).date(now.date()).format('YYYY-MM-DD');	
			InvoiceSummaryReport();
		}
	
		defaultDates();
		
		function InvoiceSummaryReport(){
			self.dataLoaded = false;
			InvoicesService.postInvoiceSummaryReport(self.dates).then(function(success){
				self.invoices = success.data;
			}, function(error){
				console.log(error);
			}).finally(function() {
				self.dataLoaded = true;
			});
		}
		
		self.refreshData = function(){			
			InvoiceSummaryReport();			
		}		
		
		
		self.print = function(){
			self.showSpinner = true;		
			self.report_data= {
                    "template": { "shortid" : "4kMxl_ypl" },
                    "title": "Invoice Summary",
                    "data": {
                        invoices: self.invoices
                    }
               }; 
			$timeout(function() {
				self.showSpinner = false;				
			}, 3000);
		}
		
		
		
	}
])