angular.module('schedulers.modals')

.factory("ModalSchedulerDeleteClean", [function(){
	 
	var modalSchedulerDeleteClean = {};          
	
	
	modalSchedulerDeleteClean.DeleteMultipleCleans = function(permissions){ 
		return {
			animation: true,     
			template: 
			`<div class="modal-header">
				<h3 class="modal-title">{{title}}</h3>
			</div>
			<div class="modal-body alert">           
				<form name="cleaningForm">
					<div class="row">	
						<div class="col-sm-6">
							<div class="form-group" ng-class="{'has-error': cleaningForm.cleaning_date.$invalid && cleaningForm.cleaning_date.$touched}">       
								<label>Start Date</label>
								<p class="input-group">
									<input ng-model="data.start_date" type="text" class="form-control" uib-datepicker-popup="{{'dd-MMM-yyyy'}}" 
										   datepicker-localdate
										   is-open="start_date_open" datepicker-options="{formatYear: 'yy', startingDay: 1}" 
										   ng-required="true" close-text="Close" alt-input-formats="altInputFormats" 
										   ng-focus="focus=false" ng-blur="focus=true" name="start_date"                                 
										   required/>
									<span class="input-group-btn">
										<button tabindex="-1" type="button" class="btn btn-default" ng-click="start_date_open = !start_date_open"><i class="glyphicon glyphicon-calendar"></i></button>
									</span>
								</p>
								<p ng-show="cleaningForm.start_date.$invalid && cleaningForm.start_date.$touched" class="help-block"> 
												Required
								</p>
							</div>
						</div>
						
						<div class="col-sm-6">
							<div class="form-group" ng-class="{'has-error': cleaningForm.end_date.$invalid && cleaningForm.end_date.$touched}">       
								<label>End Date</label>
								<p class="input-group">
									<input ng-model="data.end_date" type="text" class="form-control" uib-datepicker-popup="{{'dd-MMM-yyyy'}}" 
										   datepicker-localdate
										   is-open="end_date_open" datepicker-options="{formatYear: 'yy', startingDay: 1}" 
										   ng-required="true" close-text="Close" alt-input-formats="altInputFormats" 
										   ng-focus="focus=false" ng-blur="focus=true" name="end_date"                                 
										   required/>
									<span class="input-group-btn">
										<button tabindex="-1" type="button" class="btn btn-default" ng-click="end_date_open = !end_date_open"><i class="glyphicon glyphicon-calendar"></i></button>
									</span>
								</p>
								<p ng-show="cleaningForm.end_date.$invalid && cleaningForm.end_date.$touched" class="help-block"> 
												Required
								</p>
							</div>
						</div>
						
					</div>
					
					<div class="row">
						
						<div class="col-md-6">
							<div class="form-group" ng-class="{'has-error': cleaningForm.check_out_date.$invalid && cleaningForm.check_out_date.$touched}">       
								<label>Apartment</label>
								<select class="form-control"  ng-model="data.apartment_id"                  
									ng-options="apartment.id as apartment.address1 for apartment in apartments" required>
									 <option></option>
								</select>
								<p ng-show="cleaningForm.apartment_id.$invalid && cleaningForm.apartment_id.$touched" class="help-block">
									Required
								</p>
							</div>
						</div>
						<div class="col-sm-6">
							
						</div>
						
					</div>		
				</form>
			</div>
			<div class="modal-footer">    
				<div class="row">					
					<div class="col-sm-3">
						<my-save-button
							click-fn="deleteCleanings()"
							show-spinner="showSaveSpinner"												
							ng-disabled="cleaningForm.$invalid"
							ng-show="permissions['cleanings.delete']"
						></my-save-button>
						
					</div>
					<div class="col-sm-6">
						<my-form-alert				
							show-alert="showAlert"
							alert-type="alertDetails.type"
							alert-message="alertDetails.message"											
						></my-form-alert>											
					</div>					 
					<div class="col-sm-3">
						<button class="btn btn-warning btn-lg" type="button" ng-click="cancel()">Cancel</button>
					</div>
				</div>
			</div>`,	
			resolve: {					
				title: function(){
					return "Delete Multiple Cleans"
				},
				permissions: function(){
					return permissions;
				}
			},
			controller: function ($uibModalInstance, $scope, title, permissions,
							UtilBroadcastService, $timeout, ApartmentsService,  CleaningsService) {	
				var config = {};
				$scope.title = title;
				$scope.permissions = permissions;				
				$scope.alertDetails = {};
				$scope.showSaveSpinner = false;
				$scope.showAlert = false;
				
				function getApartments(){				
					config.params = {};	
					config = {	params: {  							
						orderby: 'apartments.address1', 
						dropdown: true	
					}  };
					ApartmentsService.getApartments(config).then(function(success){     		
						$scope.apartments = success.data;
					}, function(error){
						console.log(error);
					});
				}	
				
				getApartments();
						  
				$scope.deleteCleanings = function () {
					$scope.showSaveSpinner = true;
					CleaningsService.postDeleteMultiple($scope.data).then(function(success){
						UtilBroadcastService.showAlert($scope, 'Success', 'Cleans deleted.');
						$scope.data = {};				
					}, function(error){
						console.log('error');
						UtilBroadcastService.showAlert($scope, 'Failure', 'Unable to delete cleans.');
					}).finally(function(){
						$scope.showSaveSpinner = false;
					});
				}				
				
				$scope.cancel = function() {
				   $uibModalInstance.dismiss();
				}				
			}	
		}
	}		
	return modalSchedulerDeleteClean;
}])
