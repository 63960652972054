angular
    .module("invoices.directives")

    .directive(
        "invoicePayments",
        function ($rootScope, $state, InvoicesService, ModalConfigurationService, $uibModal, ModalFeedback, ModalConfirmAction, $timeout) {
            return {
                restrict: "EAC",
                replace: true,
                scope: {
                    invoiceId: "=",
                    showAlert: "=",
                    permissions: "=",
                    amountDue: "=",
                },
                template: `<div>
							<div ng-show="!dataLoaded" class="row text-center">
								<span ><i class="fa fa-cog fa-spin" style="font-size:48px"></i></span>
							</div>
							<div ng-show="dataLoaded">
								<table class="table"  >
										<th>Date</th>										
                                        <th>Amount</th>		
                                        <th>Type</th>								
                                        <th>Meta</th>
										<th colspan="2" ng-if="permissions['invoices.show']" style="width: 10%"><div class="text-center">Action</div></th>
									<tr ng-repeat="item in invoice_payments">                               
										<td>{{item.date_paid | date:'mediumDate'}}</td>
                                        <td>{{item.amount_paid | number:2}}</td>
                                        <td>{{item.type | capitalize | underscoreless }} {{invoice.credit_note_id}}</td>
                                        <td ng-if="item.credit_note_id === null">{{item.meta}}</td>   
                                        <td ng-if="item.credit_note_id !== null"><a href="" ng-click="goToCreditNote(item.credit_note_id)" >{{item.credit_note_number}}</a></td>                                        
										<td><my-sub-form-button click-fn="showInvoicePayment(item)" 
											ng-if="permissions['invoices.show']" 
											button-type="edit"></my-sub-form-button></td>
										<td><my-sub-form-button 
											ng-if="permissions['invoices.delete']" 
											click-fn="deleteInvoicePayment(item)" 
											button-type="delete"></my-sub-form-button>
										</td>   										
									</tr>                            
								</table>
								<div class="pull-right">
									<my-sub-form-button ng-if="permissions['invoices.create']"  
												click-fn="createInvoicePayment()" 
												button-type="add"></my-sub-form-button>
								</div>
							</div>
						</div>`,
                link: function (scope, element, attr) {
                    var invoicePaymentData;
                    scope.dataLoaded = false;
                    scope.invoice_payments = [];

                    scope.goToCreditNote = function (id) {
                        $state.go("main.credit-note", { id: id }, {});
                    };

                    function updateAlert(value) {
                        setTimeout(function () {
                            scope.$apply(function () {
                                scope.showAlert = value;
                            });
                        }, 500);
                    }

                    function getInvoicePayments() {
                        scope.dataLoaded = false;
                        InvoicesService.getInvoicePaymentByInvoice(scope.invoiceId)
                            .then(
                                function (success) {
                                    scope.invoice_payments = success.data;
                                    updateAlert(scope.invoice_payments.length == 0);
                                },
                                function (error) {
                                    console.log(error);
                                }
                            )
                            .finally(function () {
                                scope.dataLoaded = true;
                            });
                    }

                    getInvoicePayments();

                    function showPayment() {
                        var modalInstance = ModalConfigurationService.getInvoicePayment(invoicePaymentData, scope.permissions, scope.amountDue);

                        modalInstance.result.then(function (data) {
                            getInvoicePayments();
                            broadcastUpdate();
                        });
                    }

                    scope.showInvoicePayment = function (item) {
                        invoicePaymentData = item;
                        showPayment();
                    };

                    scope.createInvoicePayment = function () {
                        invoicePaymentData = { id: "new", invoice_id: scope.invoiceId, paid_to: "Metro business account" };
                        showPayment();
                    };

                    scope.deleteInvoicePayment = function (item) {
                        var modalInstance = $uibModal.open(
                            ModalConfirmAction.confirmAction(
                                "Warning!",
                                "Are you sure you would like to delete this record? Deletions cannot be undone.",
                                "alert-danger"
                            )
                        );

                        modalInstance.result.then(function (data) {
                            if (data == "confirmed") {
                                InvoicesService.getInvoicePaymentDelete(item.id).then(
                                    function (success) {
                                        var modalInstance1 = $uibModal.open(
                                            ModalFeedback.showFeedback("Success", "Record deleted!", "alert-success")
                                        );
                                        $timeout(function () {
                                            modalInstance1.close("cancel");
                                        }, 2000);
                                        broadcastUpdate();
                                    },
                                    function (error) {
                                        var modalInstance1 = $uibModal.open(
                                            ModalFeedback.showFeedback(
                                                "Error!",
                                                "Unable to delete record. Please contact a system administrator.",
                                                "alert-danger"
                                            )
                                        );
                                        $timeout(function () {
                                            modalInstance1.close("cancel");
                                        }, 2000);
                                    }
                                );
                            }
                        });
                    };

                    function broadcastUpdate() {
                        console.log("broadcasting update");
                        $rootScope.$broadcast("updateInvoice", {
                            data: true,
                        });
                    }

                    scope.$on("updateSidebars", function (event, result) {
                        getInvoicePayments();
                    });

                    scope.$watch("invoiceId", function (newVal, oldVal) {
                        if (newVal != oldVal) {
                            getInvoicePayments();
                        }
                    });
                },
            };
        }
    );
