angular.module('reports.details', [])

.factory('REPORT_DETAILS',  [function(){
	
    var reportBookingSummary = function(){
        return [
				{
					title: "Booking Status",
					description: "Only Active Bookings are used in the calculations."
				},
				{
					title: "Booking Types",
					description: "Held and Maintenance Bookings are not used in the calculations."
				},
				{
					title: "Time Period",
					description: "No of nights in time period. 01/03 to 15/03 = 14 nights."
				},
				{
					title: "No of apartments",
					description: "Total no of apartments active during the time period."
				},
				{
					title: "Total Days Available",
					description: "The days available for each individual apartment is calculated depending on active and inactive dates. For example, there may be a time period of 90 days but an apartment is made inactive 28 days prior to this. This apartment will then have been available for 62 (90-28) days. The days available for all apartments are then aggregated."
				},
				{
					title: "Total Days Booked (Count)",
					description: "Represents an aggregation of all days booked per apartment per booking (for all active bookings)."
				},
				{
					title: "Total Days Booked (%)",
					description: "Calculated by dividing Total Days Booked by Total Days Available."
				},
				{
					title: "Total Income",
					description: "The charge per night figure is taken for the booking and multiplied by the number of days for that booking. These figures are then aggregated to provide the total income."
				},
				{
					title: "Total Rent",
					description: "Rent for the period is calculated by taking the average of all rents from associated apartment contracts, dividing that figure by 31 (this provides the daily rental rate) and then multiplying that figure by the number of days the apartment is available."
				},
				{
					title: "Total Profit",
					description: "Total Income - Total Rent."
				},
				{
					title: "GP",
					description: "Total Profit / Total Income."
				}
            ];
    }
	
	var reportEnquirySummary = function(){
        return [
				{
					title: "Dates",
					description: "Any enquiries created between given dates (inclusive) will be shown."
				}
				
            ];
    }
    
	var organisationFigures = function(){
        return [
				{
					title: "Last Year",
					description: "Every active booking where the booking date falls within the last year (today minus 1 year)."
				}
				
            ];
    }
	
	var reportInvoicesUnpaidByClient = function(){
        return [
				{
					title: "When does an invoice become overdue?",
					description: "An invoice becomes overdue if it has not been paid by the Due Date."
				},
				{
					title: "How are overdue dates calculated?",
					description: "All overdue dates are calculated from the Due Date of an invoice."
				},
				{
					title: "Example:",
					description: "Invoice date: 01/03/2015 Due Date: 01/04/2015 Current Date: 15/04/2015 - The invoice is 15 days overdue."
				}
				
            ];
    }
	
	var reportInvoicesUnpaid = function(){
        return [				
				{
					title: "How are overdue dates calculated?",
					description: "All overdue dates are calculated from the Due Date of an invoice."
				}
				
            ];
    }
   
  
    return {
        reportBookingSummary: reportBookingSummary,
		reportEnquirySummary: reportEnquirySummary,
		organisationFigures: organisationFigures,
		reportInvoicesUnpaidByClient: reportInvoicesUnpaidByClient,
		reportInvoicesUnpaid: reportInvoicesUnpaid
       
    };
}])