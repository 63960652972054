angular
    .module("invoices.controllers")

    .controller(
        "ModalInvoicePaymentsController",
        function (
            $scope,
            $uibModalInstance,
            data,
            title,
            amount_due,
            permissions,
            InvoicesService,
            CreditNotesService,
            UtilBroadcastService,
            $timeout
        ) {
            $scope.title = title;
            $scope.data = data;
            $scope.data.type = null;
            $scope.amount_due = amount_due;
            $scope.showSaveSpinner = false;
            $scope.showAlert = false;
            $scope.alertDetails = {};
            $scope.permissions = permissions;
            $scope.paid_to_options = InvoicesService.getBankAccountsForInvoices();
            $scope.paymentTypes = InvoicesService.getPaymentTypes();
            $scope.credit_notes = [];

            $scope.max_payment_amount = amount_due;

            function getCreditNotes() {
                const cnConfig = {
                    params: {
                        orderby: "credit_notes.id",
                        sort: "DESC",
                        allocated: false
                    },
                };

                CreditNotesService.getCreditNotes(cnConfig).then(
                    function (success) {
                        $scope.credit_notes = success.data;
                    },
                    function (error) {
                        console.log(error);
                    }
                );
            }

            getCreditNotes();

            $scope.saveRecord = function () {
                /* validation */
                if ($scope.data.amount_paid * 1 > $scope.amount_due * 1) {
                    UtilBroadcastService.showAlert($scope, "Failure", "Please ensure that payment is less than or equal to amount due.");
                    return;
                }

                $scope.showSaveSpinner = true;

                var promise;
                var message;
                if ($scope.data.id === "new") {
                    promise = InvoicesService.postInvoicePaymentStore($scope.data);
                    message = "Record created";
                } else {
                    return;
                }

                promise
                    .then(
                        function (success) {
                            $scope.invoicePaymentsForm.$setPristine();
                            UtilBroadcastService.showAlert($scope, "Success", message, false);
                            if ($scope.data.id === "new") $scope.data.id = success.data;
                        },
                        function (error) {
                            console.log(error);
                            UtilBroadcastService.showAlert($scope, "Failure", "Unable to create/update record.");
                        }
                    )
                    .finally(function () {
                        UtilBroadcastService.broadcastUpdate();
                        $timeout(function () {
                            $uibModalInstance.close("close");
                        }, 2000);
                    });
            };

            $scope.cancel = function () {
                $uibModalInstance.dismiss();
            };

            $scope.$watch(function () {
                    return data.credit_note_id;
                }, function (newVal, oldVal) {
                if (newVal != oldVal) {
                    if( data.credit_note_id > 0 ) {
                        $timeout(function () {
                            data.meta = "CREDIT NOTE";
                            $scope.max_payment_amount = Math.min(
                                amount_due,
                                $scope.credit_notes.find((x) => parseInt(x.id) === parseInt(data.credit_note_id)).remaining_credit
                            );
                        });
                    }
                }
            });
        }
    );
