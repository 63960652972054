angular
    .module("invoices.services", [])

    .factory("InvoicesService", [
        "$http",
        "$q",
        function ($http, $q) {
            var invoicesService = {};

            invoicesService.getInvoices = function (config) {
                return $http.get("api/invoice", config == null ? [] : config);
            };

            invoicesService.getInvoicesPaginate = function (config) {
                return $http.get("api/invoice/paginate", config == null ? [] : config);
            };

            invoicesService.getUnpaid = function (config) {
                return $http.get("api/invoice/unpaid", typeof config !== "undefined" ? config : []);
            };

            invoicesService.getInvoice = function (id) {
                return $http.get("api/invoice/show/" + id);
            };

            invoicesService.postUpdate = function (id, invoice) {
                return $http.post("api/invoice/update/" + id, invoice);
            };

            invoicesService.getWithDetails = function (id) {
                return $http.get("api/invoice/with-details/" + id);
            };

            invoicesService.postUpdateWithDetails = function (id, invoice) {
                return $http.post("api/invoice/update-with-details/" + id, invoice);
            };

            invoicesService.postStore = function (invoice) {
                return $http.post("api/invoice/store", invoice);
            };

            invoicesService.postStoreWithDetails = function (invoice) {
                return $http.post("api/invoice/store-with-details", invoice);
            };

            invoicesService.getDelete = function (id) {
                return $http.get("api/invoice/destroy/" + id);
            };

            invoicesService.getStatusForInvoices = function () {
                return ["new", "sent", "paid", "cancelled", "overdue"];
            };

            invoicesService.getTypesForInvoices = function () {
                return ["standard", "other"];
            };

            invoicesService.getAccountTypes = function () {
                return ["200-Sales", "311-Commission"];
            };

            invoicesService.getInvoiceDetailTypes = function () {
                return ["rate_per_night", "extra"];
            };

            /*New Invoice Helpers*/
            invoicesService.getLastInvoiceNo = function () {
                return $http.get("api/invoice/last-invoice-no");
            };

            invoicesService.getLastInvoiceEndDateByBooking = function (booking_id) {
                return $http.get("api/invoice/last-invoice-end-date-by-booking/" + booking_id);
            };

            /*Non Invoice*/
            invoicesService.getBookings = function () {
                return $http.get("api/booking/for-dropdown");
            };

            invoicesService.getOrganisations = function () {
                return $http.get("api/organisation/for-dropdown");
            };

            /*Invoice Details*/

            invoicesService.getInvoiceDetailByInvoice = function (id) {
                return $http.get("api/invoice-details/by-invoice/" + id);
            };

            invoicesService.postInvoiceDetailUpdate = function (id, data) {
                return $http.post("api/invoice-details/update/" + id, data);
            };

            invoicesService.postInvoiceDetailStore = function (data) {
                return $http.post("api/invoice-details/store", data);
            };

            invoicesService.getInvoiceDetailDelete = function (id) {
                return $http.get("api/invoice-details/destroy/" + id);
            };

            invoicesService.getAddCommission = function (id) {
                return $http.get("api/invoice/add-commission/" + id);
            };

            /*Invoice Payments*/

            invoicesService.getInvoicePaymentByInvoice = function (id) {
                return $http.get("api/invoice-payments/by-invoice/" + id);
            };

            invoicesService.postInvoicePaymentStore = function (data) {
                return $http.post("api/invoice-payments/store", data);
            };

            invoicesService.getInvoicePaymentDelete = function (id) {
                return $http.get("api/invoice-payments/destroy/" + id);
            };

            invoicesService.getBankAccountsForInvoices = function () {
                return ["Business Bank Account", "Commission Invoice"];
            };

            invoicesService.getPaymentTypes = function () {
                return ["bank_transfer", "credit_card", "cash", "commission", "credit_note"];
            };

            /*
	invoicesService.getPaymentAmountByInvoice = function(id){            
		return $http.get('api/invoice-payments/payment-amount-by-invoice/'+id);
	}
	*/
            /*Xero*/

            invoicesService.getXeroInvoice = function (id) {
                return $http.get("api/invoice/xero-invoice/" + id);
            };

            invoicesService.getSentInvoiceToXero = function (id) {
                return $http.get("api/invoice/send-invoice-to-xero/" + id);
            };

            invoicesService.getXeroInvoicePaidAmount = function (id) {
                return $http.get("api/invoice/xero-invoice-paid-amount/" + id);
            };

            /* Reports */

            invoicesService.getStandardInvoiceReport = function (id, config) {
                //return $http.get('api/invoice/standard-invoice-report/'+id, config == null ? [] : config);
                var defer = $q.defer();
                $http({
                    url: "api/invoice/standard-invoice-report/" + id,
                    method: "GET",
                    responseType: "arraybuffer",
                    params: config.params,
                })
                    .success(function (data, status, headers, config) {
                        var fileBlob = new Blob([data], {
                            type: "application/pdf",
                        });
                        var fileUrl = URL.createObjectURL(fileBlob);
                        defer.resolve(fileUrl);
                    })
                    .error(function (data, status, headers, config) {
                        defer.resolve(data);
                    });

                return defer.promise;
            };

            invoicesService.getStandardInvoiceReportForWord = function (id, data, params) {
                var defer = $q.defer();
                $http({
                    url: "api/invoice/word-report/" + id,
                    method: "POST",
                    responseType: "arraybuffer",
                    params: params,
                    data: data,
                })
                    .success(function (data, status, headers, config) {
                        var fileBlob = new Blob([data], {
                            type: "application/docx",
                        });
                        var fileUrl = URL.createObjectURL(fileBlob);
                        defer.resolve(fileUrl);
                    })
                    .error(function (data, status, headers, config) {
                        defer.resolve(data);
                    });

                return defer.promise;
            };

            invoicesService.getInvoiceOverdueReport = function (config) {
                return $http.get("api/invoice/invoice-overdue-report", config);
            };

            invoicesService.getInvoiceOverdueReportPDF = function (config) {
                var defer = $q.defer();
                $http({
                    url: "api/invoice/invoice-overdue-report-pdf",
                    method: "GET",
                    responseType: "arraybuffer",
                    params: config.params,
                })
                    .success(function (data, status, headers, config) {
                        var fileBlob = new Blob([data], {
                            type: "application/pdf",
                        });
                        var fileUrl = URL.createObjectURL(fileBlob);
                        defer.resolve(fileUrl);
                    })
                    .error(function (data, status, headers, config) {
                        defer.resolve(data);
                    });

                return defer.promise;
            };

            invoicesService.postInvoiceSummaryReport = function (dates) {
                return $http.post("api/invoice/invoice-summary-report", dates);
            };

            invoicesService.postInvoiceDetailedUnpaidReport = function (dates) {
                return $http.post("api/invoice/invoice-detailed-unpaid-report", dates);
            };

            invoicesService.postInvoiceSummaryUnpaidReport = function (dates) {
                return $http.post("api/invoice/invoice-summary-unpaid-report", dates);
            };

            invoicesService.postInvoiceSummaryUnpaidByClientReport = function (config, query) {
                if (config == null) config = [];
                return $http.post("api/invoice/invoice-summary-unpaid-by-client-report", query, config);
            };

            invoicesService.postInvoiceScheduleReport = function (dates) {
                return $http.post("api/invoice/invoice-schedule-report", dates);
            };

            invoicesService.getEmailInvoice = function (id, config) {
                return $http.get("api/invoice/email-invoice/" + id, config == null ? [] : config);
            };

            invoicesService.postInvoiceDetailedReportExcel = function (dates) {
                //return $http.post('api/invoice/invoice-detailed-report-excel', dates);

                var defer = $q.defer();
                $http({
                    url: "api/invoice/invoice-detailed-report-excel",
                    method: "POST",
                    responseType: "arraybuffer",
                    data: dates,
                })
                    .success(function (data, status, headers, config) {
                        var fileBlob = new Blob([data], {
                            type: "application/xlsx",
                        });
                        var fileUrl = URL.createObjectURL(fileBlob);
                        defer.resolve(fileUrl);
                    })
                    .error(function (data, status, headers, config) {
                        defer.resolve(data);
                    });

                return defer.promise;
            };

            invoicesService.postInvoiceDetailedReportDateToDate = function (dates) {
                return $http.post("api/invoice/invoice-detailed-report-date-to-date", dates);
            };

            /*
             *             Invoice Attachment
             */

            invoicesService.getAttachmentTypes = function () {
                return [ "other" ];
            };

            invoicesService.getInvoiceAttachmentByInvoice = function (id) {
                return $http.get("api/invoice-attachments/by-invoice/" + id);
            };

            invoicesService.postInvoiceAttachmentUpdate = function (id, data) {
                return $http.post("api/invoice-attachments/update/" + id, data);
            };

            invoicesService.postInvoiceAttachmentStore = function (data) {
                return $http.post("api/invoice-attachments/store", data);
            };

            invoicesService.getInvoiceAttachmentDelete = function (id) {
                return $http.get("api/invoice-attachments/destroy/" + id);
            };

            return invoicesService;
        },
    ]);
