angular.module('versions.controllers', [])

.controller('VersionsController', ['$state', 'VersionsService', '$scope', '$filter', 'PermissionsService', 
        function($state, VersionsService, $scope, $filter, PermissionsService) {
    var self = this;    
   
    self.dataLoaded = false;
	self.versions = [];
	self.current_version = {};
    self.versions.current_page = 1;        
    self.maxSize = 5;
    self.search_value;
    self.sortASC = true;
    self.permissions = [];
    
    var config = {
        params: {   
            orderby: 'versions.id',
            sort: 'DESC',
            page: self.versions.current_page,               
            per_page: 20
        }                
    };
    
    PermissionsService.getVersion().then(function(success){            
        self.permissions = success.data;
    }, function(error){
        console.log(error);
    });      
    
    function getVersions(){
        self.dataLoaded = false;

        VersionsService.getVersionsPaginate(config).then(function(success){         
            self.versions = success.data;           
        }, function(error){
            console.log(error);
        }).finally(function(){
            self.dataLoaded = true;
        });
    }
    
    getVersions();
    
    function getCurrentVersion(){        
        VersionsService.getCurrentVersion().then(function(success){         
            self.current_version = success.data;        
        }, function(error){
            console.log(error);
        }); 
    }
    
	getCurrentVersion();
	
	
             
    self.go = function(id){
        $state.go('main.version', {'id': id}, {reload: true});
    }
   
    self.pagination = function(){           
        config.params.page = self.versions.current_page;
        getVersions();
    }
    
    self.sortTable = function(heading){
        config.params.orderby = heading;
        config.params.sort = (self.sortASC) ? 'ASC' : 'DESC';   
        self.sortASC = !self.sortASC;
        getVersions();
       
    }
    
    self.search = function(){
        self.versions.current_page = 1;
        config.params.page = self.versions.current_page;
        config.params.search = self.search_value;
        getVersions();
    }
        
    
}])

