angular.module('form-controls.directives')

	.directive('areaDropdown', function (AreasService, $uibModal, ModalAddArea) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				areaId: '=ngModel',
				formName: '=',
				isNew: '=',
				isDisabled: '=',
				showAddButton: '=',
				title: '@'
			},    
			require: 'ngModel',
			template:	`<div class="form-group" ng-class="{'has-error': formName.area_id.$invalid && formName.area_id.$touched}">
							<label>{{title}}</label>
							<div ng-class="{'input-group': showAddButton && !isDisabled }">	
								<select class="form-control" name="area_id" ng-disabled="isDisabled"									
									ng-model="areaId" 
									ng-options="option.id as option.area for option in areas" required>
								</select>
								<span class="input-group-btn" ng-show="showAddButton && !isDisabled">
									<button type="button" ng-disabled="isDisabled" class="btn btn-success" ng-click="addArea()"><i class="fa fa-plus"></i></button>
								</span>		
							</div>
							<p ng-show="formName.area_id.$invalid && formName.area_id.$touched" 
								class="help-block">
								Required
							</p>							
											
						</div>`,
			link: function (scope, element, attr) {				
				var config={};
				var newId = -1;
				scope.areas;				
				
				function setDefaults(){					
					if (angular.isUndefined(scope.showAddButton)) scope.showAddButton = false;
					if (angular.isUndefined(scope.isDisabled)) scope.isDisabled = true;
				}
				
				setDefaults();
								
				function getAreas(){
					
					config.params={};
					config.params.orderby = 'areas.area';
					config.params.dropdown = true;
					if(scope.isNew){
						config.params.findby = 'status';
						config.params.findbyvalue = 'active';
					}
					
					AreasService.getAreas(config).then(function(success){    				
						scope.areas = success.data;						
					}, function(error){
						console.log(error);
					}).finally(function(){
						if(newId>0) scope.areaId = newId;
						newId = -1;
					});  				
				}
				
				getAreas();
				
				scope.addArea= function(){
					
					var modalInstance = $uibModal.open( ModalAddArea.addArea() );
					
					modalInstance.result.then(function (data) {
						newId = data;
						getAreas();				
						modalInstance.close('cancel');                  
					});
				}
				
			}
		};
	})
