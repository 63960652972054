angular.module('areas.services', [])

.factory("AreasService", ['$http',
	function($http){
	 
	var areasService = {};          
	
	
	areasService.getAreas = function(config){ 
		if(config==null) config=[];		
		return $http.get('api/area', config);
	}
	
	areasService.getAreasPaginate = function(config){ 
		if(config==null) config=[];		
		return $http.get('api/area/paginate', config);
	}
	
	areasService.getArea = function(id){            
		return $http.get('api/area/show/'+id);
	}
	
	areasService.postUpdate = function(id, area){            
		return $http.post('api/area/update/'+id, area);
	}
	
	areasService.postStore = function(area){            
		return $http.post('api/area/store', area);
	}     
	
	areasService.getDelete = function(id){            
		return $http.get('api/area/destroy/'+id);
	}
	
   
			
	return areasService;
}])