angular.module('issues.directives')

	.directive('issueSubcontractor', function ($rootScope, IssuesService, OrganisationsService, 
				$uibModal, ModalConfirmAction, ModalFeedback, $state, $timeout) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				issueId: '=',					
				permissions: '='
			},      
			template:	`<div>
							<div ng-show="!dataLoaded" class="row text-center">
								<span ><i class="fa fa-cog fa-spin" style="font-size:48px"></i></span>
							</div>
							<div ng-show="dataLoaded">
								<table class="table"  >
										<th>Subcontractor</th>						
										<th>Created At</th>  	
										<th>Delete</th>
									<tr ng-repeat="item in issue_subcontractors">                               
										<td><a href="" ng-click="goToOrganisation(item.organisation_id)">{{item.organisation_name}}</a></td>										
										<td>{{item.created_at}}</td>      
										<td><button ng-click="deleteRecord(item.id)" class="btn btn-sm btn-outline"><i class="fa fa-trash"></i></button></td>
									</tr>																	
								</table>								
								<p ng-show="permissions['issues.create']" >									
									<organisation-dropdown
										ng-model="organisationId" 
										form-name="na" 
										is-new="true"
										show-add-button="false"
										is-disabled="!permissions['issues.create']"
										type="subcontractor"
										title="Subcontractors">
									</organisation-dropdown>						
								</p>  							
							</div>
						</div>`,
			link: function (scope, element, attr) {
				var config={};		
				
				scope.dataLoaded = false;
				scope.issue_subcontractors = [];
				scope.organisations = [];
				scope.issue_subcontractor = {};
				scope.showSpinner = 0;		
				scope.savingRecord = false;
			
				
				function getIssueSubcontractors(){
					scope.dataLoaded = false;
					config.params={};					
					config.params.findby = 'issues_subcontractors.issue_id';
					config.params.findbyvalue = scope.issueId;
					config.params.orderby = 'organisations.organisation_name';
					IssuesService.getIssueSubcontractors(config).then(function(success){
						scope.issue_subcontractors = success.data;
					}, function(error){
						console.log(error);
					}).finally(function(){
						scope.dataLoaded = true;
					});
				}

				getIssueSubcontractors();
				
				function broadcastUpdate(){
					console.log('broadcasting update');
					$rootScope.$broadcast('updateSidebars', {
						data: true
					});
				}
				
				scope.$on('updateSidebars', function (event, result) {					
					getIssueSubcontractors();
				});
				
				
				scope.goToOrganisation = function(id){
					$state.go('main.subcontractor', {'id': id}, {});
				}
				
				function getOrganisations(){
					config.params={};
					config.params.orderby = 'organisations.organisation_name';
					config.params.sort = 'ASC';
					config.params.dropdown = true;
					config.params.findby = 'type';
					config.params.findbyvalue = 'subcontractor';
					config.params.status = 'active';
					
					OrganisationsService.getOrganisations(config).then(function(success){     
						scope.organisations = success.data;            
					}, function(error){
						console.log(error);
					});
				}
				
				getOrganisations();
				
				function addSubcontractor(){
					scope.savingRecord = true;
					var obj = {};
					obj = {issue_id: scope.issueId, organisation_id: scope.organisationId};
					
					IssuesService.postIssueSubcontractorsStore(obj).then(function(success){
						scope.organisationId = null;
					}, function(error){
						console.log(error);
					}).finally(function(){
						getIssueSubcontractors();
						getOrganisations();
						scope.savingRecord = false;
					});
				}
				
				scope.deleteRecord = function(id){
					
					var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
						'Warning!', 
						'Are you sure you would like to delete this record? Deletions cannot be undone.', 
						'alert-danger')
					);
					
					modalInstance.result.then(function(data){  						
						IssuesService.getIssueSubcontractorsDestroy(id).then(function(success){
								var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Record deleted!', 'alert-success'));						
							$timeout(function() { modalInstance1.close('cancel'); }, 2000);
							broadcastUpdate();	
						}, function(error){
							console.log(error);
							var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
										'Unable to delete record. Please contact a system administrator.', 'alert-danger'));			
							$timeout(function() { modalInstance1.close('cancel'); }, 2000);
						}).finally(function(){
							getIssueSubcontractors();			
						});								
						
					}, function(error){
						console.log(error);                        
					});			
					
					modalInstance.close('cancel');
				}
				
				scope.$watch('organisationId', function(newVal, oldVal){
					if(oldVal != newVal && scope.organisationId != null) addSubcontractor();
				});
			}
		};
	})