angular.module('invoices.controllers')

.controller('InvoiceSummaryUnpaidClientReportController', ['$scope', 'InvoicesService', '$filter', 'SettingsService', '$timeout', '$state', 
				'OrganisationsService', 'REPORT_DETAILS',
    function($scope, InvoicesService, $filter, SettingsService, $timeout, $state, OrganisationsService, REPORT_DETAILS) {
        var self = this; 
		var start_month = 1;
		self.dataLoaded = true;
		self.showSpinner = false;
		self.report_data = {'data': 'no'};
		self.sortASC = true;
		self.dates = {};
		self.query = {};
		self.invoices = [];
		self.organisations=[];
		self.report_details = REPORT_DETAILS.reportInvoicesUnpaidByClient();
		self.organisation_id;
		var configOrgs = {};
		var configInvoices = {};
		var currentSortField = '';
		
		console.log('InvoiceSummaryUnpaidClientReportController');
		
		configOrgs.params = {orderby: 'organisations.organisation_name', dropdown: true};		
		configInvoices.params = {orderby: 'invoices.invoice_number', sort: 'ASC'};		
		
		currentSortField = 'invoices.invoice_number';
				
		function defaultDates(){
			var now = moment(); 
			self.query.end_date = now.format('YYYY-MM-DD');
			self.query.start_date = moment().year(now.year()).month(now.month()-6).date(now.date()).format('YYYY-MM-DD');				
		}
	
		defaultDates();
		
		function getOrganisations(){
			
			OrganisationsService.getOrganisations(configOrgs).then(function(success){
				self.organisations = success.data;            
			}, function(error){
				console.log(error);
			});
		}
		
		getOrganisations();
				
		function InvoiceUnpaidSummaryByClientReport1(){
			console.log('InvoiceUnpaidSummaryByClientReport');
			self.query.organisation_id =  self.organisation_id;			
			if(self.query.start_date == false || self.query.end_date == false || self.query.organisation_id == false) return;
			self.dataLoaded = false;	
			
			InvoicesService.postInvoiceSummaryUnpaidByClientReport(configInvoices, self.query).then(function(success){
				self.invoices = success.data;				
			}, function(error){
				console.log(error);
			}).finally(function() {
				self.dataLoaded = true;
			});
		}	
		
		self.sortTable = function(heading){
			configInvoices.params.orderby = heading;
			
			if(currentSortField == heading){
				configInvoices.params.sort = (self.sortASC) ? 'ASC' : 'DESC';				
			} else {
				self.sortASC = true;
				configInvoices.params.sort = 'ASC';
			}		
			self.sortASC = !self.sortASC;
			currentSortField = heading;
			
            InvoiceUnpaidSummaryByClientReport1();
           
        }	
		
		self.print = function(){
			self.showSpinner = true;
			self.report_data= {
				"template": { "shortid" : "4yZaYTh1W" },
				"title": "Invoice Unpaid Summary By Client",
				"data": self.invoices
			};      
			$timeout(function() {
				self.showSpinner = false;				
			}, 2000);
		}
		
		self.goToInvoice = function(item){
			return $state.href('main.invoice', {'id': item.invoice_id});
		}
		
		self.goToContact = function(item){					
			 return $state.href('main.contact', {'id': item.contact_id});
		}
		
		$scope.$watch(function(){
			return self.organisation_id;
		}, function(newVal, oldVal){		
			if(newVal != oldVal) InvoiceUnpaidSummaryByClientReport1();
		});
		
		$scope.$watch(function(){
			return self.query.start_date;
		}, function(newVal, oldVal){		
			if(newVal != oldVal) InvoiceUnpaidSummaryByClientReport1();
		}, true);
		
		$scope.$watch(function(){
			return self.query.end_date;
		}, function(newVal, oldVal){			
			if(newVal != oldVal) InvoiceUnpaidSummaryByClientReport1();
		}, true);
		
		
	}
])