angular.module('angular-utils.directives')

.directive('customstyle', function () {
    return {
        restrict: 'AC',
        link: function (scope, element, attrs) {          
            element.css('background-color', attrs.myBgcolor);
            element.css('border', attrs.myBorderstyle);
        }
    }
})