(function() {
	
	window.ui = window.ui || {};
	
	var ApartmentStrip = function (apartmentData, xVal, yVal, width, height, text1, text2, dev_color) {
		
		this.apartmentData = apartmentData;
		this.text1 = text1;
		this.text2 = text2;	
		this.width = width;
		this.height = height;
		this.xVal = xVal;
		this.yVal = yVal;		
		this.dev_color = dev_color;
		this.initialize();
		
	}
	
	var c = ApartmentStrip.prototype = new createjs.Container();
	c.apartmentId;
	c.text1;	
	c.text2;	
	c.xVal;
	c.yVal;	
	c.Container_initialize = c.initialize;
	
	c.initialize = function () {
		
		this.Container_initialize();
		this.createApartmentStrip();	
		this.setButtonListeners();
		this.x = this.xVal;
		this.y = this.yVal;
	}
	
	c.setButtonListeners = function (){
		this.cursor = 'pointer';		
	}
	
	c.createApartmentStrip = function(){	
	
		var shape = new createjs.Shape();                         
		shape.graphics.beginFill(this.dev_color).drawRoundRect(0, 0, this.width, this.height, 5, 5, 5, 5);
		shape.shadow = new createjs.Shadow("#000000", 3, 3, 10);
								
		var outline = new createjs.Shape();                         
		outline.graphics.beginStroke("#000").drawRoundRect(0, 0, this.width, this.height, 5, 5, 5, 5);
		
		var label1 = new createjs.Text(this.text1, "12px Arial", "#000");
		label1.name = 'label';
		label1.textAlign = 'left';
		label1.x = 10;
		label1.y = 5;		
				
		var label2 = new createjs.Text(this.text2, "12px Arial", "#000");
		label2.name = 'label';
		label2.textAlign = 'left';
		label2.x = 10;
		label2.y = 20;
		
		this.addChild(shape);
		this.addChild(outline);
		this.addChild(label1);
		this.addChild(label2);
		
	}	
		
	window.ui.ApartmentStrip = ApartmentStrip;
	
}());