"use strict";

var app = angular.module("samsApp", [
    "ui.router",
    "satellizer",
    "chart.js",
    "ngFileUpload",
    "ui.tinymce",
    "templates",
    "auth.controllers",
    "modal-configuration",
    "users.controllers",
    "users.services",
    "areas.controllers",
    "areas.services",
    "areas.directives",
    "developments.controllers",
    "developments.services",
    "developments.directives",
    "developments.modals",
    "organisations.controllers",
    "organisations.services",
    "organisations.directives",
    "organisations.modals",
    "credit-notes",
    "contacts.controllers",
    "contacts.services",
    "contacts.directives",
    "contacts.modals",
    "cleanings.controllers",
    "cleanings.services",
    "apartments.controllers",
    "apartments.services",
    "apartments.directives",
    "apartments.modals",
    "apartment-details-defaults.controllers",
    "apartment-details-defaults.services",
    "bookings.controllers",
    "bookings.services",
    "bookings.directives",
    "bookings.modals",
    "enquiries",
    "email-templates",
    "xero",
    "marketing",
    "channel-manager",
    "login-activity",
    "invoices.controllers",
    "invoices.services",
    "invoices.directives",
    "invoices.modals",
    "invoice-templates",
    "settings.controllers",
    "settings.services",
    "data-clean-up.controllers",
    "data-clean-up.services",
    "schedulers.controllers",
    "schedulers.services",
    "schedulers.directives",
    "schedulers.modals",
    "actionslog.controllers",
    "actionslog.services",
    "actionslog.directives",
    "permissions.controllers",
    "permissions.services",
    "dashboard.controllers",
    "dashboard.services",
    "dashboard.directives",
    "issues.services",
    "issues.controllers",
    "issues.directives",
    "issues.modals",
    "angular-utils.services",
    "angular-utils.filters",
    "angular-utils.directives",
    "modals.services",
    "menus.controllers",
    "menus.services",
    "versions.controllers",
    "versions.services",
    "test-reports.controllers",
    "test-emails.controllers",
    "google-maps.services",
    "google-maps.directives",
    "ui.bootstrap",
    "ngAnimate",
    "ui.router.title",
    "form-controls.directives",
    "attachments.services",
    "reports.controllers",
    "reports.directives",
    "reports.details",
]);

app.run(function ($rootScope, $state, $auth) {
    $rootScope.$on("$stateChangeStart", function (event, next, nextParams, fromState) {
        if (!$auth.isAuthenticated()) {
            if (next.name !== "auth") {
                event.preventDefault();
                $state.go("auth");
            }
        }
    });
});

app.config(function ($stateProvider, $urlRouterProvider, $authProvider, $provide, $httpProvider, $uibModalProvider) {
    function redirectWhenLoggedOut($q, $injector) {
        return {
            responseError: function (rejection) {
                // Need to use $injector.get to bring in $state or else we get
                // a circular dependency error
                var $state = $injector.get("$state");

                // Instead of checking for a status code of 400 which might be used
                // for other reasons in Laravel, we check for the specific rejection
                // reasons to tell us if we need to redirect to the login state
                var rejectionReasons = ["token_not_provided", "token_expired", "token_absent", "token_invalid"];

                // Loop through each rejection reason and redirect to the login
                // state if one is encountered
                angular.forEach(rejectionReasons, function (value, key) {
                    if (rejection.data.error === value) {
                        console.log(value);

                        // If we get a rejection corresponding to one of the reasons
                        // in our array, we know we need to authenticate the user so
                        // we can remove the current user from local storage
                        localStorage.removeItem("user");

                        // Send the user to the auth state so they can login
                        $state.go("auth");
                    }
                });

                return $q.reject(rejection);
            },
        };
    }

    // Setup for the $httpInterceptor
    $provide.factory("redirectWhenLoggedOut", redirectWhenLoggedOut);

    // Push the new factory onto the $http interceptor array
    $httpProvider.interceptors.push("redirectWhenLoggedOut");
    // Satellizer configuration that specifies which API
    // route the JWT should be retrieved from

    $httpProvider.interceptors.push(function ($q, $injector, ModalFeedback) {
        return {
            responseError: function (rejection) {
                var $state = $injector.get("$state");
                var $uibModal = $injector.get("$uibModal");

                if (rejection.status === 403) {
                    var modalInstance = $uibModal.open(ModalFeedback.showFeedback("Error!", "Route not authorised.", true));

                    setTimeout(function () {
                        modalInstance.close("cancel");
                        $state.go("main.dashboard");
                    }, 2000);
                }
                return $q.reject(rejection);
            },
        };
    });

    $authProvider.loginUrl = "/api/authenticate";

    // Redirect to the auth state if any other states
    // are requested other than users

    $urlRouterProvider.otherwise("/auth");

    $stateProvider

        .state("auth", {
            url: "/auth",
            templateUrl: "auth/login-view.html",
            controller: "AuthController as auth",
        })

        .state("logout", {
            url: "/logout",
            templateUrl: "auth/logout-view.html",
            controller: "AuthController as auth",
        });

    $stateProvider.state("main", {
        abstract: true,
        controller: "MenuController as ctrlMenu",
        templateUrl: "menu/menu-view.html",
    });

    /**********************************************************/
    /****************** Dashboard *****************************/
    /**********************************************************/

    $stateProvider.state("main.dashboard", {
        url: "/dashboard",
        controller: "DashboardController as ctrlDashboard",
        templateUrl: "dashboard/dashboard-view-v2.html",
        resolve: {
            $title: function () {
                return "Dashboard";
            },
        },
    });

    /**********************************************************/
    /****************** Users *****************************/
    /**********************************************************/

    $stateProvider.state("main.users", {
        url: "/users",
        controller: "UsersController as ctrlUsers",
        templateUrl: "users/users-view.html",
        resolve: {
            $title: function () {
                return "Users";
            },
        },
    });

    $stateProvider.state("main.user", {
        url: "/user/{id}",
        controller: "UserController as ctrlUser",
        templateUrl: "users/user-view.html",
        resolve: {
            $title: function () {
                return "User";
            },
        },
    });

    $stateProvider.state("main.profile", {
        url: "/profile",
        controller: "ProfileController as ctrlProfile",
        templateUrl: "users/profile-view.html",
        resolve: {
            $title: function () {
                return "Profile";
            },
        },
    });

    /**********************************************************/
    /****************** Areas *****************************/
    /**********************************************************/

    $stateProvider.state("main.areas", {
        url: "/areas",
        controller: "AreasController as ctrlAreas",
        templateUrl: "areas/areas-view.html",
        resolve: {
            $title: function () {
                return "Areas";
            },
        },
    });

    $stateProvider.state("main.area", {
        url: "/area/{id}",
        controller: "AreaController as ctrlArea",
        templateUrl: "areas/area-view.html",
        resolve: {
            $title: function () {
                return "Area";
            },
        },
    });

    /**********************************************************/
    /****************** Developments *****************************/
    /**********************************************************/

    $stateProvider.state("main.developments", {
        url: "/developments",
        controller: "DevelopmentsController as ctrlDevelopments",
        templateUrl: "developments/developments-view.html",
        resolve: {
            $title: function () {
                return "Developments";
            },
        },
    });

    $stateProvider.state("main.development", {
        url: "/development/{id}",
        controller: "DevelopmentController as ctrlDevelopment",
        templateUrl: "developments/development-view.html",
        resolve: {
            $title: function () {
                return "Development";
            },
        },
    });

    /**********************************************************/
    /****************** Clients *****************************/
    /**********************************************************/

    $stateProvider.state("main.clients", {
        url: "/clients",
        controller: "OrganisationsController as ctrlOrganisations",
        templateUrl: "organisations/organisations-view.html",
        resolve: {
            organisation_type: function () {
                return {
                    title: "Agents",
                    type: "client",
                    state: "main.client",
                };
            },
            $title: function () {
                return "Agents";
            },
        },
    });

    $stateProvider.state("main.client", {
        url: "/client/{id}",
        controller: "OrganisationController as ctrlOrganisation",
        templateUrl: "organisations/organisation-view.html",
        resolve: {
            organisation_type: function () {
                return {
                    title: "Agent",
                    plural: "Agents",
                    type: "client",
                    state_plural: "main.clients",
                    state_single: "main.client",
                };
            },
            $title: function () {
                return "Client";
            },
        },
    });

    $stateProvider.state("main.client-reports", {
        url: "/client-reports",
        controller: "OrganisationReportsController as ctrlOrganisationReports",
        templateUrl: "organisations/organisation-reports.html",
        resolve: {
            $title: function () {
                return "Client Reports";
            },
        },
    });

    $stateProvider.state("main.client-analytics", {
        url: "/client-analytics",
        controller: "OrganisationAnalyticsController as ctrlOrganisationAnalytics",
        templateUrl: "organisations/organisation-analytics.html",
        resolve: {
            $title: function () {
                return "Client Analytics";
            },
        },
    });

    /**********************************************************/
    /****************** Subcontractors *****************************/
    /**********************************************************/

    $stateProvider.state("main.subcontractors", {
        url: "/subcontractors",
        controller: "OrganisationsController as ctrlOrganisations",
        templateUrl: "organisations/organisations-view.html",
        resolve: {
            organisation_type: function () {
                return {
                    title: "Sub-contractors",
                    type: "subcontractor",
                    state: "main.subcontractor",
                };
            },
            $title: function () {
                return "Sub-contractors";
            },
        },
    });

    $stateProvider.state("main.subcontractor", {
        url: "/subcontractor/{id}",
        controller: "OrganisationController as ctrlOrganisation",
        templateUrl: "organisations/organisation-view.html",
        resolve: {
            organisation_type: function () {
                return {
                    title: "Sub-contractor",
                    plural: "Sub-contractors",
                    type: "subcontractor",
                    state_plural: "main.subcontractors",
                    state_single: "main.subcontractor",
                };
            },
            $title: function () {
                return "Sub-contractors";
            },
        },
    });

    /**********************************************************/
    /****************** Apartments *****************************/
    /**********************************************************/
    $stateProvider.state("main.apartments", {
        url: "/apartments",
        controller: "ApartmentsController as ctrlApartments",
        templateUrl: "apartments/apartments-view.html",
        resolve: {
            $title: function () {
                return "Apartments";
            },
        },
    });

    $stateProvider.state("main.apartment", {
        url: "/apartment/{id}",
        controller: "ApartmentController as ctrlApartment",
        templateUrl: "apartments/apartment-view.html",
        resolve: {
            $title: function () {
                return "Apartment";
            },
        },
    });
    /*
      $stateProvider.state('main.apartment-reports', {
          url: "/apartment-reports",
          controller: 'ApartmentReportsController as ctrlApartmentReports',
          templateUrl: 'apartments/apartment-reports.html',
          resolve: {		  
              $title: function() { return 'Apartment Reports'; }
          }
      })*/

    /**********************************************************/
    /****************** Apartment details defaults*************/
    /**********************************************************/

    $stateProvider.state("main.apartment-details-defaults", {
        url: "/apartment-details-defaults",
        controller: "ApartmentDetailsDefaultsController as ctrlApartmentDetailsDefaults",
        templateUrl: "apartment-details-defaults/apartment-details-defaults-view.html",
        resolve: {
            $title: function () {
                return "Apartment Details Defaults";
            },
        },
    });

    $stateProvider.state("main.apartment-details-default", {
        url: "/apartment-details-default/{id}",
        controller: "ApartmentDetailsDefaultController as ctrlApartmentDetailsDefault",
        templateUrl: "apartment-details-defaults/apartment-details-default-view.html",
        resolve: {
            $title: function () {
                return "Apartment Details Default";
            },
        },
    });

    /**********************************************************/
    /****************** Bookings *****************************/
    /**********************************************************/
    $stateProvider.state("main.bookings", {
        url: "/bookings",
        controller: "BookingsController as ctrlBookings",
        templateUrl: "bookings/bookings-view.html",
        resolve: {
            $title: function () {
                return "Bookings";
            },
        },
    });

    $stateProvider.state("main.booking", {
        url: "/booking/{id}",
        controller: "BookingController as ctrlBooking",
        templateUrl: "bookings/booking-view.html",
        resolve: {
            $title: function () {
                return "Booking";
            },
        },
    });

    $stateProvider.state("main.booking-reports", {
        url: "/booking-reports",
        controller: "BookingReportsController as ctrlBookingReports",
        templateUrl: "bookings/booking-reports.html",
        resolve: {
            $title: function () {
                return "Booking Report";
            },
        },
    });
    /*
      $stateProvider.state('main.booking-confirmation', {
          url: "/booking-confirmation/{id}",
          controller: 'BookingConfirmationController as ctrlBookingConfirmation',
          templateUrl: 'bookings/booking-confirmation-view.html',
          resolve: {		  
              $title: function() { return 'Booking Confirmation'; }
          }
      })
      */
    $stateProvider.state("main.booking-summary-report", {
        url: "/booking-summary-report",
        controller: "BookingSummaryReportController as ctrlBookingReports",
        templateUrl: "bookings/booking-summary-report.html",
        resolve: {
            $title: function () {
                return "Booking Summary";
            },
        },
    });

    $stateProvider.state("main.enquiry-summary-report", {
        url: "/enquiry-summary-report",
        controller: "EnquirySummaryReportController as ctrlEnquiryReports",
        templateUrl: "enquiries/enquiry-summary-report.html",
        resolve: {
            $title: function () {
                return "Enquiry Summary";
            },
        },
    });

    /**********************************************************/
    /****************** Credit Notes **************************/
    /**********************************************************/

    $stateProvider.state("main.credit-notes", {
        url: "/credit-notes",
        controller: "CreditNotesController as ctrlCreditNotes",
        templateUrl: "credit-notes/credit-notes-view.html",
        resolve: {
            $title: function () {
                return "Credit Notes";
            },
        },
    });

    $stateProvider.state("main.credit-note", {
        url: "/credit-note/{id}",
        controller: "CreditNoteController as ctrlCreditNote",
        templateUrl: "credit-notes/credit-note-view.html",
        resolve: {
            $title: function () {
                return "Credit Note";
            },
        },
    });

     $stateProvider.state("main.credit-note-summary-date-to-date", {
         url: "/credit-note-summary-date-to-date",
         controller: "CreditNoteSummaryDateToDateReportController as ctrlCreditNoteReportsSummaryByDate",
         templateUrl: "credit-notes/credit-note-summary-by-date.html",
         resolve: {
             $title: function () {
                 return "Credit Note Summary By Date";
             },
         },
     });

    /**********************************************************/
    /****************** Enquiries *****************************/
    /**********************************************************/

    $stateProvider.state("main.enquiries", {
        url: "/enquiries",
        controller: "EnquiriesController as ctrlEnquiries",
        templateUrl: "enquiries/enquiries-view.html",
        resolve: {
            $title: function () {
                return "Enquiries";
            },
        },
    });

    $stateProvider.state("main.enquiry", {
        url: "/enquiry/{id}",
        controller: "EnquiryController as ctrlEnquiry",
        templateUrl: "enquiries/enquiry-view.html",
        resolve: {
            $title: function () {
                return "Enquiry";
            },
        },
    });

    /**********************************************************/
    /****************** Contacts**************************/
    /**********************************************************/

    $stateProvider.state("main.contacts", {
        url: "/contacts",
        controller: "ContactsController as ctrlContacts",
        templateUrl: "contacts/contacts-view.html",
        resolve: {
            $title: function () {
                return "Contacts";
            },
        },
    });

    $stateProvider.state("main.contact", {
        url: "/contact/{id}",
        controller: "ContactController as ctrlContact",
        templateUrl: "contacts/contact-view.html",
        resolve: {
            $title: function () {
                return "Contact";
            },
        },
    });
    /*
      $stateProvider.state('main.contact-reports', {
          url: "/contact-reports",
          controller: 'ContactReportsController as ctrlContactReports',
          templateUrl: 'contacts/contact-reports.html',
          resolve: {		  
              $title: function() { return 'Contact Reports'; }
          }
      })
      */
    /**********************************************************/
    /****************** Invoices**************************/
    /**********************************************************/

    $stateProvider.state("main.invoices", {
        url: "/invoices",
        controller: "InvoicesController as ctrlInvoices",
        templateUrl: "invoices/invoices-view.html",
        resolve: {
            $title: function () {
                return "Invoices";
            },
        },
    });

    $stateProvider.state("main.invoice", {
        url: "/invoice/{id}",
        controller: "InvoiceController as ctrlInvoice",
        templateUrl: "invoices/invoice-view.html",
        params: { booking_id: null },
        resolve: {
            $title: function () {
                return "Invoice";
            },
            invoiceResponse: function (InvoicesService, $stateParams) {
                const id = $stateParams.id;
                if (id === "add-new ") return { data: {} };
                return InvoicesService.getInvoice(id);
            },
        },
    });

    $stateProvider.state("main.invoice-analytics", {
        url: "/invoice-analytics",
        controller: "InvoiceAnalyticsController as ctrlInvoiceAnalytics",
        templateUrl: "invoices/invoice-analytics.html",
        resolve: {
            $title: function () {
                return "Invoice Analytics";
            },
        },
    });

    $stateProvider.state("main.invoice-reports", {
        url: "/invoice-reports",
        controller: "InvoiceReportsController as ctrlInvoiceReports",
        templateUrl: "invoices/invoice-reports.html",
        resolve: {
            $title: function () {
                return "Invoice Reports";
            },
        },
    });

    $stateProvider.state("main.invoice-standard-report", {
        url: "/invoice-standard-report",
        controller: "InvoiceStandardReportController as ctrlInvoiceReportsStandard",
        templateUrl: "invoices/invoice-standard-report.html",
        resolve: {
            $title: function () {
                return "Invoice Standard Report";
            },
        },
    });

    $stateProvider.state("main.invoice-schedule-report", {
        url: "/invoice-schedule-report",
        controller: "InvoiceScheduleReportController as ctrlInvoiceReportsSchedule",
        templateUrl: "invoices/invoice-schedule-report.html",
        resolve: {
            $title: function () {
                return "Invoice Schedule Report";
            },
        },
    });

    $stateProvider.state("main.invoice-overdue-report", {
        url: "/invoice-overdue-report",
        controller: "InvoiceOverdueReportController as ctrlInvoiceOverdueReport",
        templateUrl: "invoices/invoice-overdue.html",
        resolve: {
            $title: function () {
                return "Invoice Overdue Report";
            },
        },
    });

    $stateProvider.state("main.invoice-summary-date-to-date", {
        url: "/invoice-summary-date-to-date",
        controller: "InvoiceSummaryDateToDateReportController as ctrlInvoiceReportsSummaryByDate",
        templateUrl: "invoices/invoice-summary-by-date.html",
        resolve: {
            $title: function () {
                return "Invoice Summary By Date";
            },
        },
    });

    $stateProvider.state("main.invoice-summary-unpaid-client", {
        url: "/invoice-summary-unpaid-client",
        controller: "InvoiceSummaryUnpaidClientReportController as ctrlInvoiceReportsUnpaidClient",
        templateUrl: "invoices/invoice-summary-unpaid-by-client.html",
        resolve: {
            $title: function () {
                return "Invoice Summary Unpaid Client";
            },
        },
    });

    $stateProvider.state("main.invoice-summary-unpaid-date-to-date", {
        url: "/invoice-summary-unpaid-date-to-date",
        controller: "InvoiceSummaryUnpaidDateToDateReportController as ctrlInvoiceReportsSummaryUnpaidByDate",
        templateUrl: "invoices/invoice-summary-unpaid-by-date.html",
        resolve: {
            $title: function () {
                return "Invoice Summary Unpaid Date";
            },
        },
    });

    $stateProvider.state("main.invoice-detailed-date-to-date", {
        url: "/invoice-detailed-date-to-date",
        controller: "InvoiceDetailedDateToDateReportController as ctrlInvoiceReportDetailedByDate",
        templateUrl: "invoices/invoice-detailed-by-date.html",
        resolve: {
            $title: function () {
                return "Invoice Detailed By Date";
            },
        },
    });

    $stateProvider.state("main.invoice-detailed-unpaid-date-to-date", {
        url: "/invoice-detailed-unpaid-date-to-date",
        controller: "InvoiceDetailedUnpaidDateToDateReportController as ctrlInvoiceReportsDetailedUnpaidByDate",
        templateUrl: "invoices/invoice-detailed-unpaid-by-date.html",
        resolve: {
            $title: function () {
                return "Invoice Detailed Unpaid Date";
            },
        },
    });

    /**********************************************************/
    /****************** Issues**************************/
    /**********************************************************/

    $stateProvider.state("main.issues", {
        url: "/issues",
        controller: "IssuesController as ctrlIssues",
        templateUrl: "issues/issues-view.html",
        resolve: {
            $title: function () {
                return "Issues";
            },
        },
    });

    $stateProvider.state("main.issue", {
        url: "/issue/{id}",
        controller: "IssueController as ctrlIssue",
        templateUrl: "issues/issue-view.html",
        resolve: {
            $title: function () {
                return "Issue";
            },
        },
    });

    $stateProvider.state("main.issue-new", {
        url: "/issue-new/{id}",
        controller: "IssueController as ctrlIssue",
        templateUrl: "issues/issue-view-new.html",
        resolve: {
            $title: function () {
                return "Issue";
            },
        },
    });

    $stateProvider.state("main.issue-reports", {
        url: "/issue-reports",
        controller: "IssueReportsController as ctrlIssueReports",
        templateUrl: "issues/issue-reports.html",
        resolve: {
            $title: function () {
                return "Issue Reports";
            },
        },
    });

    $stateProvider.state("main.open-issues-report", {
        url: "/open-issues-report",
        controller: "IssuesOpenReportController as ctrlIssuesOpenReport",
        templateUrl: "issues/issues-open-report.html",
        resolve: {
            $title: function () {
                return "Open Issues Report";
            },
        },
    });

    /**********************************************************/
    /****************** Cleanings *****************************/
    /**********************************************************/

    $stateProvider.state("main.cleanings", {
        url: "/cleanings",
        controller: "CleaningsController as ctrlCleanings",
        templateUrl: "cleanings/cleanings-view.html",
        resolve: {
            $title: function () {
                return "Cleanings";
            },
        },
    });

    $stateProvider.state("main.cleaning", {
        url: "/cleaning/{id}",
        controller: "CleaningController as ctrlCleaning",
        templateUrl: "cleanings/cleaning-view.html",
        resolve: {
            $title: function () {
                return "Cleaning";
            },
        },
    });

    $stateProvider.state("main.cleaning-reports", {
        url: "/cleaning-reports",
        controller: "CleaningReportsController as ctrlCleaningReports",
        templateUrl: "cleanings/cleaning-reports.html",
        resolve: {
            $title: function () {
                return "Cleaning Reports";
            },
        },
    });

    $stateProvider.state("main.cleaning-rota-report", {
        url: "/cleaning-rota-report",
        controller: "CleaningRotaReportController as ctrlCleaningReports",
        templateUrl: "cleanings/cleaning-rota-report.html",
        resolve: {
            $title: function () {
                return "Cleaning Rota Report";
            },
        },
    });

    /**********************************************************/
    /****************** Site Settings *****************************/
    /**********************************************************/

    $stateProvider.state("main.settings-list", {
        url: "/settings-list",
        templateUrl: "settings/settings-list.html",
        resolve: {
            $title: function () {
                return "Settings";
            },
        },
    });

    $stateProvider.state("main.settings", {
        url: "/settings/{group}",
        controller: "SettingsController as ctrlSettings",
        templateUrl: "settings/settings-view.html",
        resolve: {
            $title: function () {
                return "Setting";
            },
            $group: function ($stateParams) {
                var returnVal;
                switch ($stateParams.group) {
                    case "company_settings":
                        returnVal = "Company Settings";
                        break;
                    case "invoice_settings":
                        returnVal = "Invoice Settings";
                        break;
                    case "issue_response_times":
                        returnVal = "Issue Response Times";
                        break;
                    case "report_settings":
                        returnVal = "Report Settings";
                        break;
                    case "scheduler_settings":
                        returnVal = "Scheduler Settings";
                        break;
                    case "booking_confirmation_settings":
                        returnVal = "Booking Confirmation Settings";
                        break;
                    case "general_email_settings":
                        returnVal = "General Email Settings";
                        break;
                    case "channel_manager_settings":
                        returnVal = "Channel Manager Settings";
                        break;
                    default:
                        returnVal = "Company Settings";
                }

                return returnVal;
            },
        },
    });

    /**********************************************************/
    /****************** Data Clean Up **************************/
    /**********************************************************/

    $stateProvider.state("main.data-clean-up", {
        url: "/data-clean-up",
        controller: "DataCleanUpController as ctrlDataCleanUp",
        templateUrl: "settings/data-clean-up-view.html",
        resolve: {
            $title: function () {
                return "Data Clean Up";
            },
        },
    });

    /**********************************************************/
    /****************** Schedulers *****************************/
    /**********************************************************/

    $stateProvider.state("main.scheduler", {
        url: "/scheduler",
        controller: "SchedulerController as ctrlScheduler",
        templateUrl: "schedulers/scheduler-view.html",
        resolve: {
            $title: function () {
                return "Scheduler";
            },
        },
    });

    $stateProvider.state("main.scheduler-settings", {
        url: "/scheduler-settings",
        controller: "SchedulerSettingsController as ctrlScheduler",
        templateUrl: "schedulers/scheduler-settings.html",
        resolve: {
            $title: function () {
                return "Scheduler Settings";
            },
        },
    });

    $stateProvider.state("main.scheduler-apartment-order", {
        url: "/scheduler-apartment-order",
        controller: "SchedulerApartmentOrderController as ctrlScheduler",
        templateUrl: "schedulers/scheduler-apartment-order.html",
        resolve: {
            $title: function () {
                return "Scheduler Apartment Order";
            },
        },
    });

    /**********************************************************/
    /******************* Versions *****************************/
    /**********************************************************/

    $stateProvider.state("main.versions", {
        url: "/versions",
        controller: "VersionsController as ctrlVersions",
        templateUrl: "versions/versions-view.html",
        resolve: {
            $title: function () {
                return "Versions";
            },
        },
    });

    $stateProvider.state("main.version", {
        url: "/version/{id}",
        controller: "VersionController as ctrlVersion",
        templateUrl: "versions/version-view.html",
        resolve: {
            $title: function () {
                return "Version";
            },
        },
    });

    /**********************************************************/
    /****************** ActionsLog *****************************/
    /**********************************************************/

    $stateProvider.state("main.actionslogs", {
        url: "/actionslogs",
        controller: "ActionsLogsController as ctrlActionsLogs",
        templateUrl: "actionslog/actionslogs-view.html",
        resolve: {
            $title: function () {
                return "Action Logs";
            },
        },
    });

    $stateProvider.state("main.actionslog", {
        url: "/actionslog/{id}",
        controller: "ActionsLogController as ctrlActionsLog",
        templateUrl: "actionslog/actionslog-view.html",
        resolve: {
            $title: function () {
                return "Action Log";
            },
        },
    });

    /**********************************************************/
    /****************** LoginActivity *****************************/
    /**********************************************************/

    $stateProvider.state("main.login-activities", {
        url: "/login-activities",
        controller: "LoginActivitiesController as ctrlLoginActivities",
        templateUrl: "login-activity/login-activities-view.html",
        resolve: {
            $title: function () {
                return "Login Activities";
            },
        },
    });

    $stateProvider.state("main.login-activity", {
        url: "/login-activity/{id}",
        controller: "LoginActivityController as ctrlLoginActivity",
        templateUrl: "login-activity/login-activity-view.html",
        resolve: {
            $title: function () {
                return "Login Activity";
            },
        },
    });

    /**********************************************************/
    /****************** Permissions****************************/
    /**********************************************************/

    /*$stateProvider.state('main.permissions', {
          url: "/permissions",
          controller: 'PermissionsController as ctrlPermissions',
          templateUrl: 'permissions/permissions-view.html',
          resolve: {		  
              $title: function() { return 'Permissions'; }
          }
      })*/

    $stateProvider.state("main.permissions-list", {
        url: "/permissions-list",
        templateUrl: "permissions/permissions-list.html",
        resolve: {
            $title: function () {
                return "Permissions";
            },
        },
    });

    $stateProvider.state("main.permissions", {
        url: "/permissions/{group}",
        controller: "PermissionsController as ctrlPermissions",
        templateUrl: "permissions/permissions-view.html",
        resolve: {
            $title: function () {
                return "Permissions";
            },
            $group: function ($stateParams) {
                return $stateParams.group;
            },
        },
    });

    $stateProvider.state("main.reports", {
        url: "/reports",
        controller: "ReportsController as ctrlReports",
        templateUrl: "reports/reports-view.html",
        resolve: {
            report_data: function ($localstorage) {
                return $localstorage.getObject("report_data");
            },
            $title: function () {
                return "Reports";
            },
        },
    });

    /**********************************************************/
    /****************** Channel Manager ***********************/
    /**********************************************************/

    $stateProvider.state("main.channel-manager", {
        url: "/channel-manager",
        controller: "ChannelManagerController as ctrlChannelManager",
        templateUrl: "channel-manager/channel-manager-view.html",
        resolve: {
            $title: function () {
                return "Channel Manager";
            },
        },
    });

    $stateProvider.state("main.channel-manager-development-view", {
        url: "/channel-manager-development-view/{id}",
        controller: "CmDevelopmentController as ctrlCmDevelopment",
        templateUrl: "channel-manager/cm-development-view.html",
        resolve: {
            $title: function () {
                return "Channel Manager Development View";
            },
            data: function ($stateParams, DevelopmentsService, $q, UtilService) {
                var fullId = $stateParams.id;
                var id = UtilService.formatHotelId(fullId);
                var deferred = $q.defer();
                DevelopmentsService.getDevelopment(id).then(
                    function (success) {
                        deferred.resolve(success.data);
                    },
                    function (error) {
                        console.log(error);
                        deferred.resolve({});
                    }
                );

                return deferred.promise;
            },
        },
    });

    $stateProvider.state("main.channel-manager-reservations-paginate", {
        url: "/channel-manager-reservations-paginate",
        controller: "CmPushedReservationsController as ctrlCmPushedReservations",
        templateUrl: "channel-manager/channel-manager-pushed-reservations-view.html",
        resolve: {
            $title: function () {
                return "Channel Manager Reservations View";
            },
        },
    });

    $stateProvider.state("main.channel-manager-reservation", {
        url: "/channel-manager-reservation/{id}",
        controller: "CmPushedReservationController as ctrlCmPushedReservation",
        templateUrl: "channel-manager/channel-manager-pushed-reservation-view.html",
        resolve: {
            $title: function () {
                return "Channel Manager Reservation View";
            },
        },
    });

    /**********************************************************/
    /****************** Marketing *****************************/
    /**********************************************************/
    $stateProvider.state("main.marketing", {
        url: "/marketing",
        controller: "MarketingController as ctrlMarketing",
        templateUrl: "marketing/marketing-view.html",
        resolve: {
            $title: function () {
                return "Marketing";
            },
        },
    });

    /**********************************************************/
    /*********************** Xero *****************************/
    /**********************************************************/

    $stateProvider.state("main.xero", {
        url: "/xero",
        controller: "XeroController as ctrlXero",
        templateUrl: "xero/xero-view.html",
        resolve: {
            $title: function () {
                return "Xero";
            },
        },
    });

    /**********************************************************/
    /****************** test reports **************************/
    /**********************************************************/

    $stateProvider.state("main.test-report1", {
        url: "/test-report1",
        controller: "TestReportController as ctrlTestReport",
        templateUrl: "test-reports/test-report1-view.html",
    });

    $stateProvider.state("main.test-report2", {
        url: "/test-report2",
        controller: "TestReportController2 as ctrlTestReport",
        templateUrl: "test-reports/test-report2-view.html",
    });

    /**********************************************************/
    /****************** test emails  **************************/
    /**********************************************************/

    $stateProvider.state("main.test-email", {
        url: "/test-email",
        controller: "TestEmailController as ctrlTestEmail",
        templateUrl: "test-emails/email-test.html",
        resolve: {
            $title: function () {
                return "Test Email";
            },
        },
    });

    $stateProvider.state("main.email-display-booking-confirmation", {
        url: "/email-display/booking-confirmation/{booking_id}",
        controller: function ($scope, $stateParams, $http, $sce) {
            var id = $stateParams.booking_id;

            $scope.emailDisplay = $sce.trustAsHtml('<i class="fa fa-cog fa-spin" style="font-size:48px"></i>');

            $http.get("api/emails/confirmation-email/" + id).then(
                function (success) {
                    $scope.emailDisplay = $sce.trustAsHtml(success.data);
                },
                function (error) {
                    console.log(error);
                }
            );
        },
        templateUrl: "email-display/email-display.html",
        resolve: {
            $title: function () {
                return "Booking Confirmation Email";
            },
        },
    });

    $stateProvider.state("main.email-display-booking-confirmation-attachment", {
        url: "/email-display/booking-confirmation-attachment/{booking_id}",
        controller: function ($scope, $stateParams, $http, $sce) {
            var id = $stateParams.booking_id;

            $scope.emailDisplay = $sce.trustAsHtml('<i class="fa fa-cog fa-spin" style="font-size:48px"></i>');

            $http.get("api/emails/confirmation-email-attachment/" + id).then(
                function (success) {
                    $scope.emailDisplay = $sce.trustAsHtml(success.data);
                },
                function (error) {
                    console.log(error);
                }
            );
        },
        templateUrl: "email-display/email-display.html",
        resolve: {
            $title: function () {
                return "Booking Confirmation Email";
            },
        },
    });

    $stateProvider.state("main.email-display-check-in", {
        url: "/email-display/check-in/{booking_id}",
        controller: function ($scope, $stateParams, $http, $sce) {
            var id = $stateParams.booking_id;

            $scope.emailDisplay = $sce.trustAsHtml('<i class="fa fa-cog fa-spin" style="font-size:48px"></i>');

            $http.get("api/emails/check-in/" + id).then(
                function (success) {
                    $scope.emailDisplay = $sce.trustAsHtml(success.data);
                },
                function (error) {
                    console.log(error);
                }
            );
        },
        templateUrl: "email-display/email-display.html",
        resolve: {
            $title: function () {
                return "Check-In Email";
            },
        },
    });

    $stateProvider.state("main.email-display-check-out", {
        url: "/email-display/check-out",
        controller: function ($scope, $stateParams, $http, $sce) {
            var id = $stateParams.booking_id;

            $scope.emailDisplay = $sce.trustAsHtml('<i class="fa fa-cog fa-spin" style="font-size:48px"></i>');

            $http.get("api/emails/check-out").then(
                function (success) {
                    $scope.emailDisplay = $sce.trustAsHtml(success.data);
                },
                function (error) {
                    console.log(error);
                }
            );
        },
        templateUrl: "email-display/email-display.html",
        resolve: {
            $title: function () {
                return "Check-In Email";
            },
        },
    });

    $stateProvider.state("main.email-display-enquiry-confirmation", {
        url: "/email-display/enquiry-confirmation/{enquiry_id}",
        controller: function ($scope, $stateParams, $http, $sce) {
            var id = $stateParams.enquiry_id;

            $scope.emailDisplay = $sce.trustAsHtml('<i class="fa fa-cog fa-spin" style="font-size:48px"></i>');

            $http.get("api/emails/enquiry-confirmation-email/" + id).then(
                function (success) {
                    $scope.emailDisplay = $sce.trustAsHtml(success.data);
                },
                function (error) {
                    console.log(error);
                }
            );
        },
        templateUrl: "email-display/email-display.html",
        resolve: {
            $title: function () {
                return "Enquiry Confirmation Email";
            },
        },
    });

    $stateProvider.state("main.email-display-marketing-email", {
        url: "/emails/marketing-email/{email_template_id}",
        controller: function ($scope, $stateParams, $http, $sce) {
            var id = $stateParams.email_template_id;

            $scope.emailDisplay = $sce.trustAsHtml('<i class="fa fa-cog fa-spin" style="font-size:48px"></i>');

            $http.get("api/emails/marketing-email/" + id).then(
                function (success) {
                    $scope.emailDisplay = $sce.trustAsHtml(success.data);
                },
                function (error) {
                    console.log(error);
                }
            );
        },
        templateUrl: "email-display/email-display.html",
        resolve: {
            $title: function () {
                return "Marketing Email";
            },
        },
    });
});
