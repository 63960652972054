angular.module('invoice-templates')

.controller('ModalInvoiceTemplatesController', ['$scope',  '$uibModalInstance', 'data', 'title', 'permissions',
			'InvoiceTemplatesService', 'UtilBroadcastService', '$timeout', '$document',
		function($scope, $uibModalInstance, data, title, permissions,
			InvoiceTemplatesService, UtilBroadcastService, $timeout, $document) {
	
		$scope.title = title;
		$scope.data = data;
		$scope.showSaveSpinner = false;
		$scope.showAlert = false;
		$scope.alertDetails = {};
		$scope.newInvoiceTemplate = {};
		$scope.permissions = permissions;
		$scope.section = 'edit';
		$scope.preview_data = {};
		
		$scope.addPlaceholder = function (type) {
			var queryResult = $document[0].getElementById('description_id');
			var element = angular.element(queryResult);                     
			var domElement = element[0];
			var text = domElement.value;
			var val = "{" + type + "}"; 
			var newText = '';        
			
			var startPos = ''
			var endPos = '';
			var scrollTop = '';
	
			if (document.selection) {		                             
					newText = val;	
			} else if (domElement.selectionStart || domElement.selectionStart === 0) {                                
					startPos = domElement.selectionStart;
					endPos = domElement.selectionEnd;
					scrollTop = domElement.scrollTop;
					newText = domElement.value.substring(0, startPos) + val + domElement.value.substring(endPos, domElement.value.length);                                                          
			} else {
					newText = domElement.value += val;          
			}

			$scope.data.description = newText;

			if (document.selection) {	                                                    
				domElement.focus();		                            
			} else if (domElement.selectionStart || domElement.selectionStart === 0) {                                                     
				domElement.focus();
				domElement.selectionStart = startPos + val.length;
				domElement.selectionEnd = startPos + val.length;
				domElement.scrollTop = scrollTop;                        
			} else {                        
				domElement.focus();                    
			}

				
		}

		$scope.viewPreview = function(){
			var start = 'Mon, May 20 2030';
			var end = 'Tue, Aug 20 2030';
			var duration = '92';
			var charge = '150';
			var total = '13,800';
			var vat = '0.2';
			var vat_rate = '2,760';

			var preview_data = {};

			preview_data.description = $scope.data.description;
			preview_data.description = preview_data.description.replace("{start-date}", start);
			preview_data.description = preview_data.description.replace("{end-date}", end);
			preview_data.description = preview_data.description.replace("{duration}", duration);
			preview_data.description = preview_data.description.replace("{charge-per-night}", charge);
			preview_data.description = preview_data.description.replace("{total}", total);
			preview_data.description = preview_data.description.replace("{vat}", vat);
			preview_data.description = preview_data.description.replace("{vat-rate}", vat_rate);
			
			preview_data.total = $scope.data.total;
			preview_data.total = preview_data.total.replace("{total}", total);

			preview_data.vat = $scope.data.vat;
			preview_data.vat = preview_data.vat.replace("{vat}", vat);

			preview_data.vat_rate = $scope.data.vat_rate;
			preview_data.vat_rate = preview_data.vat_rate.replace("{vat-rate}", vat_rate);

			$scope.preview_data= preview_data;


			$scope.section = 'preview';
		}

		$scope.viewEdit = function(){
			$scope.section = 'edit';
		}


		$scope.saveRecord = function () {
			$scope.showSaveSpinner = true;

			var promise; var message;
			if ($scope.data.id === 'new') {
				promise = InvoiceTemplatesService.postStore($scope.data);
				message = 'Record created';
			} else {
				promise = InvoiceTemplatesService.postUpdate($scope.data.id, $scope.data);
				message = 'Record updated';
			}

			promise.then(function (success) {
				$scope.InvoiceTemplatesForm.$setPristine();
				UtilBroadcastService.showAlert($scope, 'Success', message, false);
				if ($scope.data.id === 'new') $scope.data.id = success.data;
			}, function (error) {
				console.log(error);
				UtilBroadcastService.showAlert($scope, 'Failure', 'Unable to create/update record.');
			}).finally(function () {
				UtilBroadcastService.broadcastUpdate();
				$timeout(function () {
					$uibModalInstance.close('close');
				}, 2000);
			});
		}

		$scope.cancel = function () {
			$uibModalInstance.dismiss();
		}
	}
	
])