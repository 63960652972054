angular.module('data-clean-up.services', [])

    .factory("DataCleanUpService", ['$http',

        function($http){

        var dataCleanUpService = {};

        dataCleanUpService.postContactCleanUp = function(object){
            return $http.post('api/contact/clean-up', object);
        }
		
		dataCleanUpService.postOrganisationCleanUp = function(object){
            return $http.post('api/organisation/clean-up', object);
        }
       
        return dataCleanUpService;
    }])



