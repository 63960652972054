angular.module('bookings.directives', [])

	.directive('bookingDetails', function ($rootScope, BookingsService, $uibModal, ModalFeedback, 
									ModalBookingDetail, ModalConfirmAction, $timeout) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				bookingId: '=',
				checkInDate: '=',
				checkOutDate: '=',				
				permissions: '='
			},      
			template:	`<div>
							<div ng-show="!dataLoaded" class="row text-center">
								<span ><i class="fa fa-cog fa-spin" style="font-size:48px"></i></span>
							</div>
							<div ng-show="dataLoaded">
								<table class="table"  >
									<tr>
										<th>Check In</th>
										<th>Check Out</th>
										<th>Apartment</th>
										<th colspan="2" style="width: 10%"><div class="text-center">Action</div></th>
									</tr>
									<tr ng-repeat="item in bookingdetails">
										<td>{{item.check_in_date | date:'mediumDate'}}</td>
										<td>{{item.check_out_date | date:'mediumDate'}}</td>
										<td>{{item.address1}}, {{item.address2}}</td>
										<td><my-sub-form-button click-fn="showBookingDetail(item)" button-type="edit"></my-sub-form-button></td>
										<td><my-sub-form-button ng-show="permissions['bookings.delete']" 
												click-fn="deleteBookingDetail(item)" button-type="delete"></my-sub-form-button></td>                                
																	   
									</tr>                           
								</table>
								<div class="pull-right">
									<my-sub-form-button ng-show="permissions['bookings.create']" 
									click-fn="createBookingDetail()" button-type="add"></my-sub-form-button>
								</div>
							</div>
						</div>`,
			link: function (scope, element, attr) {
				var config = {	params: {}	};
				scope.dataLoaded = false;
				scope.bookingdetails = [];
				scope.apartments = [];			
				
				function modalMissingInfoAlert(msg){	
					var modalInstance = $uibModal.open(ModalFeedback.showFeedback(msg.title, msg.description, 'alert-default', false));		
				}    
				
				
				function getBookingDetails(){
					BookingsService.getBookingDetailByBooking(scope.bookingId).then(function(success){     
						scope.bookingdetails = success.data;  
						
						if(scope.bookingdetails.length === 0) {
							var msg = {}; msg.title = 'No Booking Details';	msg.description = 'Warning: No booking details found with this booking.';
							modalMissingInfoAlert(msg);
							
						}
						scope.dataLoaded = true;
					}, function(error){
						console.log(error);
					});
				}
				
				getBookingDetails();
				
				function broadcastUpdate(){
					$rootScope.$broadcast('updateRecord', {
						data: true
                    });
				}
				
				scope.$on('updateSidebars', function (event, result) {					
					getBookingDetails();
				});
				
				
				function showBookingDetail(data){
					var booking_dates = {};
					booking_dates.check_in_date = scope.checkInDate;
					booking_dates.check_out_date = scope.checkOutDate;
					var modalInstance = $uibModal.open(ModalBookingDetail.showDetail(data, booking_dates, scope.permissions));			
					
					modalInstance.result.then(function (data) {
						getBookingDetails();
					});
				}
				
				scope.showBookingDetail = function(data){
					showBookingDetail(data);					
				}
				
				scope.createBookingDetail = function(){
					var data = {id: 'new', booking_id: scope.bookingId};
					showBookingDetail(data);
				}
				
				scope.deleteBookingDetail = function(item){
					var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
						'Warning!', 
						'Are you sure you would like to delete this record? Deletions cannot be undone.', 
						'alert-danger')
					);
					
					modalInstance.result.then(function (data) {
						if(data=='confirmed'){
							BookingsService.getBookingDetailDelete(item.id).then(function(success){                      
								var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback(
										'Success',
										'Record deleted!',
										'alert-success'
									)
								);						
								$timeout(function() { modalInstance1.close('cancel'); }, 2000);
                                broadcastUpdate();		
                                getBookingDetails();
							}, function(error){
								var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback(
										'Error!',
										'Unable to delete record. Please contact a system administrator.',
										'alert-danger'
									)
								);			
								$timeout(function() { modalInstance1.close('cancel'); }, 2000);
							});
						}					  
					});
				}
				
			}
		};
	})