angular.module('enquiries')

.factory("ModalEnquiryDetail", [function(){
	 
	var modalEnquiryDetail = {};          
	
	
	modalEnquiryDetail.show = function(data, permissions){ 
		
		return {
				animation: true,     
                template: 
				`<div class="modal-header">
					<div class="col-sm-9">
						<h3 class="modal-title">{{title}}</h3>
					</div>
					<div class="col-sm-3">
						<a ng-show="permissions['cleanings.show'] && data.id != 'new'" 
							ng-click="goToClean()"
							class="btn btn-primary pull-right"><i class="fa fa-arrow-right" aria-hidden="true"></i> Go To Clean</a>
					</div>
				</div>
				<div class="modal-body alert">           
					<form name="enquiryDetailForm">
						
						<div>    
							<div class="row">
								<div class="col-sm-6">
									<div class="form-group">       
										<label>ID</label>
										<input type="text" class="form-control" name="id" ng-model="data.id" readonly/>          
									</div>
								</div>            
							</div>   
							
							<div class="row">
								<div class="col-md-12">
									
								</div>
							</div>
							
							<div class="row">		
								<div class="col-md-12">
									<apartment-dropdown
										ng-model="data.apartment_id" 
										form-name="enquiryDetailForm" 
										available-by-dates="data.id == 'new'"
										available-start-date="data.checkInDate"
										available-end-date="data.checkOutDate"
										is-new="true"
										title="Apartment">
									</apartment-dropdown>
								</div>
							</div>

							<div class="row">
								
							</div>
							
							<div class="row">
								<div class="col-sm-12">
									<div class="form-group" ng-class="{'has-error': enquiryDetailForm.amount.$invalid && enquiryDetailForm.amount.$touched}">
										<label>Amount</label>
										<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="amount"
											   ng-model="data.amount" required />
										<p ng-show="enquiryDetailForm.amount.$invalid && enquiryDetailForm.amount.$touched" class="help-block">
											Required
										</p>
									</div>
								</div>
							</div>
								   
						</div>  
					</form>
				</div>
				<div class="modal-footer">  
					<div class="row">
						<div class="col-sm-3">
							<my-save-button
								click-fn="saveRecord()"
								show-spinner="showSaveSpinner"												
								ng-disabled="enquiryDetailForm.$invalid"
								ng-show="permissions['bookings.update']"
							></my-save-button>
							
						</div>
						<div class="col-sm-6">
							<my-form-alert				
								show-alert="showAlert"
								alert-type="alertDetails.type"
								alert-message="alertDetails.message"											
							></my-form-alert>											
						</div>
						<div class="col-sm-3">	
							<button class="btn btn-warning btn-lg" type="button" ng-click="cancel()">Cancel</button>
						</div>    
					</div>   
					
				</div>`,	
				resolve: {
					data: function () {
					  return data;
					},
					title: function(){
						return "Add/Edit Enquiry"
					},
					permissions: function(){						
						return permissions;
					}
				},
				controller: function ($uibModalInstance, $scope, data, title, permissions,
										 UtilBroadcastService, $timeout, EnquiryService) {	
					
					$scope.title = title;
					$scope.data = data; 					
					$scope.showSaveSpinner = false;			
					$scope.showAlert = false;
					$scope.alertDetails = {};
					$scope.confirmationSuccessMessage = ($scope.data.id==='new') ? 'created' : 'updated';	
					$scope.permissions = permissions;
					
					
										
					var config = {};	
					
													  
					$scope.saveRecord = function () {	
						$scope.showSaveSpinner = true;
						
						var promise; var message;	
						if( $scope.data.id==='new' ){							
							promise = EnquiryService.postEnquiryDetailStore($scope.data);
							message = 'Record created';			
						} else {
							promise = EnquiryService.postEnquiryDetailUpdate($scope.data.id, $scope.data);
							message = 'Record updated';			
						}
						
						promise.then(function(success){						
							$scope.enquiryDetailForm.$setPristine();		
							UtilBroadcastService.showAlert($scope, 'Success', message, false);	
							if($scope.data.id==='new') $scope.data.id = success.data;
						}, function(error){
							console.log(error);
							UtilBroadcastService.showAlert($scope,'Failure', 'Unable to create/update record.');
						}).finally(function(){
							UtilBroadcastService.broadcastUpdate();							
							$timeout(function() {
								$uibModalInstance.close('close');
							}, 2000);					
						});							
					}
					
					$scope.cancel = function() {
					   $uibModalInstance.dismiss();
					}			
		
				}	
		}
	}		
	return modalEnquiryDetail;
}])
