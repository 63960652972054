angular.module('issues.controllers')

.controller('IssuesOpenReportController', ['IssuesService', '$filter', 'SettingsService', '$state', '$timeout',
    function(IssuesService, $filter, SettingsService, $state, $timeout) {
        var self = this; 
		
		self.dataLoaded = false;
		self.report_data = {'data': 'no'};
		self.showSpinner = false;
		self.showGetSpinner = false;
		self.dates = {};
		self.issues = [];	
		var config = {	params: {}	};
		
				
		self.getIssues = function(type){
			self.dataLoaded = false;	
			self.showGetSpinner = true;		
			IssuesService.getIssuesOpenReport(config, type).then(function(success){
				self.issues = success.data;	
			}, function(error){
				console.log(error);
			}).finally(function() {
				self.dataLoaded = true;
				self.showGetSpinner = false;
			});
		}
		
		self.getIssues('new');
		
		
		self.print = function(){			
			self.showSpinner = true;
			self.report_data= {
				"template": { "shortid" : "ByOci2ZES" },				
				"data": self.issues				
				
		   };  
		   $timeout(function() {
				self.showSpinner = false;				
			}, 1000);
		}
		
				
		
	}
])