angular.module('versions.controllers')

.controller('VersionController', ['VersionsService', '$scope', 'UtilBroadcastService', 
    '$stateParams', '$uibModal', '$state', 'ModalConfirmAction', 'ModalFeedback',  
                        'PermissionsService', '$timeout',
        
    function(VersionsService, $scope, UtilBroadcastService, $stateParams, $uibModal, $state, 
                ModalConfirmAction, ModalFeedback, PermissionsService, $timeout) {
    var self = this;    
    
    self.dataLoaded = false;
    self.title='Version';
    self.version;   
    self.showSaveSpinner = false;   
    self.showAlert = false;
    self.alertDetails = {};
    var id = $stateParams.id;
    self.isNew = (id=='add-new');
    self.permissions = [];
    var config = {  params: {}  };
    
    PermissionsService.getVersion().then(function(success){            
        self.permissions = success.data;
    }, function(error){
        console.log(error);
    });      
   
    function getShow(){
        VersionsService.getShow(id).then(function(success){           
            self.version = success.data;                
            self.dataLoaded = true;         
        }, function(error){
            console.log(error);
        });     
    }
    
    if( !self.isNew ){ 
        getShow(); 
    } else {               
        self.dataLoaded = true;
    }
        
    self.saveRecord = function(){
        self.showSaveSpinner = true; 
        var promise; var message;   
        if( self.isNew ){
            self.showSaveSpinner = true; 
            promise = VersionsService.postStore(self.version);
            message = 'Record created';         
        } else {
            promise = VersionsService.postUpdate(id, self.version);
            message = 'Record updated';         
        }
        
        promise.then(function(success){
            var newId=false;
            if(self.isNew) newId = success.data;
            $scope.versionForm.$setPristine();              
            UtilBroadcastService.showAlert(self, 'Success', message, newId, 'main.version');                                   
        }, function(error){
            console.log(error);
            UtilBroadcastService.showAlert(self,'Failure', 'Unable to create/update record.');
        }).finally(function(){
            UtilBroadcastService.broadcastUpdate();
        }); 
    }
    
    self.deleteRecord = function(){
        var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
            'Warning!', 
            'Are you sure you would like to delete this record? Deletions cannot be undone.', 
            'alert-danger')
        );
        
        modalInstance.result.then(function (msg) {
            VersionsService.getDelete(id).then(function(success){
                var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Record deleted!', 'alert-success', true));                       
                $timeout(function() { 
                    modalInstance1.close('cancel'); 
                    $state.go('main.versions', {}, {reload: true});
                }, 2000);
                
            }, function(error){
                console.log(error);
                var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
                            'Unable to delete record. Please contact a system administrator.', 'alert-danger', true));          
                $timeout(function() { modalInstance1.close('cancel'); }, 2000);
            });
        });
      
    }
    
     
}])

