angular.module('actionslog.controllers', [])

.controller('ActionsLogsController', ['$state', 'ActionsLogService', '$scope',  
		function($state, ActionsLogService, $scope) {
    var self = this;	
  
    self.logs = [];
	self.actions_logs = {};
    self.actions_logs.current_page = 1;        
	self.maxSize = 5;
	self.search_value;
	self.sortASC = true;
    self.sortCriteria = {'field': 'none', 'reverse': false };
    self.dataLoaded = false;
    
    var config = {
		params: { 	
			page:self.actions_logs.current_page, 	
			orderby: 'actions_logs.id',
			sort: 'DESC',
			per_page:30
		}				 
	};
  

    function getActionLogs(){
		self.dataLoaded = false;
		ActionsLogService.getActionsLogsPaginate(config).then(function(success){        
			self.actions_logs = success.data;  			
		}, function(error){
			console.log(error);
		}).finally(function(){
			self.dataLoaded = true;
		});      
    }
    
    getActionLogs();
  
    self.go = function(id){
        $state.go('main.actionslog', {'id': id}, {reload: true});
    }
  
        
    self.pagination = function(){			
		config.params.page = self.actions_logs.current_page;
		getActionLogs();
	}
	
	self.sortTable = function(heading){	
		config.params.orderby = heading;
		config.params.sort = (self.sortASC) ? 'ASC' : 'DESC';	
		self.sortASC = !self.sortASC;
		getActionLogs(); 
	}
	
	self.search = function(){
		self.actions_logs.current_page = 1;
		config.params.page = self.actions_logs.current_page;
		config.params.search = self.search_value;
		getActionLogs();   
	}

    
    
}])