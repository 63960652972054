angular.module('form-controls.directives')

	.factory("ModalShowContact", [function () {

		var modalShowContact = {};


		modalShowContact.showContact = function (data) {
			return {
				template:
					`<form name="contactForm" novalidate>
					<div class="modal-header">
						<h3 class="modal-title">Contact Details</h3>
					</div>
					<div class="modal-body default">           
                    
						<div class="form-group">
							<label>Contact Name</label>
							<input  type="text" class="form-control" name="contact_name" 
								   ng-model="data.contact_name" readonly />               
						</div>

						<div class="form-group">
							<label>Company</label>
							<input  type="text" class="form-control" name="company" 
								   ng-model="data.company" readonly />               
						</div>

						<div class="form-group">
							<label>Address1</label>
							<input  type="text" class="form-control" name="address_1" 
								   ng-model="data.address_1" readonly />               
						</div>

						<div class="form-group">
							<label>Postcode</label>
							<input  type="text" class="form-control" name="postcode" 
								   ng-model="data.postcode" readonly />               
						</div>

						<div class="form-group">
							<label>Telephone Number</label>
							<input  type="text" class="form-control" name="telephone_number" 
								   ng-model="data.telephone_number" readonly />               
						</div>

						<div class="form-group">
							<label>Email Address</label>
							<input  type="text" class="form-control" name="email_address" 
								   ng-model="data.email_address" readonly />               
						</div>
				
					</div>
					<div class="modal-footer">   	
						<div class="form-group">
							<div class="row">
								<div class="col-sm-9"></div>
								<div class="col-sm-3">
									<button type="button" class="btn btn-lg btn-default btn-outline btn-block" 
										ng-click="cancel()">Cancel</button>										
								</div>	
							</div>    
						</div>
					</div>
                </form>`,
				animation: true,
				resolve: {
                    data: function () {
                        return data;
					}
				},
				controller: function ($uibModalInstance, $scope, data) {
					$scope.data = data;

					$scope.cancel = function () {
						$uibModalInstance.dismiss();
					}

				}
			};

		}

		return modalShowContact;
	}])
