angular.module('login-activity', [])

.factory("LoginActivityService", ['$http',
	function($http){
	 
	var loginActivityService = {};

	loginActivityService.getIndex = function(config){
		return $http.get('api/login-activity', config == null ? [] : config);
	}
	
	loginActivityService.getPaginate = function(config){
		return $http.get('api/login-activity/paginate', config == null ? [] : config);
	}
	
	loginActivityService.getShow = function(id){
		return $http.get('api/login-activity/show/'+id);
	}

	return loginActivityService;
}])