angular.module('apartments.modals')

.factory("ModalApartmentDetail", [function(){
	 
	var modalApartmentDetail = {};          
	
	
	modalApartmentDetail.showDetail = function(data, permissions){ 
		return {
                template: 
				`<form name="apartmentDetailsForm" novalidate>
					<div class="modal-header">
						<h3 class="modal-title">Details</h3>
					</div>
					<div class="modal-body default">  				
						<div class="form-group" ng-class="{'has-error': apartmentDetailsForm.detail.$invalid && apartmentDetailsForm.detail.$touched}">
							<label>Item</label>
							<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="detail"
								   ng-readonly="!permissions['apartments.update']"
								   ng-model="data.detail" required/>
							<p ng-show="apartmentDetailsForm.detail.$invalid && apartmentDetailsForm.detail.$touched" class="help-block">
											Required
							</p>
						</div>
						<div class="form-group" ng-class="{'has-error': apartmentDetailsForm.start_date.$invalid && apartmentDetailsForm.start_date.$touched}">
							<label>Start Date</label>
							<p class="input-group">
							<input ng-model="data.start_date" type="text" class="form-control" uib-datepicker-popup="{{'dd-MMM-yyyy'}}" 
								   is-open="start_date_open" datepicker-options="{formatYear: 'yy', startingDay: 1}" 
								   ng-readonly="!permissions['apartments.update']"
								   datepicker-localdate ng-model-options="{timezone: 'utc'}"
								   close-text="Close" alt-input-formats="altInputFormats" 
								   ng-focus="focus=false" ng-blur="focus=true" name="start_date" required/>
							<span class="input-group-btn">
								<button type="button" ng-disabled="!permissions['apartments.update']" class="btn btn-default" ng-click="start_date_open = !start_date_open"><i class="glyphicon glyphicon-calendar"></i></button>
							</span>
							</p>
							<p ng-show="apartmentDetailsForm.start_date.$invalid && apartmentDetailsForm.start_date.$touched" class="help-block">
								Required
							</p>
						</div>
						
						<div class="form-group" ng-class="{'has-error': apartmentDetailsForm.end_date.$invalid && apartmentDetailsForm.end_date.$touched}">
							<label>End Date</label>
							<p class="input-group">
							<input ng-model="data.end_date" type="text" class="form-control" uib-datepicker-popup="{{'dd-MMM-yyyy'}}" 
									ng-readonly="!permissions['apartments.update']"
								   is-open="end_date_open" datepicker-options="{formatYear: 'yy', startingDay: 1}" 
								   close-text="Close" alt-input-formats="altInputFormats" 
								   datepicker-localdate ng-model-options="{timezone: 'utc'}"
								   ng-focus="focus=false" ng-blur="focus=true" name="end_date"/>
							<span class="input-group-btn">
								<button type="button" ng-disabled="!permissions['apartments.update']" class="btn btn-default" ng-click="end_date_open = !end_date_open"><i class="glyphicon glyphicon-calendar"></i></button>
							</span>
							</p>
						</div>
						<div class="form-group" ng-class="{'has-error': apartmentDetailsForm.reference.$invalid && apartmentDetailsForm.reference.$touched}">
							<label>Reference</label>
							<input ng-readonly="!permissions['apartments.update']" ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="value"
								   ng-model="data.reference" required/>
							<p ng-show="apartmentDetailsForm.reference.$invalid && apartmentDetailsForm.reference.$touched" class="help-block">
								Required
							</p>
						</div>    
						<div class="form-group" ng-class="{'has-error': apartmentDetailsForm.value.$invalid && apartmentDetailsForm.value.$touched}">
							<label>Value</label>
							<input ng-readonly="!permissions['apartments.update']" ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="value"
								   ng-model="data.value" required/>
							<p ng-show="apartmentDetailsForm.value.$invalid && apartmentDetailsForm.value.$touched" class="help-block">
								Required
							</p>
						</div>      
						<div ng-show="data.id != 'new'" class="form-group" ng-class="{'has-error': apartmentDetailsForm.file.$invalid && apartmentDetailsForm.file.$touched}">
							<label>Attachment</label>
							<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="attachment"
								ng-readonly="true"
								ng-model="data.attachment"/>						
						</div>  

						<div class="form-group" ng-class="{'has-error': apartmentHandOverForm.file.$invalid && apartmentHandOverForm.file.$touched}">
						
						<label>Add Attachment</label>
							<input type="file" ngf-select ng-model="file" name="file" ngf-pattern="'.docx,.doc,.pdf,.jpg,.png,.txt,.msg,.xls,.xlsx'"
								ngf-accept="'.docx,.doc,.pdf,.jpg,.png,.txt,.msg,.xls,.xlsx'" ngf-max-size="4MB">						
						</div>  

					</div>
					<div class="modal-footer">   	
						<div class="form-group">
							<div class="row">
								<div class="col-sm-3">
									<my-save-button
										click-fn="saveRecord()"
										show-spinner="showSaveSpinner"												
										ng-disabled="apartmentDetailsForm.$invalid"									
									></my-save-button>
									
								</div>
								<div class="col-sm-6">
									<my-form-alert				
										show-alert="showAlert"
										alert-type="alertDetails.type"
										alert-message="alertDetails.message"											
									></my-form-alert>											
								</div>
								<div class="col-sm-3">
									<button type="button" class="btn btn-lg btn-default btn-outline btn-block" 
										ng-click="cancel()">Cancel</button>										
								</div>	
							</div>    
						</div>
					</div>
                </form>`,
				animation: true,      
				resolve: {
					data: function () {
					  return data;
					},
					title: function(){
						return "Apartment Details"
					},
					permissions: function(){
						return permissions;
					}
				},
				controller: function($scope, $uibModalInstance, data, title, permissions, ApartmentsService, UtilBroadcastService, $timeout, Upload) {
					
					$scope.title = title;
					$scope.data =  data;
					$scope.showSaveSpinner = false;			
					$scope.showAlert = false;
					$scope.alertDetails = {};
					$scope.permissions = permissions;	
				
					$scope.saveRecord1 = function () {	
						$scope.showSaveSpinner = true;
						
						var promise; var message;	
						if( $scope.data.id==='new' ){							
							promise = ApartmentsService.postApartmentDetailStore($scope.data);
							message = 'Record created';			
						} else {
							promise = ApartmentsService.postApartmentDetailUpdate($scope.data.id, $scope.data);
							message = 'Record updated';			
						}
						
						promise.then(function(success){						
							$scope.apartmentDetailsForm.$setPristine();		
							UtilBroadcastService.showAlert($scope, 'Success', message, false);	
							if($scope.data.id==='new') $scope.data.id = success.data;
						}, function(error){
							console.log(error);
							UtilBroadcastService.showAlert($scope,'Failure', 'Unable to create/update record.');
						}).finally(function(){
							UtilBroadcastService.broadcastUpdate();							
							$timeout(function() {
								$uibModalInstance.close('close');
							}, 2000);						
						});							
					}

					$scope.cancel = function() {
						$uibModalInstance.dismiss();
					}

					$scope.saveRecord = function () {	
						$scope.showSaveSpinner = true;
						
						var message, url;
						
						if($scope.data.id != 'new'){
							url = 'api/apartment-details/update/'+$scope.data.id;
							message = "Apartment Detail Updated."
						} else {
							url = 'api/apartment-details/store';
							message = "Apartment Detail Stored";
						}

						var requestData = {
							file: $scope.file, 
							'detail': $scope.data.detail,
							'reference': $scope.data.reference,
							'apartment_id': $scope.data.apartment_id,									
							'start_date': $scope.data.start_date,
							'value': $scope.data.value									
						};

						if($scope.data.order_no !== null) requestData.order_no = $scope.data.order_no;
						if($scope.data.end_date !== null) requestData.end_date = $scope.data.end_date;
						
						var upload = Upload.upload({
							url: url,
							data: requestData
						});
						
						
						upload.then(function (resp) {
							if($scope.file) console.log('Success ' + resp.config.data.file.name + 'uploaded. Response: ' + resp.data);
							
							self.sendSpinner = false;					
							
							$scope.apartmentDetailsForm.$setPristine();	
							UtilBroadcastService.showAlert($scope, 'Success', message, false);	
							if($scope.data.id==='new') $scope.data.id = resp.data;					
							
						}, function (resp) {
							console.log('Error status: ' + resp.status);
							UtilBroadcastService.showAlert($scope,'Failure', 'Unable to create/update record.');
						}, function (evt) {
							var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
		
							if($scope.file)console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
						});
						
						upload.finally(function(){
							UtilBroadcastService.broadcastUpdate();	
							$timeout(function() {
								$uibModalInstance.close('close');
							}, 2000);										
						});									
					}
				}

            };
	}	
	
			
	return modalApartmentDetail;
}])