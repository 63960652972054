angular.module('bookings.controllers', [])

    .controller('BookingsController', ['$state', 'BookingsService', 'PermissionsService', 'UtilLookUpService',
        function($state, BookingsService, PermissionsService, UtilLookUpService) {
        var self = this;
        
        self.dataLoaded = false;
        self.bookings = [];        
        self.maxSize = 5;          
		self.permissions = [];
        self.canCreate = false;
		self.canShow = false;
        self.booking_states = UtilLookUpService.getStatusForBookings();
		self.showSpinner = false;
		self.search_value;
        self.sortASC = true;
		self.status = 'active';
        
		var config = {
			params: { 	
				page:self.bookings.current_page, 				
				per_page:30
			}				 
		};
		
		PermissionsService.getByModuleByUser('bookings').then(function(success){   			
            self.permissions = success.data;
        }, function(error){
            console.log(error);
        });      
        
		
        function getBookings(){
			config.params.findby = 'bookings.status';
			config.params.findbyvalue = self.status;
			self.dataLoaded = false;
			BookingsService.getBookingsPaginate(config).then(function(success){
				self.bookings = success.data;		
			}, function(error){
					console.log(error);
			}).finally(function(){
				self.dataLoaded = true;
				self.showSpinner = false;
			}); 
        }

        getBookings();

        self.updateStatus = function(status){
			self.showSpinner = true;
			self.status = status;
			getBookings();
        }

        self.go = function(id){
            $state.go('main.booking', {'id': id}, {});
        }

        self.pagination = function(){			
			config.params.page = self.bookings.current_page;
			getBookings();
		}
		
		self.sortTable = function(heading){
			config.params.orderby = heading;
			config.params.sort = (self.sortASC) ? 'ASC' : 'DESC';	
			self.sortASC = !self.sortASC;
            getBookings();
           
        }
		
		self.search = function(){
			self.bookings.current_page = 1;
			config.params.page = self.bookings.current_page;
			config.params.search = self.search_value;
			getBookings();
		}
    }])