angular.module('schedulers.modals')

.factory("ModalSchedulerShowApartment", [function(){
	 
	var modalSchedulerShowApartment = {};          
	
	
	modalSchedulerShowApartment.show = function(data){ 
		return {
				animation: true, 
				windowClass: 'app-modal-window',
                template: 
				`<div class="modal-header">    
					<div class="col-sm-6">
						<h3 class="modal-title">{{title}}</h3>
					</div>
					<div class="col-sm-3">
						<a ng-click="addBooking()" class="btn btn-info pull-right"><i class="fa fa-plus" aria-hidden="true"></i> Add Booking</a>
					</div>
					<div class="col-sm-3">
						<a ng-click="goToApartment()" class="btn btn-primary pull-right"><i class="fa fa-arrow-right" aria-hidden="true"></i> Go To Apartment</a>
					</div>
				</div>
				<div class="modal-body alert">   
					<h3>General Info</h3>
					<table  class="table"  >							
						<tbody >
							<tr>
								<td><b>ID</b></td>
								<td>{{data.id}}</td>		
								<td><b>Development</b></td>
								<td>{{data.development}}</td>		
							</tr>
							<tr>
								<td><b>Address1</b></td>
								<td>{{data.address1}}</td>
								<td><b>Bed Configuration</b></td>
								<td>{{data.bedconfiguration}}</td>		
							</tr>
							<tr>
								<td><b>Address2</b></td>
								<td>{{data.address2}}</td>
								<td><b>Beds</b></td>
								<td>{{data.beds}}</td>		
							</tr>
							<tr>
								<td><b>Town</b></td>
								<td>{{data.town}}</td>
								<td><b>Baths</b></td>
								<td>{{data.baths}}</td>		
							</tr>
							<tr>
								<td><b>City</b></td>
								<td>{{data.city}}</td>
								<td><b>Floor</b></td>
								<td>{{data.floor}}</td>		
							</tr>
							<tr>
								<td><b>Postcode</b></td>
								<td>{{data.postcode}}</td>
								<td><b>Parking Details</b></td>
								<td>{{data.parking_details}}</td>		
							</tr>
							<tr>
								<td><b>Active Date</b></td>
								<td>{{data.activedate | date:shortDate}}</td>
								<td><b>Inactive Date</b></td>
								<td>{{data.inactivedate | date:shortDate}}</td>		
							</tr>
							
						</tbody>		
					</table>  	
					<h3>Charges</h3>
					<table  class="table">
						<thead>
							<tr>
								<td>Start Night</td>
								<td>End Night</td>		
								<td>Amount</td>		
							</tr>		
						</thead>
						<tbody>
							<tr ng-repeat="item in data.apartment_charges">				
								<td>{{item.start_nights}}</td>		
								<td>{{item.end_nights}}</td>		
								<td>{{item.amount}}</td>		
							</tr>		
						</tbody>		
					</table>    
					
						
				</div>
				<div class="modal-footer">        
					<button class="btn btn-default btn-lg" type="button" ng-click="cancel()">Close</button>
				</div>`,	
				resolve: {
					data: function () {
					  return data;
					},
					title: function(){
						return "Apartment Information"
					}
				},
				controller: function ($uibModalInstance, $scope, data, title) {	
					
					$scope.title = title;
					$scope.data = data; 					
					
					var returnData = {};
   
    
					$scope.goToApartment = function () {
						returnData.type = 'go-to-apartment';
						returnData.id = $scope.data.id;
						$uibModalInstance.close(returnData);				
					};
					
					$scope.addBooking = function () {
						returnData.type = 'add-booking';
						returnData.id = $scope.data.id;
						$uibModalInstance.close(returnData);		
					};
					
					$scope.cancel = function() {
						returnData.type = 'close';
						returnData.id = -1;
						$uibModalInstance.close(returnData);	
					}
				}	
		}
	}		
	return modalSchedulerShowApartment;
}])
