angular.module('users.controllers', [])

.controller('UsersController', ['$state', 'UsersService', 'PermissionsService', 'ModalConfirmAction', '$uibModal', 
		function($state, UsersService, PermissionsService, ModalConfirmAction, $uibModal) {
	var self = this;	
	self.dataLoaded = false;   
	self.users = [];			
	self.users.current_page = 1;        
	self.maxSize = 5;
	self.search_value;
	self.sortASC = true;
	self.permissions = [];
	self.user_states = UsersService.getStatusForUsers();
	self.status = 'active';
	
	var config = {
		params: { 	
			page:self.users.current_page, 	
			orderby: 'users.name',
			per_page:30
		}				 
	};

	PermissionsService.getByModuleByUser('users').then(function(success){   			
		self.permissions = success.data;
	}, function(error){
		console.log(error);
	});      
	
	function getUsers(){
		config.params.findby = 'users.status';
		config.params.findbyvalue = self.status;
		self.dataLoaded = false;  
		UsersService.getUsersPaginate(config).then(function(success){  
			self.users = success.data;		
			self.users.data.map(function(user){
				user.showSpinner = 0;
			});      				
		}, function(error){
			console.log(error);
		}).finally(function(){
			self.dataLoaded = true;
			self.showSpinner = false;
		}); 
	}
	
	getUsers();

	self.updateStatus = function(status){
		self.showSpinner = true;
		self.status = status;
		getUsers();
	}

	self.logMeInAs = function(item){
		
		var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
			'Warning!',
			'Are you sure you would like to log in as user '+item.name+'?',
			'alert-info')
		);

		modalInstance.result.then(function (msg) {
			item.showSpinner = 1;

			UsersService.postLogMeInAs(item.id)
				.then(function(success){
					$state.go('main.dashboard', {}, { reload: true });
				}, function(error){
					console.log(error)
				})
				.finally(function(){
					item.showSpinner = 0;
				});
		});
	}
  
	self.go = function(id){
		$state.go('main.user', {'id': id}, {});
	}
	
	self.pagination = function(){			
		config.params.page = self.users.current_page;
		getUsers();	   
	}
	
	self.sortTable = function(heading){
		config.params.orderby = heading;
		config.params.sort = (self.sortASC) ? 'ASC' : 'DESC';	
		self.sortASC = !self.sortASC;
		getUsers();	   
	}
	
	self.search = function(){
		self.users.current_page = 1;
		config.params.page = self.users.current_page;
		config.params.search = self.search_value;
		getUsers();	   
	}
	
	
}])