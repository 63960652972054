angular
    .module("credit-notes")

    .controller("CreditNoteSummaryDateToDateReportController", [
        "CreditNotesService",
        "$window",
        function (CreditNotesService, $window) {
            var self = this;

            self.dataLoaded = false;
            self.showSpinner = false;
            self.report_data = { data: "no" };
            self.dates = {};
            self.credit_notes = [];
            self.config = {};
            self.config.allocation_type = "non_allocated";

            function defaultDates() {
                var now = moment();
                self.config.end_date = now.format("YYYY-MM-DD");
                self.config.start_date = moment()
                    .year(now.year())
                    .month(now.month() - 6)
                    .date(now.date())
                    .format("YYYY-MM-DD");
                CreditNoteSummaryReport();
            }

            defaultDates();

            function CreditNoteSummaryReport() {
                self.dataLoaded = false;
                CreditNotesService.postCreditNoteSummaryReport(self.config)
                    .then(
                        function (success) {
                            self.credit_notes = success.data;
                        },
                        function (error) {
                            console.log(error);
                        }
                    )
                    .finally(function () {
                        self.dataLoaded = true;
                    });
            }

            self.refreshData = function () {
                CreditNoteSummaryReport();
            };

            self.print = function () {
                self.dataLoaded = false;

                CreditNotesService.postCreditNoteSummaryReportPdf(self.config)
                    .then(
                        function (url) {
                            var printwWindow = $window.open(url);
                        },
                        function (error) {
                            console.log(error);
                        }
                    )
                    .finally(function () {
                        self.dataLoaded = true;
                    });
            };
        },
    ]);