angular.module('dashboard.controllers', [])

.controller('DashboardController', ['PermissionsService', 'DashboardService',
			function(PermissionsService, DashboardService) {
	var self = this;	
	self.permissions = [];	
	self.dashboard = {};
	self.dashboard.current_revenue = '0.00';
	self.dashboard.next_revenue = '0.00';
	self.dashboard.anr_sum_current_month = '0.00';
	self.dashboard.anr_sum_next_month = '0.00';
	self.dashboard.occupancy_current_month = '0.00';
	self.dashboard.occupancy_next_month = '0.00';
	self.dashboard.unpaid_invoices_sum = '0.00';
	self.dashboard.unpaid_invoices_count = '0.00';
	self.dashboard.overdue_invoices_sum = '0.00';
	self.dashboard.overdue_invoices_count = '0.00';
	self.dashboard.overdue_tasks = '0.00';
	self.dashboard.main_held_bookings = '0.00';
	self.dashboard.als = '0.00';
	self.dashboard.invoiced_to_sum = '0.00';
	self.dashboard.invoiced_to_count = '0.00';
	
	var config = {	params: {}	};
		
	PermissionsService.getByModuleByUser('dashboard').then(function(success){   			
		self.permissions = success.data;		
	}, function(error){
		console.log(error);
	}); 

	function getCurrentAnr(){
		var object = {};
		object.start_date = moment().startOf('month').format('YYYY-MM-DD');
		object.end_date = moment().endOf('month').format('YYYY-MM-DD');
		object.type='ANR';
		DashboardService.postBookingFigures(object).then(function(success){
			self.dashboard.anr_current_month = success.data;
		}, function(error){
			console.log(error);
		}); 
	}

	getCurrentAnr();

	function getNextAnr(){
		var object = {};
		object.start_date = moment().add(1, 'months').startOf('month').format('YYYY-MM-DD');
		object.end_date = moment().add(1, 'months').endOf('month').format('YYYY-MM-DD');
		object.type='ANR';
		DashboardService.postBookingFigures(object).then(function(success){
			self.dashboard.anr_next_month = success.data;
		}, function(error){
			console.log(error);
		}); 
	}

	getNextAnr();

	function getCurrentAnrSum(){
		var object = {};
		object.start_date = moment().startOf('month').format('YYYY-MM-DD');
		object.end_date = moment().endOf('month').format('YYYY-MM-DD');
		object.type='ANRSum';
		DashboardService.postBookingFigures(object).then(function(success){
			self.dashboard.anr_sum_current_month = success.data;
		}, function(error){
			console.log(error);
		}); 
	}

	getCurrentAnrSum();

	function getNextAnrSum(){
		var object = {};
		object.start_date = moment().add(1, 'months').startOf('month').format('YYYY-MM-DD');
		object.end_date = moment().add(1, 'months').endOf('month').format('YYYY-MM-DD');
		object.type='ANRSum';
		DashboardService.postBookingFigures(object).then(function(success){
			self.dashboard.anr_sum_next_month = success.data;
		}, function(error){
			console.log(error);
		}); 
	}

	getNextAnrSum();

	function getCurrentOccupancy(){
		var object = {};
		object.start_date = moment().startOf('month').format('YYYY-MM-DD');
		object.end_date = moment().endOf('month').format('YYYY-MM-DD');
		object.type='OCCPerMonth';
		DashboardService.postBookingFigures(object).then(function(success){
			self.dashboard.occupancy_current_month = success.data[0].occupancy*100;			
		}, function(error){
			console.log(error);
		}); 
	}

	getCurrentOccupancy();

	function getNextOccupancy(){
		var object = {};
		object.start_date = moment().add(1, 'months').startOf('month').format('YYYY-MM-DD');
		object.end_date = moment().add(1, 'months').endOf('month').format('YYYY-MM-DD');
		object.type='OCCPerMonth';
		DashboardService.postBookingFigures(object).then(function(success){
			self.dashboard.occupancy_next_month = success.data[0].occupancy*100;			
		}, function(error){
			console.log(error);
		}); 
	}

	getNextOccupancy();

	function getUnpaidInvoicesSum(){
		var object = {};
		object.type='sum';

		DashboardService.postUnpaidInvoices(object).then(function(success){
			self.dashboard.unpaid_invoices_sum = success.data;
		}, function(error){
			console.log(error);
		}); 
	}

	getUnpaidInvoicesSum();

	function getUnpaidInvoicesCount(){
		var object = {};
		object.type='count';

		DashboardService.postUnpaidInvoices(object).then(function(success){
			self.dashboard.unpaid_invoices_count = success.data;
		}, function(error){
			console.log(error);
		}); 
	}

	getUnpaidInvoicesCount();

	function getOverdueInvoicesSum(){
		var object = {};
		object.type='sum';

		DashboardService.postOverdueInvoices(object).then(function(success){
			self.dashboard.overdue_invoices_sum = success.data;
		}, function(error){
			console.log(error);
		}); 
	}

	getOverdueInvoicesSum();

	function getOverdueInvoicesCount(){
		var object = {};
		object.type='count';

		DashboardService.postOverdueInvoices(object).then(function(success){
			self.dashboard.overdue_invoices_count = success.data;
		}, function(error){
			console.log(error);
		}); 
	}

	getOverdueInvoicesCount();

	function getOverdueTasks(){
		
		DashboardService.getOverdueTasks().then(function(success){
			self.dashboard.overdue_tasks = success.data;
		}, function(error){
			console.log(error);
		}); 
	}

	getOverdueTasks();

	function getUninvoicedBookingsValue(){
		DashboardService.getUninvoicedBookingsValue().then(function(success){
			self.dashboard.invoiced_to_sum = success.data.invoiced_to_sum;
			self.dashboard.invoiced_to_count = success.data.invoiced_to_count;
		}, function(error){
			console.log(error);
		}); 
	}

	getUninvoicedBookingsValue();

	function getCurrentRevenue(){
		var object = {};
		object.start_date = moment().add(0, 'months').startOf('month').format('YYYY-MM-DD');
		object.end_date = moment().add(0, 'months').endOf('month').format('YYYY-MM-DD');
		object.type='TRPerMonth';
		DashboardService.postBookingFigures(object).then(function(success){
			self.dashboard.current_revenue = success.data[0].total_revenue_net;			
		}, function(error){
			console.log(error);
		}); 
	}

	getCurrentRevenue();

	function getNextRevenue(){
		var object = {};
		object.start_date = moment().add(1, 'months').startOf('month').format('YYYY-MM-DD');
		object.end_date = moment().add(1, 'months').endOf('month').format('YYYY-MM-DD');
		object.type='TRPerMonth';
		DashboardService.postBookingFigures(object).then(function(success){
			self.dashboard.next_revenue = success.data[0].total_revenue_net;			
		}, function(error){
			console.log(error);
		}); 
	}

	getNextRevenue();

	function getAls(){
		DashboardService.postAverageLengthOfStay().then(function(success){
			self.dashboard.als = success.data;
		}, function(error){
			console.log(error);
		}); 
	}

	getAls();



	
}]);