angular.module('issues.directives', [])

	.directive('issueDetails', function ($rootScope, IssuesService, $uibModal, ModalFeedback) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				issueId: '=',					
				permissions: '='
			},      
			template:	`<div style="max-height: 500px; overflow-y: scroll;">
							<div ng-show="!dataLoaded" class="row text-center">
								<span ><i class="fa fa-cog fa-spin" style="font-size:48px"></i></span>
							</div>
							<div ng-show="dataLoaded">
								<table class="table"  >
										<th>User</th>
										<th>Note</th>
										<th>Created At</th>              
										
									<tr ng-repeat="item in issue_details">                               
										<td>{{item.name}}</td>
										<td>{{item.notes}}</td>
										<td>{{item.created_at | fullDateFormatted}}</td>                        
										
									</tr>
									<tr ng-if="permissions['issues.create']">
										<td colspan="3">
											<div class="form-group">                                        
												<textarea ng-focus="focus=false" 
													   ng-blur="focus=true" 
													   type="text" 
													   class="form-control" 
													   name="note"
													   placeholder="Create Note"
													   ng-model="issue_detail.notes" /></textarea>
											</div>
										</td>              
									</tr>
									<tr ng-if="permissions['issues.create']">  										
										<td colspan="3" ><a href="" ng-click="createIssueDetail()" class="btn btn-success btn-md btn-block">
											<span ng-show="showSpinner === 1"><i class="fa fa-cog fa-spin" style="font-size:16px"></i>
											</span>
											<span ng-show="showSpinner !== 1" >Add Note</span></a>
										</td>										
									</tr>
								</table>
							</div>
						</div>`,
			link: function (scope, element, attr) {
				
				const id = scope.issueId;
				scope.dataLoaded = false;
				scope.issue_details = [];
				scope.issue_detail = {};
				scope.showSpinner = 0;				
				
				function getIssueDetails(){
					IssuesService.getDetailByIssue(id).then(function(success){
						scope.issue_details = success.data;
					}, function(error){
						console.log(error);
					}).finally(function(){
						scope.dataLoaded = true;
					});
				}

				getIssueDetails();	
				
				function broadcastUpdate(){
					console.log('broadcasting update');
					$rootScope.$broadcast('updateSidebars', {
						data: true
					});
				}
				
				scope.$on('updateSidebars', function (event, result) {					
					getIssueDetails();	
				});
				
				scope.createIssueDetail = function(){
					scope.showSpinner = 1;
					scope.issue_detail.issue_id = id;
					IssuesService.postDetailStore(scope.issue_detail).then(function(success){ 					
						scope.showSpinner = 0;
						scope.issue_detail = {};
					}, function(error){
						console.log(error);
						var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
							'Unable to store detail. Please contact a system administrator.', 'alert-danger'));			
							$timeout(function() { modalInstance1.close('cancel'); }, 2000);
					}).finally(function(){
						scope.showSpinner = 0;
						broadcastUpdate();
					});				   
				}
			}
		};
	})