angular.module('angular-utils.directives')

.directive('mySubFormButton', function () {
	return {
		restrict : 'EAC',
		replace : true,
		scope :{
			clickFn: '&',
			buttonType: '@'
		},      
		template:	'<a href="" id="mySubFormButton" ng-click="clickMe()"' +
						'class="btn  btn-outline btn-sm">' +
						'<i  aria-hidden="true"></i>' +
					'</a>',
		link: function (scope, element, attr) {
			var icon = angular.element(element.children()[0]);
			
			if(scope.buttonType == 'edit') {
				element.addClass('btn-primary');
				icon.addClass('fa fa-binoculars');
			}
			if(scope.buttonType == 'add') {
				element.addClass('btn-success');
				icon.addClass('fa fa-plus-square');
			}
			if(scope.buttonType == 'delete') {
				element.addClass('btn-danger');
				icon.addClass('fa fa-trash');
			}

			scope.clickMe = function() {					
				scope.clickFn();
			}
		}
	};
})