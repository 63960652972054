angular.module('invoices.directives')

	.directive('invoiceBookings', function (BookingsService) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				bookingId: '='
			},      
			template:	`<div class="panel panel-default" >
							<div class="panel-heading">
								<h3 class="panel-title"><i class="fa fa-clock-o fa-fw"></i> Booking Details</h3>
							</div>
							<div class="panel-body">	
								<div ng-show="!dataLoaded" class="row text-center">
									<span ><i class="fa fa-cog fa-spin" style="font-size:48px"></i></span>
								</div>
								<table ng-show="dataLoaded" class="table"  >							
									<tbody >
										<tr>
											<td><b>ID</b></td>
											<td>{{booking.id}}</td>
										</tr>
										<tr>
											<td><b>Client</b></td>
											<td>{{booking.organisation_name}}</td>
										</tr>
										<tr>
											<td><b>Guest</b></td>
											<td>{{booking.contact_name}}</td>
										</tr>
										<tr>
											<td><b>Charge Per Night</b></td>
											<td>{{booking.charge_per_night}}</td>
										</tr>
										<tr>
											<td><b>Check In Date</b></td>
											<td>{{booking.check_in_date | date:shortDate}}</td>
										</tr>
										<tr>
											<td><b>Check Out Date</b></td>
											<td>{{booking.check_out_date | date:shortDate}}</td>
										</tr>
										<tr>
											<td><b>Status</b></td>
											<td>{{booking.status | capitalize}}</td>
										</tr>
									</tbody>									
								</table>                
							</div>
						</div>`,
			link: function (scope, element, attr) {
				
				
				var	id = scope.bookingId;
				scope.dataLoaded = false;
				scope.booking = {};	
								
				function getBooking(){		
					scope.dataLoaded = false;
					BookingsService.getBooking(id).then(function(success){
						scope.booking = success.data;						
					}, function(error){
						console.log(error);
					}).finally(function(){
						scope.dataLoaded = true;
					});
				}
				
				getBooking();

				scope.$watch('bookingId', function (event, result) {
					id = scope.bookingId;
					getBooking();
				});				
				
			}
		};
	})