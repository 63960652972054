angular.module('organisations.modals', [])

    .controller('ModalContactOrganisationController', ['$scope', 'data', 'title', 'permissions', '$uibModalInstance',
        'UtilBroadcastService', '$timeout', 'OrganisationsService',
        function ($scope, data, title, permissions, $uibModalInstance,
            UtilBroadcastService, $timeout, OrganisationsService) {

            $scope.title = title;
            $scope.data = data;
            $scope.showSaveSpinner = false;
            $scope.showAlert = false;
            $scope.alertDetails = {};
            $scope.permissions = permissions;


            var config = {};

            $scope.saveRecord = function () {
                $scope.showSaveSpinner = true;
 
                var promise; var message;
                if ($scope.data.id === 'new') {
                    promise = OrganisationsService.postContactOrganisationStore($scope.data);
                    message = 'Record created';
                } else {
                    promise = OrganisationsService.postContactOrganisationUpdate($scope.data.id, $scope.data);
                    message = 'Record updated';
                }

                promise.then(function (success) {
                    $scope.contactOrganisationForm.$setPristine();
                    UtilBroadcastService.showAlert($scope, 'Success', message, false);
                    if ($scope.data.id === 'new') $scope.data.id = success.data;
                }, function (error) {
                    console.log(error);
                    UtilBroadcastService.showAlert($scope, 'Failure', 'Unable to create/update record.');
                }).finally(function () {
                    UtilBroadcastService.broadcastUpdate();
                    $timeout(function () {
                        $uibModalInstance.close('close');
                    }, 2000);
                });
            };

            $scope.cancel = function () {
                $uibModalInstance.dismiss();
            }

        }
    ])

