angular
    .module("credit-notes")

    .directive("creditNoteRelatedInvoice", function (InvoicesService) {
        return {
            restrict: "EAC",
            replace: true,
            scope: {
                invoiceId: "=",
                relatedInvoice: "="
            },
            template: `<div class="panel panel-default" >
							<div class="panel-heading">
								<h3 class="panel-title"><i class="fa fa-clock-o fa-fw"></i> Related Invoice</h3>
							</div>
							<div class="panel-body">	
								<div ng-show="!dataLoaded" class="row text-center">
									<span ><i class="fa fa-cog fa-spin" style="font-size:48px"></i></span>
								</div>
								<table ng-show="dataLoaded" class="table"  >							
									<tbody >
										<tr>
											<td><b>Number</b></td>
											<td>{{invoice.invoice_number}}</td>
										</tr>
										<tr>
											<td><b>Date</b></td>
											<td>{{invoice.invoice_date | date:shortDate}}</td>
										</tr>
										<tr>
											<td><b>Start Date</b></td>
											<td>{{invoice.start_date | date:shortDate}}</td>
										</tr>
										<tr>
											<td><b>End Date</b></td>
											<td>{{invoice.end_date | date:shortDate}}</td>
										</tr>
										<tr>
											<td><b>SubTotal</b></td>
											<td>{{invoice.subtotal}}</td>
										</tr>
										<tr>
											<td><b>VAT</b></td>
											<td>{{invoice.vat}}</td>
                                        </tr>
                                        <tr>
											<td><b>Total</b></td>
											<td>{{invoice.total}}</td>
										</tr>
										<tr>
											<td><b>Status</b></td>
											<td>{{invoice.status | capitalize}}</td>
										</tr>
									</tbody>									
								</table>                
							</div>
						</div>`,
            link: function (scope, element, attr) {
                scope.dataLoaded = true;

                var id = scope.invoiceId;
                scope.dataLoaded = false;
                scope.invoice = {};

                function getInvoice() {
                    scope.dataLoaded = false;
                    InvoicesService.getInvoice(id)
                        .then(
                            function (success) {
                                scope.invoice = success.data;
                                scope.relatedInvoice = success.data;
                            },
                            function (error) {
                                console.log(error);
                            }
                        )
                        .finally(function () {
                            scope.dataLoaded = true;
                        });
                }

                getInvoice();

                scope.$watch("bookingId", function (event, result) {
                    id = scope.invoiceId;
                    getInvoice();
                });
            },
        };
    });
