angular.module('invoices.controllers')

.controller('InvoiceSummaryUnpaidDateToDateReportController', ['InvoicesService', '$filter', 'SettingsService', '$state', '$timeout', 'REPORT_DETAILS',
    function(InvoicesService, $filter, SettingsService, $state, $timeout, REPORT_DETAILS) {
        var self = this; 
		
		self.dataLoaded = false;
		self.report_data = {'data': 'no'};
		self.showSpinner = false;
		self.dates = {};
		self.invoices = [];	
		self.report_details = REPORT_DETAILS.reportInvoicesUnpaid();
		
		function defaultDates(){
			var now = moment(); 
			self.dates.end_date = now.format('YYYY-MM-DD');
			self.dates.start_date = moment().year(now.year()).month(now.month()-1).date(now.date()).format('YYYY-MM-DD');	
			InvoiceSummaryReport();
		}
	
		defaultDates();
		
		function InvoiceSummaryReport(){
			self.dataLoaded = false;
			InvoicesService.postInvoiceSummaryUnpaidReport(self.dates).then(function(success){				
				self.invoices = success.data;				
			}, function(error){
				console.log(error);
			}).finally(function() {
				self.dataLoaded = true;
			});
		}
		
		self.refreshData = function(){			
			InvoiceSummaryReport();			
		}		
		
		
		self.print = function(){
			self.showSpinner = true;
			self.report_data= {
				"template": { "shortid" : "S1W5LzSDl" },				
				"data": self.invoices
			};  
			$timeout(function() {
				self.showSpinner = false;				
			}, 2000);
		}
		
		self.goToInvoice = function(item){
			$state.go('main.invoice', {'id': item.invoice_id}, {reload: true});
		}
		
		self.goToClient = function(item){			
			$state.go('main.client', {'id': item.organisation_id}, {reload: true});
		}
				
		
		
	}
])