angular.module('invoice-templates')

	.directive('invoiceTemplates', function ($rootScope, InvoiceTemplatesService, $uibModal, 
									ModalConfirmAction, ModalFeedback, $timeout, ModalConfigurationService) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				bookingId: '=',					
				showAlert: '=',
				permissions: '='
			},      
			template:	`<div>
							<div ng-show="!dataLoaded" class="row text-center">
								<span ><i class="fa fa-cog fa-spin" style="font-size:48px"></i></span>
							</div>
							<div ng-show="dataLoaded">
								<table class="table"  >
										<th>Description</th>										
										<th>Total</th>
										<th>VAT</th>
										<th>VAT Rate</th>
										<th colspan="2" ng-if="permissions['bookings.show'] || permissions['bookings.delete']" style="width: 10%"><div class="text-center">Action</div></th>
									<tr ng-repeat="item in invoice_templates">                               
										<td>{{item.description}}</td>
										<td>{{item.total}}</td>
										<td>{{item.vat}}</td>
										<td>{{item.vat_rate}}</td>
										<td><my-sub-form-button click-fn="showInvoiceTemplate(item)" 
											ng-if="permissions['bookings.show']" 
											button-type="edit"></my-sub-form-button></td>
										<td><my-sub-form-button 
											ng-if="permissions['bookings.delete']" 
											click-fn="deleteInvoiceTemplate(item)" 
											button-type="delete"></my-sub-form-button>
										</td>            										
									</tr>                            
								</table>
								<div class="pull-right">
									<my-sub-form-button ng-if="permissions['invoices.create']"  
												click-fn="createInvoiceTemplate()" 
												button-type="add"></my-sub-form-button>
								</div>
							</div>
						</div>`,
			link: function (scope, element, attr) {				
				
				var invoiceTemplateData;
				var config = {	params: {}	};
				config.params.findby = 'booking_id';
				config.params.findbyvalue = scope.bookingId;

				scope.dataLoaded = false;
				scope.chargePerNight = 0;
				scope.invoice_templates = [];					
				
				function updateAlert(value){
					setTimeout(function(){
						scope.$apply(function(){
							scope.showAlert=value;							
						});		
					}, 500);						
				}
				
				function getInvoiceTemplates(){
					InvoiceTemplatesService.getIndex(config).then(function(success){
						scope.invoice_templates = success.data;					
						updateAlert(scope.invoice_templates.length==0);		
					}, function(error){
						console.log(error);
					}).finally(function(){						
						scope.dataLoaded = true;
					});
				}				
			
				getInvoiceTemplates();	

				
								
				function broadcastUpdate(){
					console.log('broadcasting update');
					$rootScope.$broadcast('updateSidebars', {
						data: true
					});
				}
				
				scope.$on('updateSidebars', function (event, result) {					
					getInvoiceTemplates();
				});
				
				scope.$watch('bookingId', function(newVal, oldVal){
					if(newVal != oldVal){
						getInvoiceTemplates();	
					}
				});
				
				function showInvoiceTemplate(){
					var modalInstance = ModalConfigurationService.getInvoiceTemplate(invoiceTemplateData, scope.permissions);
					
					modalInstance.result.then(function (data) {
						getInvoiceTemplates();     
					});
				}
				
				scope.showInvoiceTemplate = function(item){
					invoiceTemplateData = item;					
					showInvoiceTemplate();					
				}
				
				scope.createInvoiceTemplate = function(){
					invoiceTemplateData = {
						id: 'new', 
						booking_id: scope.bookingId, 
						description: 'Apartment for {duration} nights at £{charge-per-night} per night', 
						total: '{total}', 
						vat: '{vat}',
						vat_rate: '{vat-rate}'
					};
					showInvoiceTemplate();
				}
				
				scope.deleteInvoiceTemplate = function(item){
					var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
						'Warning!', 
						'Are you sure you would like to delete this record? Deletions cannot be undone.', 
						'alert-danger')
					);
					
					modalInstance.result.then(function (data) {						
						InvoiceTemplatesService.getDelete(item.id).then(function(success){                      
							var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Record deleted!', 'alert-success'));						
							$timeout(function() { modalInstance1.close('cancel'); }, 2000);
							broadcastUpdate();								
						}, function(error){
							console.log(error);
							var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
										'Unable to delete record. Please contact a system administrator.', 'alert-danger'));			
							$timeout(function() { modalInstance1.close('cancel'); }, 2000);
						});
						modalInstance.close('cancel');            
					});
				}				
				
				
			}
		};
	})