angular.module('schedulers.controllers', [])

.controller('SchedulerController', ['SchedulersService', 'UtilService',
				'$uibModal', 'BookingsService', 'GoogleMapsService', 'ApartmentsService',
				'$scope', 'CleaningsService', 'DevelopmentsService', '$state', 
				'PermissionsService', 'AreasService', 'ModalSchedulerAddEditClean', 'ModalSchedulerMultipleClean',
				'ModalSchedulerDeleteClean', 'ModalSchedulerAddEnquiry', 
				'ModalSchedulerShowApartment', 'ModalConfigurationService', 'UtilLookUpService',
        function(SchedulersService, UtilService, $uibModal, BookingsService, 
					GoogleMapsService, ApartmentsService, $scope, 
					CleaningsService, DevelopmentsService, $state, PermissionsService, AreasService, 
					ModalSchedulerAddEditClean, ModalSchedulerMultipleClean, ModalSchedulerDeleteClean, 
					ModalSchedulerAddEnquiry, ModalSchedulerShowApartment, ModalConfigurationService, UtilLookUpService) {
    
        var self = this;
		var config = {};
		var no_of_days = 34;
		var any = {'id': -1, 'name': '--Any--'};
        self.dataLoaded = false;
		self.runTicker = true;
        self.dates = {};
        self.data = [];
		self.bank_holidays = [];
		self.resetCurrentPage = -1;
		self.showCleaning = 1;
		self.organisations = [];
		self.contacts = [];	
		self.developmentsFull = [];
		self.developments = [];
		self.areas = [];
		self.beds = [];
		self.query = {};
		self.viewBookingID=-1;	
		self.viewCleaningID=-1;			
		self.viewApartmentData={};		
		self.selectedApartmentData={};
		self.permissions = [];
		
		 /*Modal drop downs*/
        self.apartments = [];     
        self.booking_types = UtilLookUpService.getTypeForBookings();
		self.cleaning_types = CleaningsService.getTypeForCleaning();  
		self.query_run = false;

		PermissionsService.getByModuleByUser('bookings|invoices|cleanings').then(function(success){   			
			self.permissions = success.data;			
		}, function(error){
			console.log(error);
		});  
		
		/*************Get Apartments***********/
		function getApartments(){
			config.params = {};	
			config = {	params: { 	orderby: 'apartments.address1', dropdown: true	}  };
			ApartmentsService.getApartments(config).then(function(success){     		
				self.apartments = success.data;
			}, function(error){
				console.log(error);
			});
		}
		
		getApartments();
		
		function getAreas(){
			var config = {params: {}};
			config.params.orderby = 'areas.area';
			config.params.findby = 'status';
			config.params.findbyvalue = 'active';
			config.params.with = 'with_developments';
			AreasService.getAreas(config).then(function(success){							
				self.areas = success.data;
				self.areas.unshift({'id': -1, 'area': '--Any--'});				
			}, function(error){
				console.log(error);
			});
		}
		
		getAreas();
		
		self.selectArea = function(){			
			if(self.query.area == -1){
				updateDevelopments(self.developmentsFull);
			} else {
				var developments = UtilService.findObjectByKey(self.areas, 'id', self.query.area).developments;
				updateDevelopments(developments);
			}		
			self.query.development = -1;
		}
		
		function updateDevelopments(developments){
			self.developments = developments;      				
			self.developments.unshift(any);
		}
		
		function getDevelopments(){
			config.params = {};	
			config = {	params: { 	orderby: 'developments.name', findby: 'status', findbyvalue: 'active', dropdown: true	}  };	
			DevelopmentsService.getDevelopments(config).then(function(success){     
				self.developmentsFull = success.data;      				
				updateDevelopments(success.data);
			}, function(error){
				console.log(error);
			});
		}
		
		getDevelopments();
		
		function getBeds(){			
			self.beds.push(any);
			for(var i=1; i<4; i++){
				var obj = {'id': i, 'name': i};
				self.beds.push(obj);
			}			
			var obj = {'id': 'Studio', 'name': 'Studio'};
			self.beds.push(obj);
		}
        
		getBeds();
        
        function defaultDates(){			
			var now = new Date();			
			self.dates.start_date = now;
            var d = new Date();            
            d.setDate(d.getDate() + no_of_days);
			d.setHours(0,0,0,0);
            self.dates.end_date = d;
        }
        
        defaultDates();
		
		function initQuery(){
			self.query.development = -1;
			self.query.area = -1;
			self.query.beds = -1;
		}
		
		initQuery();
        
        function getData(){  				
			var optional = [];
			var parameters = {};			
			parameters.dates = self.dates;
			parameters.optional = self.query;
            SchedulersService.postShowNew(parameters).then(function(success){
                self.data = success.data;					
                self.dataLoaded = true; 				
            }, function(error){
                console.log(error);
            });
        }
        
        getData();
		
		/***********************************/
				/*Navigation Buttons*/
        /***********************************/
        self.backDouble = function(){            
            var d = new Date(self.dates.start_date);            
            d.setMonth(d.getMonth() - 1);         
            self.dates.start_date = d;
            
            var e = new Date();      
			e.setTime( d.getTime() + no_of_days * 86400000 );     
            self.dates.end_date = e;
            getData();
        }
        
        self.backSingle = function(){            
            var d = new Date(self.dates.start_date);            
            d.setDate(d.getDate()-1);            
            self.dates.start_date = d;
            
           var e = new Date();      
			e.setTime( d.getTime() + no_of_days * 86400000 );       
            self.dates.end_date = e;
            getData();
        }
        
        self.forwardSingle = function(){            
            var d = new Date(self.dates.start_date);            
            d.setDate(d.getDate()+1);            
            self.dates.start_date = d;
            
            var e = new Date();      
			e.setTime( d.getTime() + no_of_days * 86400000 );          
            self.dates.end_date = e;
            getData();
        }
        
        self.forwardDouble = function(){            
            var d = new Date(self.dates.start_date);            
            d.setMonth(d.getMonth() + 1);         
            self.dates.start_date = d;
            
            var e = new Date();      
			e.setTime( d.getTime() + no_of_days * 86400000 );         
            self.dates.end_date = e;
            getData();
        }
        
        self.dateChange = function(){   			
			var d = new Date(self.dates.start_date);                      
            self.dates.start_date = d;
            
            var e = new Date();      
			e.setTime( d.getTime() + no_of_days * 86400000 );     
            self.dates.end_date = e;
            getData();
        }
		
		self.home = function(){			
			defaultDates();
			getData();
			var container = document.getElementById('stageContainer');
			container.scrollLeft = 0;
		}
		
		/****************************/
				/* Query */
		/****************************/
		
		self.runQuery = function(){					
			self.query_run = true;
			self.resetCurrentPage = 1;
			if(self.query.start_date != null){
				self.dates.start_date = self.query.start_date;
				self.dateChange();
			}
			
			if(self.query_location != null){
				GoogleMapsService.getGeocode(self.query_location).then(function(success){
					self.query.lat = success.data.results[0].geometry.location.lat;
					self.query.lng = success.data.results[0].geometry.location.lng;                
					getData();					
				}, function(error){
					console.log(error);
				});
			} else {
				getData();
			}
			
		}
		
		self.removeQuery = function(){
			self.resetCurrentPage = 1;
			self.query = {};
			initQuery();
			self.query_location = null;
			getData();
		}
		
		self.getLatLng = function(){            
            GoogleMapsService.getGeocode(self.query_location).then(function(success){
                self.query.lat = success.data.results[0].geometry.location.lat;
                self.query.lng = success.data.results[0].geometry.location.lng;                
                getData();                
            }, function(error){
                console.log(error);
            });
        }
		
		self.updateEndDateAndDuration = function(){	
			if(self.query.start_date!=null && self.query.end_date == null){				
				self.query.end_date = self.query.start_date;
				return;
			}
			
			if(self.query.end_date != null && self.query.start_date!=null){				
				self.query.duration = UtilService.getDateDiff(self.query.start_date, self.query.end_date);
				return;
			}
			
			if(self.query.start_date != null && self.query.duration!=null){				
				self.query.end_date = UtilService.addDate(self.query.start_date, self.query.duration);
				return;
			}
		
			return;
		}
		
		self.updateStartDateAndDuration = function(){	
			
			if(self.query.end_date != null && self.query.start_date!=null){				
				self.query.duration = UtilService.getDateDiff(self.query.start_date, self.query.end_date);
				return;
			}
			
			if(self.query.end_date != null && self.query.duration!=null){				
				self.query.start_date = UtilService.addDate(self.query.end_date, self.query.duration*-1);
				return;
			}
		
			return;
		}
		
		self.updateStartDateAndEndDate = function(){				
			if(self.query.start_date != null && self.query.duration!=null){
				self.query.end_date = UtilService.addDate(self.query.start_date, self.query.duration);
				return;
			}
			
			if(self.query.end_date != null && self.query.duration!=null){
				self.query.start_date = UtilService.addDate(self.query.end_date, self.query.duration*-1);
				return;
			}
		
			return;
		}
				
		/****************************/
				/* Enquiry */
		/****************************/
		
		self.addEnquiry = function(){		
			var apartmentsData = [];
			var data = self.data.data
			
			for(var i=0; i<data.length; i++){
				var item = data[i];
				var object = {id: item.id, address1: item.address1, area: item.area, beds:item.beds, charge: item.charge};
				apartmentsData.push(object);
			}
		
			var modalInstance = $uibModal.open(
				ModalSchedulerAddEnquiry.AddEnquiry(self.query, apartmentsData, self.permissions)
			);		
			
			modalInstance.result.then(function (result) {                        
				
			}, function(error){
				console.log(error);                        
			}).finally(function() {						
				console.log('finally');
			});
			
			modalInstance.close('cancel');	
			
		}
		
		
		
		
		/****************************/
			/* Full Booking */
		/****************************/		
		
		function showBookingModalFull(data){
		
				var modalInstance = ModalConfigurationService.getSchedulerBooking(data, self.permissions);

                modalInstance.result.then(function (data) {  					
					if(data == 'close'){
						getData();		
					} else {
						$state.go('main.booking', {'id': data}, {});							
					}
				}, function(error){
					console.log(error);                        
                }).finally(function() {
					self.runTicker = true;						
				});
                modalInstance.close('cancel');
		}
		
		function addNewBookingFull(){
			data = {};
			data.id = 'new';		
			data.show_booking_start_on_schduler = 1;
			if(self.query.start_date != null) data.check_in_date = self.query.start_date;
			if(self.query.end_date != null) data.check_out_date = self.query.end_date;
			if(self.query.end_date != null && self.query.start_date != null) {
				data.duration = UtilService.getDateDiff(self.query.start_date, self.query.end_date);
			}
			if(self.selectedApartmentData.charge != -1 && self.selectedApartmentData.charge != null) data.charge_per_night = self.selectedApartmentData.charge;	
			if(self.selectedApartmentData.id != null) data.selectedApartmentID = data.apartment_id = self.selectedApartmentData.id;	
				
			data.bookingdetails = [];			
			showBookingModalFull(data);		
		}
		
		self.btnAddNewBooking = function(){			
			self.selectedApartmentData = {};
			addNewBookingFull();
		}
		
		$scope.$watch(function () {
		   return self.viewBookingID;
		},function(newValue, oldValue){
			if(newValue !== -1 && newValue !==oldValue){
				BookingsService.getBookingWithBookingDetails(newValue).then(function(success){     
					data = success.data;
					showBookingModalFull(data);					
				}, function(error){
					console.log(error);
				});
				self.viewBookingID = -1;
			}
		});
		
		/****************************/
			/* Show Clean */
		/****************************/		
		
		function showCleaningModal(data){
			var permissions = {};
			permissions['cleanings.create'] = self.permissions['cleanings.create'];
			permissions['cleanings.update'] = self.permissions['cleanings.update'];
			permissions['cleanings.delete'] = self.permissions['cleanings.delete'];
			permissions['cleanings.show'] = self.permissions['cleanings.show'];
			
			var modalInstance = $uibModal.open(ModalSchedulerAddEditClean.AddEditClean(data, self.apartments, permissions));
			
			modalInstance.result.then(function(data){  					
				if(data == 'close'){
					getData();		
				} else {
					$state.go('main.cleaning', {'id': data}, {});					
				}
			}, function(error){
				console.log(error);                        
			});			
			
			modalInstance.close('cancel');
		}
		
		self.addNewCleaning = function(booking_detail){			
			data = {}; data.id = 'new';			
			if(booking_detail != null) data.apartment_id = booking_detail.apartment_id;				
			showCleaningModal(data);	
		}
		
		self.multipleCleans = function(booking_detail){	
			data = {};
			if(booking_detail != null){
				data.apartment_id = booking_detail.apartment_id;
				data.start_date = booking_detail.check_in_date;
				data.end_date = booking_detail.check_out_date;				
			}
			showMulitpleCleaningModal(data);	
		}
		
		$scope.$watch(function () {
		   return self.viewCleaningID;
		},function(newValue, oldValue){
			data = {};	data.id = newValue;
			if(newValue !== -1 && newValue !==oldValue){
				if(self.permissions['cleanings.show']) showCleaningModal(data);				
				self.viewCleaningID = -1;
			}
		});
		
		function showMulitpleCleaningModal(data){
			var modalInstance = $uibModal.open(
				ModalSchedulerMultipleClean.AddMultipleClean(data, self.apartments, self.permissions)
			);		
			
			modalInstance.result.then(function (data) {                        
				
			}, function(error){
				console.log(error);                        
			}).finally(function() {
				self.viewCleaningID=-1;				
				getData();		
			});
			modalInstance.close('cancel');
		}
		
		
		
		function showDestroyMulitpleCleaningModal(data){
			var modalInstance = $uibModal.open(
				ModalSchedulerDeleteClean.DeleteMultipleCleans(self.permissions)
			);
			
			modalInstance.result.then(function (data) {                        
								
			}, function(error){
				console.log(error);                        
			}).finally(function() {
				self.viewCleaningID=-1;				
				getData();		
			});
			modalInstance.close('cancel');
		}
		
		self.destroyMultipleCleans = function(){								
			showDestroyMulitpleCleaningModal();	
		}
		
		
		
		
		/****************************/
			/* Show Apartment */
		/****************************/		
		
		function showApartmentModal(data){			
            var modalInstance = $uibModal.open(	ModalSchedulerShowApartment.show(data) );	

			modalInstance.result.then(function(data){ 	
			
				if (data.type == 'add-booking') {			
					addNewBookingFull();
				} 
				
				if(data.type == 'go-to-apartment') {
					$state.go('main.apartment', {'id': data.id}, {});			
				}					
			}, function(error){
				console.log(error);                        
			});			
			
			modalInstance.close('cancel');
		}
		
		$scope.$watch(function () {
			return self.viewApartmentData;
		},function(newValue, oldValue){			
			if(newValue.id !== null && newValue !==oldValue){
				self.selectedApartmentData = newValue;
				ApartmentsService.getApartment(newValue.id).then(function(success){     
					showApartmentModal(success.data);			
				}, function(error){
					console.log(error);
				});
				self.viewApartmentData = {id: null};
			}
		});
       
        
       
   }])