angular.module('schedulers.modals', [])

.factory("ModalSchedulerAddEditClean", [function(){
	 
	var modalSchedulerAddEditClean = {};          
	
	
	modalSchedulerAddEditClean.AddEditClean = function(data, apartments, permissions){ 
		return {
				animation: true,     
                template: 
				`<div class="modal-header">
					<div class="col-sm-9">
						<h3 class="modal-title">{{title}}</h3>
					</div>
					<div class="col-sm-3">
						<a ng-show="permissions['cleanings.show'] && data.id != 'new'" 
							ng-click="goToClean()"
							class="btn btn-primary pull-right"><i class="fa fa-arrow-right" aria-hidden="true"></i> Go To Clean</a>
					</div>
				</div>
				<div class="modal-body alert">           
					<form name="cleaningForm">
						
						<div>    
							<div class="row">
								<div class="col-sm-6">
									<div class="form-group">       
										<label>ID</label>
										<input type="text" class="form-control" name="id" ng-model="data.id" readonly/>          
									</div>
								</div>            
							</div>        
							
							<div class="row">
								<div class="col-sm-6">
									<div class="form-group" ng-class="{'has-error': cleaningForm.cleaning_date.$invalid && cleaningForm.cleaning_date.$touched}">       
										<label>Date</label>
										<p class="input-group">
											<input ng-model="data.cleaning_date" type="text" class="form-control" uib-datepicker-popup="{{'dd-MMM-yyyy'}}" 
												   datepicker-localdate
												   is-open="cleaning_date_open" datepicker-options="{formatYear: 'yy', startingDay: 1}" 
												   ng-required="true" close-text="Close" alt-input-formats="altInputFormats"
													ng-change="getActiveApartments()"
												   ng-focus="focus=false" ng-blur="focus=true" name="cleaning_date"   
												ng-disabled="!permissions['cleanings.update'] || data.id !='new'"
												   required/>
											<span class="input-group-btn">
												<button ng-disabled="!permissions['cleanings.update'] || data.id !='new'"
													tabindex="-1" type="button" class="btn btn-default" ng-click="cleaning_date_open = !cleaning_date_open"><i class="glyphicon glyphicon-calendar"></i></button>
											</span>
										</p>
										<p ng-show="cleaningForm.cleaning_date.$invalid && cleaningForm.cleaning_date.$touched" class="help-block"> 
														Required
										</p>
									</div>
								</div>
								
								
					
								<div class="col-md-6">
									<div class="form-group" ng-class="{'has-error': cleaningForm.check_out_date.$invalid && cleaningForm.check_out_date.$touched}">       
										<label>Apartment</label>
										<select 
											ng-disabled="!permissions['cleanings.update'] || data.id !='new' || data.cleaning_date == null" class="form-control"  
											ng-model="data.apartment_id"                  
											ng-options="option.id as (option.address1 + ', ' + option.address2 + ', ' + option.type + ', ' + option.beds + ' bed') for option in apartments" required>
											 <option></option>
										</select>
										<p ng-show="cleaningForm.apartment_id.$invalid && cleaningForm.apartment_id.$touched" class="help-block">
											Required
										</p>
									</div>
								</div>
							</div>
							
							<div class="row">				
								<div class="col-sm-6">
									<label class="form-group dropdown-wide"  ng-class="{'has-error': cleaningForm.type.$invalid && cleaningForm.status.$touched}">
										<label>Type</label>
										<select ng-disabled="!permissions['cleanings.update'] || data.id !='new'" class="form-control" required name="type"  ng-model="data.type">
											<option value="{{type}}" ng-repeat="type in cleaning_types">{{type | capitalize}}</option>                    
										</select>
										<p ng-show="cleaningForm.type.$invalid && cleaningForm.type.$touched" class="help-block">
											Required
										</p>
									</label> 
								</div>
								
							</div>
							
							<div class="row">				
								<div class="col-sm-12">
									<label class="form-group dropdown-wide"  ng-class="{'has-error': cleaningForm.notes.$invalid && cleaningForm.notes.$touched}">
										<label >Notes</label>
										<textarea ng-disabled="!permissions['cleanings.update'] || data.id !='new'"
											class="form-control" rows="5" ng-model="data.notes" name="notes"></textarea>                        
									</label> 
								</div>
								
							</div>
					   
						</div>  
					</form>
				</div>
				<div class="modal-footer">  
					<div class="row">
						<div class="col-sm-3">
							<button class="btn btn-success btn-lg pull-left btn-block" 
								ng-show="permissions['cleanings.update'] || data.id=='new' "
								ng-disabled="cleaningForm.$invalid " type="button" 
								ng-click="saveCleaning()">
								<span ng-show="!saveSpinner">
									Save
								</span> <i ng-if="saveSpinner" class="fa fa-cog fa-spin" style="font-size:16px"></i>
							</button>
						</div>
						<div class="col-sm-6">
							<div ng-show="showSuccessAlert" class="alert alert-success show-fade" style="width: 100%; text-align:left;">
								<strong>Success!</strong> Cleaning {{confirmationSuccessMessage}}.
							</div>
						</div>
						<div class="col-sm-3">
							<button class="btn btn-warning btn-lg" type="button" ng-click="cancel()">Cancel</button>
						</div>
					</div>
				</div>`,	
				resolve: {
					data: function () {
					  return data;
					},
					title: function(){
						return "Add/Edit Cleaning"
					},
					apartments: function(){
						return apartments;
					},
					cleaning_permissions: function(){						
						return permissions;
					}
				},
				controller: function ($uibModalInstance, $scope, OrganisationsService, 
										data, title, apartments, cleaning_permissions,
										 UtilBroadcastService, $timeout, ApartmentsService,  CleaningsService) {	
					
					$scope.title = title;
					$scope.data = data; 				
					
					$scope.cleaning_types = CleaningsService.getTypeForCleaning();     
					$scope.apartments = apartments;
					$scope.saveSpinner = false;
					$scope.showSuccessAlert = false;
					$scope.confirmationSuccessMessage = ($scope.data.id==='new') ? 'created' : 'updated';	
					$scope.permissions = cleaning_permissions;
					var config = {};	
					
					function getCleanings(){
						CleaningsService.getCleaning($scope.data.id).then(function(success){     
							$scope.data = success.data;									
						}, function(error){
							console.log(error);
						})
					}
					
					if($scope.data.id !='new') getCleanings();
								  
					$scope.saveCleaning = function () {	
						$scope.saveSpinner = true;
						if($scope.data.id==='new'){
							CleaningsService.postStore($scope.data).then(function(success){  				
								$scope.showSuccessAlert = true;
								$timeout(function () {
									$scope.showSuccessAlert = false;	
									$uibModalInstance.close('close');	
								}, 1000);							
							}, function(error){
								console.log(error);
							}).finally(function(){
								$scope.saveSpinner = true;
							});
						} else {
							CleaningsService.postUpdate($scope.data.id, $scope.data).then(function(success){                
								$scope.showSuccessAlert = true;
								$timeout(function () {
									$scope.showSuccessAlert = false;	
									$uibModalInstance.close('close');	
								}, 1000);		
							}, function(error){
								console.log(error);
							}).finally(function(){
								$scope.saveSpinner = true;
							});
						}
						
					}
					
					$scope.getActiveApartments = function(){				
						config.params = {};	
						config = {	params: {  	
							status_by_dates: true, 
							status_start_date: $scope.data.cleaning_date,
							status_end_date: $scope.data.cleaning_date,
							orderby: 'apartments.address1', 
							dropdown: true	
								}  };
						ApartmentsService.getApartments(config).then(function(success){     		
							$scope.apartments = success.data;
						}, function(error){
							console.log(error);
						});
					}	
					
					$scope.goToClean = function(){
						if($scope.data.id!='new'){	
							$uibModalInstance.close($scope.data.id);				
						}
					}
					
					
					$scope.cancel = function() {
					   $uibModalInstance.close('close');
					}			
		
				}	
		}
	}		
	return modalSchedulerAddEditClean;
}])
