angular.module('enquiries')

.factory("EnquiryService", ['$http',
	function($http){
	 
	var enquiryService = {};                  
	
	enquiryService.getEnquiry = function(config){            
		if(config==null) config=[];		
		return $http.get('api/enquiry', config);
	}
	
	enquiryService.getEnquiriesPaginate = function(config){ 
		if(config==null) config=[];		
		return $http.get('api/enquiry/paginate', config);
	}
   
	enquiryService.getShow = function(id){            
		return $http.get('api/enquiry/show/'+id);
	}
		
	enquiryService.postUpdate = function(id, enquiry){            
		return $http.post('api/enquiry/update/'+id, enquiry);
	}	
	
	enquiryService.postStore = function(enquiry){            
		return $http.post('api/enquiry/store', enquiry);
	}  
	
	enquiryService.getDelete = function(id){            
		return $http.get('api/enquiry/destroy/'+id);
	} 
	
	/*enquiry details*/

	enquiryService.getEnquiryDetails = function(config){ 
		if(config==null) config=[];	
		return $http.get('api/enquiry-details/', config);
	}
	
	enquiryService.postEnquiryDetailUpdate = function(id, data){            
		return $http.post('api/enquiry-details/update/'+id, data);
	}
	
	enquiryService.postEnquiryDetailStore = function(data){            
		return $http.post('api/enquiry-details/store', data);
	}
	
	enquiryService.getEnquiryDetailDelete = function(id){            
		return $http.get('api/enquiry-details/destroy/'+id);
	}   
	
	/* reports */
	
	enquiryService.postSummaryReport = function(dates){            
		return $http.post('api/enquiry/summary-report', dates);
	}

	/* emails */
	
	enquiryService.getSendEmailConfirmation = function(enquiry_detail_id){            
		return $http.get('api/enquiry/send-email-confirmation/'+enquiry_detail_id);
	}
	
	return enquiryService;
}])