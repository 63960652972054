angular.module('users.controllers')

.controller('UserController', ['$scope', 'UtilBroadcastService', 'UsersService', '$stateParams', '$uibModal', '$state', 
			'ModalConfirmAction', 'ModalFeedback', 'PermissionsService', '$timeout',
			
	function($scope, UtilBroadcastService, UsersService,$stateParams, $uibModal, $state, 
			ModalConfirmAction, ModalFeedback, PermissionsService, $timeout) {
	var self = this;	
	var id = $stateParams.id;
	var config;
	self.title='User';
	self.user={};		
	self.isNew = (id=='add-new');
	self.dataLoaded = false;
	self.permissions = [];
	self.logs = [];
	self.passwordSpinner = false;
	self.showSaveSpinner = false;			
	self.showAlert = false;
	self.alertDetails = {};
	self.user_states = UsersService.getStatusForUsers();
	if(self.isNew) {self.user.status = 'active'};
	
	
	PermissionsService.getByModuleByUser('users').then(function(success){   			
			self.permissions = success.data;				
		}, function(error){
			console.log(error);
		});    

	
	function getRoles(){
		UsersService.getRoles().then(function(success){     
			self.roles = success.data;					
		}, function(error){
			console.log(error);
		});
	}
	
	getRoles();
	
	/********************* End ******************/ 
   
	function getUser(){
		UsersService.getUser(id).then(function(success){  	   
			self.user = success.data;
			self.dataLoaded = true;				
		}, function(error){
			console.log(error);
		});			
	}
	
	
	
	if( !self.isNew ){ 
		getUser();
	} else {			
		self.user.role_id = '5';
		self.user.email = '';
		self.user.password = '';			
		self.dataLoaded = true;
	}
	
	self.newPassword = function(){
		self.passwordSpinner = true;
		if( !self.isNew ){
			UsersService.getPasswordNew(id).then(function(success){
				var modalInstance = $uibModal.open(ModalFeedback.showFeedback('Success', 'Password generated and email sent to user.', 'alert-success', true));						
				$timeout(function() { 
					modalInstance.close('cancel'); 
					$state.go('main.users', {}, {reload: true});
				}, 2000);					
			}, function(error){
				console.log(error);
				var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
							'Unable to generate password. Please contact a system administrator.', 'alert-danger', true));			
				$timeout(function() { modalInstance1.close('cancel'); }, 2000);
			}).finally(function(){
				self.passwordSpinner = false;
			});
		}
	}
	
	self.saveRecord = function(){
		self.showSaveSpinner = true;
		var promise; var message;	
		if( self.isNew ){
			self.showSaveSpinner = true; 
			promise = UsersService.postStore(self.user);
			message = 'Record created';			
		} else {
			promise = UsersService.postUpdate(id, self.user);
			message = 'Record updated';			
		}
		
		promise.then(function(success){
			var newId=false;
			if(self.isNew) newId = success.data;
			$scope.userForm.$setPristine();				
			UtilBroadcastService.showAlert(self, 'Success', message, newId, 'main.user');								   
		}, function(error){
			console.log(error);
			UtilBroadcastService.showAlert(self,'Failure', 'Unable to create/update record.');
		}).finally(function(){
			UtilBroadcastService.broadcastUpdate();
		});
	}
	
	self.deleteRecord = function(){
		
		var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
			'Warning!', 
			'Are you sure you would like to delete this record? Deletions cannot be undone.', 
			'alert-danger')
		);
	
		modalInstance.result.then(function (msg) {
			UsersService.getDelete(id).then(function(success){
				var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Record deleted!', 'alert-success', true));						
				$timeout(function() { 
					modalInstance1.close('cancel'); 
					$state.go('main.users', {}, {reload: true});
				}, 2000);				
			}, function(error){
				console.log(error);
				var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
							'Unable to delete record. Please contact a system administrator.', 'alert-danger', true));			
				$timeout(function() { modalInstance1.close('cancel'); }, 2000);
			});
		});
	  
	}
	
	 
}])
