angular.module('modals.services', [])

.factory("ModalConfirmAction", [function(){
	 
	var modalConfirmAction = {};          
	
	
	modalConfirmAction.confirmAction = function(title, message, superClass){ 
		return {
			template: 
				`<div class="modal-header">
					<h3 class="modal-title">{{title}}</h3>
				</div>
				<div class="modal-body alert" ng-class="superClass">       
					<span>{{message}}</span>
				</div>
				<div class="modal-footer">
					<div class="row">
						<div class="col-lg-3"><button class="btn btn-primary btn-block btn-outline" type="button" ng-click="ok()">Yes</button></div>
						<div class="col-lg-6"></div>
						<div class="col-lg-3"><button class="btn btn-warning btn-block btn-outline" type="button" ng-click="cancel()">No</button></div>
					</div>				
				</div>`,
			animation: true,      
			resolve: {					
				title: function(){
					return title;
				},
				message: function(){
					return message;
				},
				superClass: function(){
					return superClass;
				}
			},
			controller: function($scope, $uibModalInstance, title, message, superClass) {
				
				$scope.title = title;				
				$scope.message = message;
				$scope.superClass = superClass;
				
				$scope.ok = function () {
					 $uibModalInstance.close('confirmed');
				};				

				$scope.cancel = function() {
					$uibModalInstance.dismiss();
				}
			}
		};
	}	
	
			
	return modalConfirmAction;
}])