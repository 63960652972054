angular
    .module("credit-notes", [])

    .factory("CreditNotesService", [
        "$http",
        "$q",
        function ($http, $q) {
            var creditNotesService = {};

            creditNotesService.getCreditNotes = function (config) {
                return $http.get("api/credit-note", config == null ? [] : config);
            };

            creditNotesService.getCreditNotesPaginate = function (config) {
                return $http.get("api/credit-note/paginate", config == null ? [] : config);
            };

            creditNotesService.getCreditNote = function (id) {
                return $http.get("api/credit-note/show/" + id);
            };

            creditNotesService.postUpdate = function (id, credit_note) {
                return $http.post("api/credit-note/update/" + id, credit_note);
            };

            creditNotesService.postStore = function (credit_note) {
                return $http.post("api/credit-note/store", credit_note);
            };

            creditNotesService.getDelete = function (id) {
                return $http.get("api/credit-note/destroy/" + id);
            };

            creditNotesService.getStatusForCreditNotes = function () {
                return ["new", "sent", "allocated"];
            };

            creditNotesService.getTypesForCreditNotes = function () {
                return ["standard", "other"];
            };

            creditNotesService.getEmailCreditNote = function (id, config) {
                return $http.get("api/credit-note/email-credit-note/" + id, config == null ? [] : config);
            };

            /*
                Credit Notes Detail
            */

            creditNotesService.getCreditNoteDetailByCreditNote = function (id) {
                return $http.get("api/credit-note-details/by-credit-note/" + id);
            };

            creditNotesService.getCreditNoteDetailDelete = function (id) {
                return $http.get("api/credit-note-details/destroy/" + id);
            };

            creditNotesService.postCreditNoteDetailUpdate = function (id, data) {
                return $http.post("api/credit-note-details/update/" + id, data);
            };

            creditNotesService.postCreditNoteDetailStore = function (data) {
                return $http.post("api/credit-note-details/store", data);
            };

            creditNotesService.getCreditNoteDetailTypes = function () {
                return ["rate_per_night", "extra"];
            };

            /*
                Invoice Payments
            */

            creditNotesService.getInvoicePaymentByCreditNote = function (id) {
                return $http.get("api/invoice-payments/by-credit-note/" + id);
            };

            /* Reports */

            creditNotesService.getStandardCreditNoteReport = function (id, config) {
                var defer = $q.defer();
                $http({
                    url: "api/credit-note/standard-credit-note-report/" + id,
                    method: "GET",
                    responseType: "arraybuffer",
                    params: config.params,
                })
                    .success(function (data, status, headers, config) {
                        var fileBlob = new Blob([data], {
                            type: "application/pdf",
                        });
                        var fileUrl = URL.createObjectURL(fileBlob);
                        defer.resolve(fileUrl);
                    })
                    .error(function (data, status, headers, config) {
                        defer.resolve(data);
                    });

                return defer.promise;
            };

            creditNotesService.postCreditNoteSummaryReport = function (config) {
                return $http.post("api/credit-note/credit-note-summary-report", config);
            };

            creditNotesService.postCreditNoteSummaryReportPdf = function (config) {
                var defer = $q.defer();

                $http({
                    url: "api/credit-note/credit-note-summary-report-pdf",
                    method: "POST",
                    responseType: "arraybuffer",
                    params: config,
                })
                    .success(function (data, status, headers, config) {
                        var fileBlob = new Blob([data], {
                            type: "application/pdf",
                        });
                        var fileUrl = URL.createObjectURL(fileBlob);
                        defer.resolve(fileUrl);
                    })
                    .error(function (data, status, headers, config) {
                        defer.resolve(data);
                    });

                return defer.promise;
            };

            return creditNotesService;
        },
    ]);
