angular.module('form-controls.directives')

.factory("ModalAddDevelopment", [function(){
	 
	var modalAddDevelopment = {};          
	
	
	modalAddDevelopment.addDevelopment = function(){ 
		return {
                template: 
				`<form name="developmentForm" novalidate>
					<div class="modal-header">
						<h3 class="modal-title">Add Development</h3>
					</div>
					<div class="modal-body default">   				
						<div class="row">
							<div class="col-sm-6">
								<area-dropdown ng-model="development.area_id" form-name="developmentForm"
									is-new="true" show-add-button="true" is-disabled="false" title="Area *">
								</area-dropdown>						
							</div>
							<div class="col-sm-6">						
								<div class="form-group" ng-class="{'has-error': developmentForm.name.$invalid && developmentForm.name.$touched}">
									<label>Development Name *</label>
									<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="name"
										ng-model="development.name" required />
									<p ng-show="developmentForm.name.$invalid && developmentForm.name.$touched" class="help-block">Required</p> 
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-sm-6">
								<div class="form-group"
									ng-class="{'has-error': developmentForm.city.$invalid && developmentForm.city.$touched}">
									<label>City *</label>
									<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control"
										maxlength="255" name="city" ng-model="development.city" required />
									<p ng-show="developmentForm.city.$invalid && developmentForm.city.$touched" class="help-block">
										Required
									</p>
								</div>

							</div>
							<div class="col-sm-6">
								<div class="form-group"
									ng-class="{'has-error':  developmentForm.licence_number.$invalid &&  developmentForm.licence_number.$touched}">
									<label>Licence Number *</label>
									<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control"
										maxlength="512" name="licence_number" ng-model="development.licence_number"
										required />
									<p ng-show=" developmentForm.licence_number.$invalid &&  developmentForm.licence_number.$touched"
										class="help-block">Required</p>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-sm-6">								
								<div class="form-group" ng-class="{'has-error': developmentForm.postcode.$invalid && developmentForm.postcode.$touched}">
									<label>Postcode *</label>
									<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="postcode"
										ng-model="development.postcode" required />
									<p ng-show="developmentForm.postcode.$invalid && developmentForm.postcode.$touched" class="help-block">
										Required
									</p>
								</div>
							</div>
							<div class="col-sm-6">
								<div class="form-group" ng-class="{'has-error': developmentForm.code.$invalid && developmentForm.code.$touched}">
									<label>Development Code (Must be unique) *</label>
									<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="code"
										ng-model="development.code"  />
									<p ng-show="developmentForm.code.$invalid && developmentForm.code.$touched" class="help-block">Required</p> 
								</div>
							</div>
						</div>
								
						<div class="row">
							<div class="col-sm-4">
								<div class="form-group">
									<button class="btn btn-success btn-outline btn-block" ng-click="getGeocode()">
										<span ng-show="!showLatLngSpinner"> Get Latitude and Longitude	</span>	
										<span ng-show="showLatLngSpinner"> <i class="fa fa-cog fa-spin" style="font-size:16px"></i> </span>		
									</button>                 
								</div>
							</div>
							<div class="col-sm-8">
							</div>
						</div>
						
						<div class="row">
							<div class="col-sm-6">
								<div class="form-group" ng-class="{'has-error': developmentForm.lat.$invalid && developmentForm.lat.$touched}">
									<label>Latitude *</label>
									<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="lat"
										ng-model="development.lat" required/>      
									<p ng-show="developmentForm.lat.$invalid && developmentForm.lat.$touched" class="help-block">
										Required
									</p>
								</div>
							</div>
							<div class="col-sm-6">								
								<div class="form-group" ng-class="{'has-error': developmentForm.lng.$invalid && developmentForm.lng.$touched}">
									<label>Longitude *</label>
									<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control" name="lng"
										ng-model="development.lng" required/>  
									<p ng-show="developmentForm.lng.$invalid && developmentForm.lng.$touched" class="help-block">
										Required
									</p>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-sm-6">
								<div class="form-group"
									ng-class="{'has-error': developmentForm.scheduler_color.$invalid && developmentForm.scheduler_color.$touched}">
									<label>Scheduler Colour (Hex) *</label>
									<input ng-focus="focus=false" ng-blur="focus=true" type="text" class="form-control"
										name="scheduler_color" maxlength="7" ng-model="development.scheduler_color"
										required />
									<p ng-show="developmentForm.scheduler_color.$invalid && developmentForm.scheduler_color.$touched"
										class="help-block">
										Required
									</p>
								</div>

							</div>
							<div class="col-sm-6">
								<div class="form-group" ng-class="{'has-error': developmentForm.status.$invalid && developmentForm.status.$touched}">
									<label>Status</label>
									<select class="form-control" name="status" ng-model="development.status" required>
										<option value="active">Active</option>
										<option value="inactive">Inactive</option>                        
									</select>
									<p ng-show="developmentForm.status.$invalid && developmentForm.status.$touched" class="help-block">
										Required
									</p>
								</div>    
							</div>
						</div>                    

					</div>
					<div class="modal-footer">				
						<div class="form-group">
							<div class="row">
								<div class="col-sm-3">
									<my-save-button
										click-fn="save()"
										show-spinner="showSaveSpinner"												
										ng-disabled="developmentForm.$invalid"										
									></my-save-button>
									
								</div>
								<div class="col-sm-6">
									<my-form-alert				
										show-alert="showAlert"
										alert-type="alertDetails.type"
										alert-message="alertDetails.message"											
									></my-form-alert>											
								</div>	
								<div class="col-sm-3">
									<button type="button" class="btn btn-lg btn-default btn-outline btn-block" 
										ng-click="cancel()">Cancel</button>										
								</div>	
							</div>    
						</div>
					</div>
				</form>`,
				animation: true,  
				windowClass: 'app-modal-window',                
				controller: function ($uibModalInstance, $scope, AreasService, 
										DevelopmentsService, GoogleMapsService, UtilBroadcastService, $timeout) {	
					var config = {	params: {}	};
					$scope.areas = [];
					$scope.development = {};
					$scope.showSaveSpinner = false;	
					$scope.showLatLngSpinner = false;	
					$scope.showAlert = false;
					$scope.alertDetails = {};

					$scope.development.scheduler_color = '#DDA5F0';
					$scope.development.status = 'active';
					
					function getAreas(){
						config.params={};
						config.params.orderby = 'areas.area';
						config.params.findby = 'status';
						config.params.findbyvalue = 'active';
						AreasService.getAreas(config).then(function(success){     
							$scope.areas = success.data;            
						}, function(error){
							console.log(error);
						});  
					}
	
					getAreas();
					
					$scope.getGeocode = function(){
						$scope.showLatLngSpinner = true;
						GoogleMapsService.getGeocode($scope.development.postcode).then(function(success){
							$scope.development.lat = success.data.results[0].geometry.location.lat;
							$scope.development.lng = success.data.results[0].geometry.location.lng;
						}, function(error){
							console.log(error);
						}).finally(function(){
							$scope.showLatLngSpinner = false;
						});
					}	
						
					$scope.save = function () {											
						var newId;
						$scope.showSaveSpinner = true;	
						DevelopmentsService.postStore($scope.development).then(function(success){							
							newId=success.data;								
							UtilBroadcastService.showAlert($scope, 'Success', 'Record created', false);								   
						}, function(error){
							console.log(error);
							UtilBroadcastService.showAlert($scope,'Failure', 'Unable to create/update record.');
						}).finally(function(){
							$scope.showSaveSpinner = false;	
							$timeout(function() {
								$uibModalInstance.close(newId);
							}, 2000);
							
						});	
						
						 
					};
					
					$scope.cancel = function() {
					   $uibModalInstance.dismiss();
					}
				}
            };
			
		
	}	
	
			
	return modalAddDevelopment;
}])