angular.module('cleanings.services', [])

.factory("CleaningsService", ['$http', function($http){
	 
	var cleaningsService = {};                  
	
	cleaningsService.getCleanings = function(config){
		return $http.get('api/cleaning', config == null ? [] : config);
	}
	
	cleaningsService.getCleaningsPaginate = function(config){
		return $http.get('api/cleaning/paginate', config == null ? [] : config);
	}
	
	cleaningsService.getCleaning = function(id){            
		return $http.get('api/cleaning/show/'+id);
	}
	
	cleaningsService.postUpdate = function(id, cleaning){            
		return $http.post('api/cleaning/update/'+id, cleaning);
	}
	
	cleaningsService.postStore = function(cleaning){            
		return $http.post('api/cleaning/store', cleaning);
	}    

	cleaningsService.postStoreMultiple = function(object){            
		return $http.post('api/cleaning/store-multiple', object);
	}    		
	
	cleaningsService.getDelete = function(id){            
		return $http.get('api/cleaning/destroy/'+id);
	}
	cleaningsService.postDeleteMultiple = function(object){            
		return $http.post('api/cleaning/destroy-multiple', object);
	}        

	cleaningsService.getAreas = function(){            
		return $http.get('api/area/getareas');
	}
	
	cleaningsService.getTypeForCleaning = function(){            
		return ['Service', 'Depart', 'Note'];
	}
   
	/* Reports */
	
	cleaningsService.postCleaningRotaReport = function(data){            
		return $http.post('api/cleaning/rota-report', data);
	}
			
	return cleaningsService;
}])