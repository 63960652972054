angular.module('contacts.controllers', [])

.controller('ContactsController', ['$state', 'ContactsService', '$scope', '$filter', 'PermissionsService', '$timeout',
		function($state, ContactsService, $scope, $filter, PermissionsService, $timeout) {
	var self = this;

	self.dataLoaded = false;
	self.report_data = {'data': 'no'};
	self.summarySpinner = false;		
	self.contacts = [];
	self.contacts.current_page = 1;        
	self.maxSize = 5;
	self.search_value;
	self.sortASC = true;
	self.permissions = [];
	
	var config = {
		params: { 	
			orderby: 'contacts.contact_name',
			page: self.contacts.current_page, 				
			per_page: 20
		}				 
	};

	PermissionsService.getByModuleByUser('contacts').then(function(success){   			
		self.permissions = success.data;
	}, function(error){
		console.log(error);
	});   
	
	function getContacts(){
		self.dataLoaded = false;
		ContactsService.getContactsPaginate(config).then(function(success){
			self.contacts = success.data;				
			self.dataLoaded = true;
		}, function(error){
			console.log(error);
		});
	}
	
	getContacts();

	self.go = function(id){
		$state.go('main.contact', {'id': id}, {reload: true});
	}

	self.pagination = function(){			
		config.params.page = self.contacts.current_page;
		getContacts();
	}
	
	self.sortTable = function(heading){
		config.params.orderby = heading;
		config.params.sort = (self.sortASC) ? 'ASC' : 'DESC';	
		self.sortASC = !self.sortASC;
		getContacts();
	   
	}
	
	self.search = function(){
		self.contacts.current_page = 1;
		config.params.page = self.contacts.current_page;
		config.params.search = self.search_value;
		getContacts();
	}		
		
	self.printSummary = function(){
		self.summarySpinner = true;
		ContactsService.getContactSummaryReport().then(function(success) {
			self.report_data= {
				"template": { "shortid" : "4kWXH9Ase" },
				"title": "A Title",
				"data": success.data	
			};						
		}, function(error){
			console.log(error);
		}).finally(function(){
			setTimeout(function(){
				self.summarySpinner = false;
			}, 2000);				
		});
			
		   
	};
		
	self.print = function(item) {
		item.detailSpinner = true;
		ContactsService.getContactDetailedReport(item.id).then(function(success) {
			self.report_data= {
				"template": { "shortid" : "4kGgSc0ox" },
				"title": "Contact Report",
				"data": success.data
			};	
		}, function(error){
			console.log(error);
		}).finally(function(){
			item.detailSpinner = false;
		});
		  
	}
}])