angular.module('invoices.modals', [])

.controller('ModalInvoiceAttachmentController', ['$scope', 'data', 'title', 'permissions', '$uibModalInstance', 
                'UtilBroadcastService', '$timeout', 'Upload', 'InvoicesService',
        function($scope, data, title, permissions, $uibModalInstance, 
            UtilBroadcastService, $timeout, Upload, InvoicesService) {
    
            $scope.title = title;
            $scope.data =  data;
            $scope.permissions = permissions;   
            $scope.showSaveSpinner = false;         
            $scope.showAlert = false;
            $scope.alertDetails = {};
            $scope.attachmentTypes = InvoicesService.getAttachmentTypes();

            $scope.saveRecord = function () {   
                $scope.showSaveSpinner = true;
                var message, url;

                if($scope.data.id != 'new'){
                    url = 'api/invoice-attachments/update/'+$scope.data.id;
                    message = "Invoice Attachment Updated."
                } else {
                    url = 'api/invoice-attachments/store';
                    message = "Invoice Attachment Stored";
                }

                                   
                Upload.upload({
                    url: url,
                    data: {file: $scope.file, 
                            'invoice_id': $scope.data.invoice_id,
                            'title': $scope.data.title,
                            'type':  $scope.data.type
                            }
                }).then(function (resp) {
                    if($scope.file) console.log('Success ' + resp.config.data.file.name + 'uploaded. Response: ' + resp.data);
                    
                    self.sendSpinner = false;                   
                    
                    $scope.invoiceAttachmentForm.$setPristine();    
                    UtilBroadcastService.showAlert($scope, 'Success', message, false);  
                    if($scope.data.id==='new') $scope.data.id = resp.data;                  
                    
                }, function (resp) {
                    console.log('Error status: ' + resp.status);
                    UtilBroadcastService.showAlert($scope,'Failure', 'Unable to create/update record.');
                }, function (evt) {
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);

                    if($scope.file)console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
                }).finally(function(){
                    UtilBroadcastService.broadcastUpdate(); 
                    $timeout(function() {
                        $uibModalInstance.close('close');
                    }, 2000);                                       
                });                 
            }

            $scope.cancel = function() {
                $uibModalInstance.dismiss();
            }

            $scope.$watch('file', function(newData, oldData) {
                if((newData != oldData) && (newData != null)) $scope.data.attachment = $scope.file.name;
            });

        
       
    }   
])

