angular.module('actionslog.controllers')

.controller('ActionsLogController', ['ActionsLogService', '$stateParams', '$uibModal', '$state',
    function(ActionsLogService, $stateParams, $uibModal, $state) {
    var self = this;      
          
    self.log = {};
	
    var id = $stateParams.id;
       
    function getActionsLog(){
      
        ActionsLogService.getActionsLog(id).then(function(success){     
            self.log = success.data;            
        }, function(error){
            console.log(error);
        }); 
    }   
      
    getActionsLog();
          
}])          