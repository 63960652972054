angular.module('invoices.directives', [])

	.directive('invoiceDetails', function ($rootScope, InvoicesService, BookingsService, $uibModal, ModalInvoiceDetail,
									ModalConfirmAction, ModalFeedback, $timeout) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				invoiceId: '=',	
				startDate: '=',
				endDate: '=',	
				bookingId: '=',
				showAlert: '=',
				permissions: '='
			},      
			template:	`<div>
							<div ng-show="!dataLoaded" class="row text-center">
								<span ><i class="fa fa-cog fa-spin" style="font-size:48px"></i></span>
							</div>
							<div ng-show="dataLoaded">
								<table class="table"  >
										<th>Qty</th>										
										<th>Price</th>
										<th>Description</th>	
										<th>Type</th>										
										<th>Total</th>
										<th>VAT</th>
										<th colspan="2" ng-if="permissions['invoices.show'] || permissions['invoices.delete']" style="width: 10%"><div class="text-center">Action</div></th>
									<tr ng-repeat="item in invoice_details">        
										<td>{{item.quantity}}</td>
										<td>{{item.unit_price}}</td>                       
										<td>{{item.description}}</td>
										<td>{{item.type | limitTo: 5 | capitalize | underscoreless }}</td>
										<td>{{item.total}}</td>
										<td>{{item.vat}}</td>
										<td><my-sub-form-button click-fn="showInvoiceDetail(item)" 
											ng-if="permissions['invoices.show']" 
											button-type="edit"></my-sub-form-button></td>
										<td><my-sub-form-button 
											ng-if="permissions['invoices.delete']" 
											click-fn="deleteInvoiceDetail(item)" 
											button-type="delete"></my-sub-form-button>
										</td>            										
									</tr>                            
								</table>
								<div class="pull-right">
									<my-sub-form-button ng-if="permissions['invoices.create']"  
												click-fn="createInvoiceDetail()" 
												button-type="add"></my-sub-form-button>
								</div>
							</div>
						</div>`,
			link: function (scope, element, attr) {				
				
				var invoiceDetailData;
				scope.dataLoaded = false;
				scope.chargePerNight = 0;
				scope.invoice_details = [];					
				
				function updateAlert(value){
					setTimeout(function(){
						scope.$apply(function(){
							scope.showAlert=value;							
						});		
					}, 500);						
				}
				
				function getInvoiceDetails(){
					InvoicesService.getInvoiceDetailByInvoice(scope.invoiceId).then(function(success){
						scope.invoice_details = success.data;					
						updateAlert(scope.invoice_details.length==0);		
					}, function(error){
						console.log(error);
					}).finally(function(){						
						scope.dataLoaded = true;
					});
						
				}				
			
				getInvoiceDetails();	

				function getBooking(){							
					BookingsService.getBooking(scope.bookingId).then(function(success){
						scope.chargePerNight = success.data.charge_per_night;						
					}, function(error){
						console.log(error);
					});
				}
				
				getBooking();
								
				function broadcastUpdate(){
					console.log('broadcasting update');
					$rootScope.$broadcast('updateSidebars', {
						data: true
					});

				}
				
				scope.$on('updateSidebars', function (event, result) {					
					getInvoiceDetails();
				});
				
				scope.$watch('invoiceId', function(newVal, oldVal){
					if(newVal != oldVal){
						getInvoiceDetails();	
					}
				});
				
				function showDetail(){
					var modalInstance = $uibModal.open(ModalInvoiceDetail.show(invoiceDetailData, scope.permissions));
					
					modalInstance.result.then(function (data) {
						getInvoiceDetails();   
						$rootScope.$broadcast('updateInvoice', {
							data: true
						});  
					});
				}
				
				scope.showInvoiceDetail = function(item){
					invoiceDetailData = item;
					invoiceDetailData.start_date = scope.startDate;
					invoiceDetailData.end_date = scope.endDate;
					invoiceDetailData.charge_per_night = scope.chargePerNight;
					showDetail();
					
				}
				
				scope.createInvoiceDetail = function(){
					invoiceDetailData = {id: 'new', invoice_id: scope.invoiceId, 
								start_date: scope.startDate, end_date: scope.endDate, charge_per_night: scope.chargePerNight};
					showDetail();
				}
				
				scope.deleteInvoiceDetail = function(item){
					var modalInstance = $uibModal.open(ModalConfirmAction.confirmAction(
						'Warning!', 
						'Are you sure you would like to delete this record? Deletions cannot be undone.', 
						'alert-danger')
					);
					
					modalInstance.result.then(function (data) {						
						InvoicesService.getInvoiceDetailDelete(item.id).then(function(success){                      
							var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Success', 'Record deleted!', 'alert-success'));						
							$timeout(function() { modalInstance1.close('cancel'); }, 2000);
							broadcastUpdate();								
						}, function(error){
							console.log(error);
							var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback('Error!',
										'Unable to delete record. Please contact a system administrator.', 'alert-danger'));			
							$timeout(function() { modalInstance1.close('cancel'); }, 2000);
						});
						modalInstance.close('cancel');            
					});
				}

			}
		};
	})