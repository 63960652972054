angular
    .module("credit-notes")

    .directive(
        "creditNoteDetails",
        function ($rootScope, CreditNotesService, $uibModal, ModalCreditNoteDetail, ModalConfirmAction, ModalFeedback, $timeout) {
            return {
                restrict: "EAC",
                replace: true,
                scope: {
                    creditNoteId: "=",
                    startDate: "=",
                    endDate: "=",
                    showAlert: "=",
                    permissions: "=",
                },
                template: `<div>
							<div ng-show="!dataLoaded" class="row text-center">
								<span ><i class="fa fa-cog fa-spin" style="font-size:48px"></i></span>
							</div>
							<div ng-show="dataLoaded">
								<table class="table"  >
										<th>Qty</th>										
										<th>Price</th>
										<th>Description</th>	
										<th>Type</th>										
										<th>Total</th>
										<th>VAT</th>
										<th colspan="2" ng-if="permissions['credit-notes.show'] || permissions['credit-notes.delete']" style="width: 10%"><div class="text-center">Action</div></th>
									<tr ng-repeat="item in credit_note_details">        
										<td>{{item.quantity}}</td>
										<td>{{item.unit_price}}</td>                       
										<td>{{item.description}}</td>
										<td>{{item.type | limitTo: 5 | capitalize | underscoreless }}</td>
										<td>{{item.total}}</td>
										<td>{{item.vat}}</td>
										<td><my-sub-form-button click-fn="showCreditNoteDetail(item)" 
											ng-if="permissions['credit-notes.show']" 
											button-type="edit"></my-sub-form-button></td>
										<td><my-sub-form-button 
											ng-if="permissions['credit-notes.delete']" 
											click-fn="deleteCreditNoteDetail(item)" 
											button-type="delete"></my-sub-form-button>
										</td>            										
									</tr>                            
								</table>
								<div class="pull-right">
									<my-sub-form-button ng-if="permissions['credit-notes.create']"  
												click-fn="createCreditNoteDetail()" 
												button-type="add"></my-sub-form-button>
								</div>
							</div>
						</div>`,
                link: function (scope, element, attr) {
                    var creditNoteDetailData;
                    scope.dataLoaded = false;
                    scope.chargePerNight = 0;
                    scope.credit_note_details = [];

                    function updateAlert(value) {
                        setTimeout(function () {
                            scope.$apply(function () {
                                scope.showAlert = value;
                            });
                        }, 500);
                    }

                    function getCreditNoteDetails() {
                        CreditNotesService.getCreditNoteDetailByCreditNote(scope.creditNoteId)
                            .then(
                                function (success) {
                                    scope.credit_note_details = success.data;
                                    updateAlert(scope.credit_note_details.length == 0);
                                },
                                function (error) {
                                    console.log(error);
                                }
                            )
                            .finally(function () {
                                scope.dataLoaded = true;
                            });
                    }

                    getCreditNoteDetails();

                    function broadcastUpdate() {
                        console.log("broadcasting update");
                        $rootScope.$broadcast("updateSidebars", {
                            data: true,
                        });
                    }

                    scope.$on("updateSidebars", function (event, result) {
                        getCreditNoteDetails();
                    });

                    scope.$watch("creditNoteId", function (newVal, oldVal) {
                        if (newVal != oldVal) {
                            getCreditNoteDetails();
                        }
                    });

                    function showDetail() {
                        var modalInstance = $uibModal.open(ModalCreditNoteDetail.show(creditNoteDetailData, scope.permissions));

                        modalInstance.result.then(function (data) {
                            getCreditNoteDetails();
                            $rootScope.$broadcast("updateCreditNote", {
                                data: true,
                            });
                        });
                    }

                    scope.showCreditNoteDetail = function (item) {
                        creditNoteDetailData = item;
                        creditNoteDetailData.start_date = scope.startDate;
                        creditNoteDetailData.end_date = scope.endDate;
                        creditNoteDetailData.charge_per_night = scope.chargePerNight;
                        showDetail();
                    };

                    scope.createCreditNoteDetail = function () {
                        creditNoteDetailData = {
                            id: "new",
                            credit_note_id: scope.creditNoteId,
                            start_date: scope.startDate,
                            end_date: scope.endDate,
                            charge_per_night: scope.chargePerNight,
                        };
                        showDetail();
                    };

                    scope.deleteCreditNoteDetail = function (item) {
                        var modalInstance = $uibModal.open(
                            ModalConfirmAction.confirmAction(
                                "Warning!",
                                "Are you sure you would like to delete this record? Deletions cannot be undone.",
                                "alert-danger"
                            )
                        );

                        modalInstance.result.then(function (data) {
                            CreditNotesService.getCreditNoteDetailDelete(item.id).then(
                                function (success) {
                                    var modalInstance1 = $uibModal.open(ModalFeedback.showFeedback("Success", "Record deleted!", "alert-success"));
                                    $timeout(function () {
                                        modalInstance1.close("cancel");
                                    }, 2000);
                                    broadcastUpdate();
                                },
                                function (error) {
                                    console.log(error);
                                    var modalInstance1 = $uibModal.open(
                                        ModalFeedback.showFeedback(
                                            "Error!",
                                            "Unable to delete record. Please contact a system administrator.",
                                            "alert-danger"
                                        )
                                    );
                                    $timeout(function () {
                                        modalInstance1.close("cancel");
                                    }, 2000);
                                }
                            );
                            modalInstance.close("cancel");
                        });
                    };
                },
            };
        }
    );
