angular.module('angular-utils.directives')

.directive('mySaveButton', function () {
	return {
		restrict : 'EAC',
		replace : true,
		scope :{				
			clickFn: '&',
			showSpinner: '=',
			buttonTitle: '@'
		},      
		template:	`<button type="button"
						id="btnSaveRecord"
						class="btn btn-lg btn-primary btn-outline btn-block" 
						ng-click="clickMe()" > 
						<span ng-show="!showSpinner"> Save	</span>	
						<span ng-show="showSpinner"> <i class="fa fa-cog fa-spin" style="font-size:16px"></i> </span>
					</button>`,
		
		
		link: function (scope, element, attr) {				
			scope.clickMe = function() {						
				scope.clickFn();
			}
		}
	};
})