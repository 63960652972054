angular.module('form-controls.directives')

	.directive('organisationDropdown', function (OrganisationsService, $uibModal, ModalAddOrganisation, ModalShowOrganisation) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				changeFn: '&',
				organisationId: '=ngModel',
				formName: '=',
				isNew: '=',
				type: '@',
				showAddButton: '=',
				title: '@',
				isDisabled: '='
			},    
			require: 'ngModel',
			template:	`<div class="form-group" ng-class="{'has-error': formName.organisation_id.$invalid && formName.organisation_id.$touched}">
							<label>{{title}}</label>
							<div ng-class="{'input-group': showAddButton && !isDisabled }">	
								<select class="form-control" style=" width: 100%;" name="organisation_id" 
									ng-change="changeMe()"
									ng-disabled="isDisabled"
									ng-model="organisationId" 
									ng-options="option.id as option.organisation_name for option in organisations" required>
								</select>
								<span class="input-group-btn" ng-show="showAddButton && !isDisabled">
								<button type="button" ng-disabled="isDisabled" class="btn btn-info" ng-click="showOrganisation()"><i class="fa fa-binoculars"></i></button>
									<button type="button" ng-disabled="isDisabled" class="btn btn-success" ng-click="addOrganisation()"><i class="fa fa-plus"></i></button>
								</span>								
							</div>
							<p ng-show="formName.organisation_id.$invalid && formName.organisation_id.$touched" class="help-block">
								Required
							</p>
						</div>`,
			link: function (scope, element, attr, ngModel) {				
				var config={};
				var newId = -1;
				scope.organisations;
				
				function setDefaults(){
					if (angular.isUndefined(scope.type)) scope.type = 'client';
					if (angular.isUndefined(scope.showAddButton)) scope.showAddButton = false;
					if (angular.isUndefined(scope.isDisabled)) scope.isDisabled = true;
				}
				
				setDefaults();
				
				function getOrganisations(){
					config.params={};
					config.params.orderby = 'organisations.organisation_name';
					config.params.dropdown = true;
					config.params.findby = 'type';
					config.params.findbyvalue = scope.type;
					if(scope.isNew){
						config.params.status = 'active';
					}	
					OrganisationsService.getOrganisations(config).then(function(success){ 				
						scope.organisations = success.data;						
					}, function(error){
						console.log(error);
					}).finally(function(){
						if(newId>0) scope.organisationId = newId;
						newId = -1;
					}); 
				}
				
				getOrganisations();  

				scope.addOrganisation= function(){
					var modalInstance = $uibModal.open( ModalAddOrganisation.addOrganisation() );
					
					modalInstance.result.then(function (data) {
						newId = data;
						getOrganisations();				
						modalInstance.close('cancel');                  
					});
				}

				scope.showOrganisation = function(){
					OrganisationsService.getOrganisation(scope.organisationId).then(function(success){
						var modalInstance = $uibModal.open( ModalShowOrganisation.showOrganisation(success.data) );
					
						modalInstance.result.then(function () {			
							modalInstance.close('cancel');                  
						});
					}, function(error){
						console.log(error);
					});
				}

				scope.changeMe = function() {						
					scope.changeFn();
				}

			
			}
		};
	})