angular.module('dashboard.directives')

	.directive('dashboardClientAls', function (DashboardService, $filter) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				chartTitle: '@',
			},      
			template:	`<div>
							<div class="row">
								<div class="col-lg-12">
									<div class="panel panel-primary">
										<div class="panel-heading" >
											<h3 class="panel-title"><i class="fa fa-long-arrow-right fa-fw"></i> {{chartTitle}}</h3>
										</div>
										<div class="panel-body" >						
											<div ng-if="!dataLoaded" class="text-center">
												<i class="fa fa-cog fa-spin" style="font-size:48px"></i>
											</div>
											<div ng-if="dataLoaded">
												<canvas height="250px"  id="base" class="chart-horizontal-bar"
													chart-data="chart.data" 
													chart-labels="chart.labels" 
													chart-series="chart.series"
													chart-colors="chart.colors"
													chart-options="chart.options"		
												
													
												></canvas>

											</div>
										</div>
									</div>
								</div>							
							</div>
						</div>`,
			link: function (scope, element, attr) {
				
			
				scope.dataLoaded = false;
				scope.dates = {};
				scope.chart = {};				
				
				function defaultDates(){
					scope.dates.start_date =  moment.utc().startOf('month').format('YYYY-MM-DD');				
					scope.dates.end_date = moment.utc().endOf('month').format('YYYY-MM-DD');					
				}
				
				defaultDates();				
				
				scope.getClientAls = function(){
					scope.dataLoaded = false;		
					
					scope.chart.options = {
						onClick: function(e) {
							var element = this.getElementAtEvent(e);
							if (element.length) {
							   console.log(element[0])
							}
						 },
						 tooltips: {
							callbacks: {
								label: function(tooltipItem, data) {		
									return $filter('number')(tooltipItem.value, 2) + ' days';								
								}
							}
						}
					};
					
					scope.chart.labels = [];
					scope.chart.data = [];		
					scope.chart.data[0] = [];			
					scope.chart.series = [];
					scope.chart.colors = [
						{
							backgroundColor: "rgba(204,0,0, 0.2)",
							borderColor: "rgba(204,0,0, 1)"
						  }
						];
					
					DashboardService.postClientAverageLengthOfStay().then(function(success){	
						angular.forEach(success.data, function(value, key) {							
							scope.chart.labels.push(key);
							scope.chart.data[0].push(value.ALS);						
						  });				
						
					}, function(error){
						console.log(error);			
					}).finally(function() {			
						scope.dataLoaded = true;
					});
				}
				
				scope.getClientAls();								
				
			}
		};
	})