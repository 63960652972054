angular
    .module("credit-notes")

    .factory("ModalCreditNoteDetail", [
        function () {
            var modalCreditNoteDetail = {};

            modalCreditNoteDetail.show = function (data, permissions) {
                return {
                    template: `<div class="modal-header">
                                    <h3 class="modal-title">Credit Note Detail</h3>
                                </div>
                                <div class="modal-body alert">    
                                    <modal-credit-note-detail
                                        data="data"
                                        permissions="permissions"
                                        update-fn="updateMe( myVal )"

                                    ></modal-credit-note-detail>                                    
                                </div>
                                `,
                    animation: true,
                    resolve: {
                        data: function () {
                            return data;
                        },
                        title: function () {
                            return "Credit Note Details";
                        },
                        permissions: function () {
                            return permissions;
                        },
                    },
                    controller: function ($scope, data, title, permissions, $uibModalInstance) {
                        $scope.title = title;
                        $scope.data = data;
                        $scope.permissions = permissions;
                       
                        $scope.updateMe = function(myVal){
                            switch (myVal){
                                case 'close':
                                    $uibModalInstance.close("close");
                                    break;
                                case 'dismiss':
                                    $uibModalInstance.dismiss();
                                    break;
                            }
                        }
                    },
                };
            };

            return modalCreditNoteDetail;
        },
    ]);
