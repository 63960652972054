angular.module('contacts.services', [])

.factory("ContactsService", ['$http',
	function($http){
	 
	var contactsService = {};                  
	
	contactsService.getContacts = function(config){ 
		if(config==null) config=[];		
		return $http.get('api/contact', config);
	}
	
	contactsService.getContactsPaginate = function(config){ 
		if(config==null) config=[];		
		return $http.get('api/contact/paginate', config);
	}
	
	contactsService.getContact = function(id){            
		return $http.get('api/contact/show/'+id);
	}
	
	contactsService.postUpdate = function(id, contact){            
		return $http.post('api/contact/update/'+id, contact);
	}
	
	contactsService.postStore = function(contact){            
		return $http.post('api/contact/store', contact);
	}     
	
	contactsService.getDelete = function(id){            
		return $http.get('api/contact/destroy/'+id);
	}
	
	contactsService.getStatusForContactWarnings = function(){            
		return ['active', 'resolved', 'cancelled'];
	}

	contactsService.getTypes = function(){            
		return ['guest', 'reservations_team', 'direct_client', 'potential_client', 'potential_agent', 'agent', 'supplier', 'letting_agent', 'other'];
	}
	
	/*
	 *              Contact Warnings
	 */
	
	contactsService.getContactWarnings = function(config){            
		if(config==null) config=[];		
		return $http.get('api/contact-warnings', config);
	}
	
	contactsService.postContactWarningsUpdate = function(id, data){            
		return $http.post('api/contact-warnings/update/'+id, data);
	}
	
	contactsService.postContactWarningsStore = function(data){            
		return $http.post('api/contact-warnings/store', data);
	}
	
	contactsService.getContactWarningsDelete = function(id){            
		return $http.get('api/contact-warnings/destroy/'+id);
	}
	
	
	
	/*Reports*/
	
	contactsService.getContactSummaryReport = function(){            
		return $http.get('api/contact/summary-report');
	}
	
	contactsService.getContactDetailedReport = function(id){            
		return $http.get('api/contact/detailed-report/'+id);
	}
	
   
			
	return contactsService;
}])