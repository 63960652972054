angular.module('channel-manager')

    .controller('ModalCmAvailabilityViewController', function ($scope, $uibModalInstance, data, apartments, ratePlans, title, permissions,
        ChannelManagerService, UtilBroadcastService, $timeout, UtilService) {

        $scope.title = title;
        $scope.data = data;
        $scope.dates = {};
        $scope.showSaveSpinner = false;
        $scope.showAlert = false;
        $scope.alertDetails = {};
        $scope.permissions = permissions;
        $scope.channeldata = {};
        $scope.apartments = apartments;
        $scope.ratePlans = ratePlans;

        var minDate = moment();
        var maxDate = moment().add(500, 'days')

        $scope.dateFromOptions = {
            'formatYear': 'yy',
            'startingDay': 1,
            'minDate': minDate,
            'maxDate': maxDate
        }

        $scope.dateToOptions = {
            'formatYear': 'yy',
            'startingDay': 1,
            'minDate': minDate,
            'maxDate': maxDate
        }

        function initDates() {
            $scope.dates.dateFrom = moment().format('YYYY-MM-DD');
            $scope.dates.dateTo = moment().add(3, 'w').format('YYYY-MM-DD');
        }

        initDates();

        $scope.changeDateTo = function () {
            var start = moment($scope.dates.dateFrom);
            var end = start.add(1, 'y').subtract(1, 'days');
            if (end.isSameOrAfter(maxDate)) end = maxDate;
            $scope.dates.dateTo = end.format('YYYY-MM-DD');
        }

        $scope.useSameDate = function () {
            $scope.dates.dateTo = $scope.dates.dateFrom;
        }

        $scope.getPrice = function(item){
            $selectedRoomType =  UtilService.findObjectByKey($scope.apartments, 'roomid', $scope.data.roomId);
            $scope.data.price = $selectedRoomType.rate*1;
        }


        $scope.saveRecord = function () {
            
            $scope.data.dateFrom = $scope.dates.dateFrom;
            $scope.data.dateTo = $scope.dates.dateTo;

            $scope.showSaveSpinner = true;

            var promise; var message;

            promise = ChannelManagerService.postAvailability($scope.data);
            message = 'Rate Plan added';

            promise.then(function (success) {
               
                UtilBroadcastService.showAlert($scope, 'Success', message, false);
                $timeout(function () {
                    $uibModalInstance.close('close');
                }, 2000);

            }, function (error) {
                console.log(error);
                UtilBroadcastService.showAlert($scope, 'Failure', error.data);
            }).finally(function () {
                $scope.availabilityForm.$setPristine();
                $scope.showSaveSpinner = false;
                
            });
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        }

    })