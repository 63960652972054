angular.module('dashboard.directives')

	.directive('dashboardIncomeByClient', function (DashboardService, $filter) {
		return {
			restrict : 'EAC',
			replace : true,
			scope :{
				chartTitle: '@',
			},      
			template:	`<div>
							<div class="row">
								<div class="col-lg-12">
									<div class="panel panel-primary">
										<div class="panel-heading" >
											<h3 class="panel-title">{{chartTitle}}</h3>	
										</div>
										<div class="panel-body" >
											<div class="col-lg-3">
												<label>Start Date</label>
												<p class="input-group">
													<input ng-model="income_clients.start_date" type="text" class="form-control" 
														uib-datepicker-popup="{{'dd-MMM-yyyy'}}" 
														datepicker-localdate
														is-open="income_start_date_open" datepicker-options="{formatYear: 'yy', startingDay: 1}" 
														ng-required="true" close-text="Close" alt-input-formats="altInputFormats" 
														ng-change="getIncomeByClients30()"
														ng-focus="focus=false" ng-blur="focus=true" name="income_start_date" required/>
														<span class="input-group-btn">
															<button type="button" tabindex="-1" class="btn btn-default" 
															ng-click="income_start_date_open = !income_start_date_open"><i class="glyphicon glyphicon-calendar"></i></button>
														</span>
												</p>
											</div>
											
											<div class="col-lg-3">
												<label>End Date</label>
												<p class="input-group">
													<input ng-model="income_clients.end_date" type="text" class="form-control" 
														uib-datepicker-popup="{{'dd-MMM-yyyy'}}" 
														datepicker-localdate
														is-open="income_end_date_open" datepicker-options="{formatYear: 'yy', startingDay: 1}" 
														ng-required="true" close-text="Close" alt-input-formats="altInputFormats" 
														ng-change="getIncomeByClients30()"
														ng-focus="focus=false" ng-blur="focus=true" name="income_end_date" required/>
														<span class="input-group-btn">
															<button type="button" tabindex="-1" class="btn btn-default" 
															ng-click="income_end_date_open = !income_end_date_open"><i class="glyphicon glyphicon-calendar"></i></button>
														</span>
												</p>
											</div>
											
											<div class="col-lg-3">
												<label>Top n clients</label>
													<input ng-focus="focus=false" ng-blur="focus=true" type="text" 
													class="form-control" name="no_of_rows"
													ng-change="getIncomeByClients30()"
											   ng-model="income_clients.top_n_rows" />
											</div>
											
											<div class="col-lg-3"></div>
										</div>
								
										<div class="panel-body text-center" >
											<div ng-if="!dataLoaded" class="text-center">
												<i class="fa fa-cog fa-spin" style="font-size:48px"></i>
											</div>
											<div ng-if="dataLoaded">
												<canvas height="100px" id="bar" class="chart chart-bar"
													chart-data="incomeByClients30.data" 
													chart-labels="incomeByClients30.labels" 
													chart-series="incomeByClients30.series"		
													chart-colors="incomeByClients30.colors"
													chart-options="incomeByClients30.options"
												></canvas>
											</div>
										</div>
									</div>
								</div>							
							</div>
						</div>`,
			link: function (scope, element, attr) {
				
			
				scope.dataLoaded = false;
				scope.incomeByClients30 = {}
				scope.income_clients = {};
				
				function defaultIncomeDates(){					
					scope.income_clients.end_date = moment.utc().startOf('day');
					scope.income_clients.start_date = moment.utc().add(-3, 'months').startOf('day');					
					scope.income_clients.top_n_rows = 10;
				}
				
				defaultIncomeDates();				
				
				scope.getIncomeByClients30 = function(){
					scope.dataLoaded = false;
					
					var object = {};
					object.end_date = scope.income_clients.end_date;		
					object.start_date = scope.income_clients.start_date;	
					object.top_n_rows = scope.income_clients.top_n_rows;
					
					scope.incomeByClients30.options = {
						tooltips: {
							callbacks: {
								label: function(tooltipItem, data) {		
									return $filter('currency')(tooltipItem.value, '£');									
								}
							}
						}
					};
					
					scope.incomeByClients30.labels = [];
					scope.incomeByClients30.data = [];
					scope.incomeByClients30.data[0] = [];
					scope.incomeByClients30.series = [];
					scope.incomeByClients30.colors = [{
						backgroundColor: "rgba(0,0,255, 0.2)",
						borderColor: "rgba(0,0,255, 1)"
					}],
					
					DashboardService.getIncomeByClients(object).then(function(success){						
						for(var i=0; i<success.data.length; i++){
							var name = success.data[i].organisation_name;
							scope.incomeByClients30.labels[i] =  $filter('limitTo')(name, 10);
							scope.incomeByClients30.data[0][i] = success.data[i].invoice_total;				
						}					
					}, function(error){
						console.log(error);			
					}).finally(function() {			
						scope.dataLoaded = true;
					});
				}
				
				scope.getIncomeByClients30();								
				
			}
		};
	})